import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import courseIcon from "../../../assets/img/courses.jpg";
import { JellyTriangle } from "@uiball/loaders";
import { Link } from "react-router-dom";

const FreeCourse = () => {
  const userID = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(false);
  const [myFreeCourses, setMyFreeCourses] = useState([]);
  console.log(myFreeCourses);
  useEffect(() => {
    setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`UserFreeCourse/${userID}`);
        const userdata = response.data.message;
        setMyFreeCourses(userdata);
        setLoading(false);
      } catch (error) {
        toast.error("failed to fetch user data", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    };
    fetchUserData();
  }, [userID]);
  return (
    <>
      {loading ? (
        <div className="container d-flex justify-content-center pt-5">
          <JellyTriangle size={80} speed={1.75} color="#044aac" />
          <ToastContainer transition={Zoom}></ToastContainer>
        </div>
      ) : (
        <section className="container-fluid p-3 p-md-3 mb-5 mb-md-2 mb-lg-0">
          {myFreeCourses.length === 0 && (
            <div className="container text-center mt-5">
              <p>You do not have any course </p>
            </div>
          )}
          {myFreeCourses.length > 0 && (
            <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 ">
              {myFreeCourses.map((mycourse) => (
                <div className="col" key={mycourse.CourseId}>
                  <div className="my-course-card mb-4 mb-md-5">
                    <div className="my-courses-img">
                      <img src={courseIcon} className="my-course-img" alt="" />
                    </div>
                    <div className="my-course-body p-3 w-100">
                      <div className="d-block">
                        <h4 className="roboto-14-700">{mycourse.CourseName}</h4>
                        <span className="roboto-12-400">
                          Everything You need to pass this course is right in
                          here just click to have access to all our amazing
                          study resources
                        </span>
                      </div>
                      <div className="d-flex mt-4">
                        <Link
                          to={`/courses/coursepage/${
                            mycourse.CourseId
                          }?courseName=${encodeURIComponent(
                            mycourse.CourseName
                          )}`}
                          className="ms-auto roboto-10-400 text-decoration-none"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default FreeCourse;

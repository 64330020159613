import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

const AdminAddCourse = ({ show, handleClose }) => {
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState("");
  const [university, setUniversity] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [faculty, setFaculty] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [CourseTitle, setCourseTitle] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [CourseCode, setCourseCode] = useState("");
  const [CourseLevel, setCourseLevel] = useState("");
  const [CourseUnit, setCourseUnit] = useState("");

  const [isloading, setisLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uniData = await axios.get("University");
        const facultyData = await axios.get("Faculty");
        const deptData = await axios.get("listDepartment");
        const uni = uniData.data.data.message;
        const fac = facultyData.data.data.message;
        const dept = deptData.data.data.message;
        setUniversity(uni);
        setFaculty(fac);
        setDepartment(dept);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const obj = {
      DepartmentID: selectedDepartment,
      schoolID: selectedUniversity,
      FaultyID: selectedFaculty,
      CourseID: "0000",
      CourseCode,
      CourseLevel,
      CourseName,
      CourseTitle,
      CourseUnit,
    };
    console.log(obj);
    try {
      await axios.post("createCourse", obj);
      handleClose();
      toast.success("Course added successfully", {
        theme: "colored",
        autoClose: "2000",
      });
      setisLoading(false);
    } catch (error) {
      toast.error(error);
      setisLoading(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        <h3 className="fw-bold text-blue">Add Department</h3>
      </ModalHeader>
      <ModalBody>
        <form className="container" onSubmit={submitForm}>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              University
            </label>
            <select
              className="resource-input"
              onChange={(e) => setSelectedUniversity(e.target.value)}
              value={selectedUniversity}
            >
              <option value="">Select University</option>
              {university.map((uni) => (
                <option key={uni.id} value={uni.id}>
                  {uni.sc_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Faculty
            </label>
            <select
              className="resource-input"
              onChange={(e) => setSelectedFaculty(e.target.value)}
              value={selectedFaculty}
            >
              <option value="">Select Faculty</option>
              {faculty.map((fac) => (
                <option key={fac.id} value={fac.id}>
                  {fac.faculty_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Department
            </label>
            <select
              className="resource-input"
              onChange={(e) => setselectedDepartment(e.target.value)}
              value={selectedDepartment}
            >
              <option value="">Select Department</option>
              {department.map((dep) => (
                <option key={dep.id} value={dep.id}>
                  {dep.dept_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Title
            </label>
            <input
              type="text"
              value={CourseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Name
            </label>
            <input
              type="text"
              value={CourseName}
              onChange={(e) => setCourseName(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Code
            </label>
            <input
              type="text"
              value={CourseCode}
              onChange={(e) => setCourseCode(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Level
            </label>
            <input
              type="number"
              value={CourseLevel}
              onChange={(e) => setCourseLevel(e.target.value)}
              className="resource-input"
              placeholder="100"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Unit
            </label>
            <input
              type="number"
              value={CourseUnit}
              onChange={(e) => setCourseUnit(e.target.value)}
              className="resource-input"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button className="blue-filled-btn text-center" type="submit">
              {isloading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span> Add</span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AdminAddCourse;

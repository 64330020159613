import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const QuizModal = ({ show, handleClose, currentQuiz, topic }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionSelected, setOptionSelected] = useState(false);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState(null);

  useEffect(() => {
    if (show) {
      setTimeLeft(15);
      setCurrentQuestion(0);
      setShowScore(false);
      setScore(0);
      setSelectedOption(null);
      setOptionSelected(false);
      shuffleOptions(currentQuiz[0]);
    }
  }, [show, currentQuiz]);

  // start timer for each question
  useEffect(() => {
    const timer =
      timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (currentQuiz.length > 0) {
      shuffleOptions(currentQuiz[currentQuestion]);
    }
  }, [currentQuestion, currentQuiz]);

  const shuffleOptions = (question) => {
    const options = [
      question.option1,
      question.option2,
      question.option3,
      question.answer,
    ];
    setShuffledOptions(options.sort(() => Math.random() - 0.5));
  };

  const handleNext = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < currentQuiz.length) {
      setCurrentQuestion(nextQuestion);
      setSelectedOption(null);
      setTimeLeft(15);
      setOptionSelected(false);
      shuffleOptions(currentQuiz[nextQuestion]);
    } else {
      setShowScore(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setShowScore(false);
    setScore(0);
    setSelectedOption(null);
    setTimeLeft(15);
    setOptionSelected(false);
    shuffleOptions(currentQuiz[0]);
  };

  const handleOptionClick = (option) => {
    if (optionSelected) return;

    setSelectedOption(option);
    setCorrectOption(currentQuiz[currentQuestion].answer);
    if (option === currentQuiz[currentQuestion].answer) {
      setScore(score + 1);
    }
    setOptionSelected(true);
  };

  if (!currentQuiz || currentQuiz.length === 0) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      {!showScore ? (
        <div className="d-block">
          <Modal.Header className="quiz-header d-flex p-2">
            <h5>
              Quiz : <span>{topic}</span>
            </h5>
            <div className="timer-box ms-auto">
              <span>
                Time left:{" "}
                <span className="time-counter ms-2 px-1">{timeLeft}</span>
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="container quiz-container">
              <h5 className="lato-20-400">
                {currentQuiz[currentQuestion].question}
              </h5>
              <div className="quiz-options-container">
                {shuffledOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`quiz-option p-3 mb-3 lato-16-400 ${
                      selectedOption === option
                        ? option === currentQuiz[currentQuestion].answer
                          ? "correct-option"
                          : "incorrect-option"
                        : correctOption === option
                        ? "correct-option"
                        : ""
                    }`}
                    role="button"
                    onClick={() => handleOptionClick(option)}
                    disabled={optionSelected}
                  >
                    {option}
                  </div>
                ))}
                <div className="quiz-buttons d-flex mt-3">
                  <div>
                    Question {currentQuestion + 1}/{currentQuiz.length}
                  </div>
                  <button
                    className="blue-filled-btn ms-auto"
                    onClick={handleNext}
                    disabled={!optionSelected}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      ) : (
        <Modal.Body className="text-center">
          <i className="bi bi-trophy-fill fs-1 text-blue"></i>
          <p>Congratulations! You have completed the Quiz.</p>
          <span>
            Your Score: {score}/{currentQuiz.length}
          </span>
          <div className="d-flex mt-3">
            <button className="btn ms-auto" onClick={handleClose}>
              Exit
            </button>
            <button onClick={restartQuiz} className="blue-filled-btn">
              Restart Quiz
            </button>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default QuizModal;

import React from "react";
import { Card } from "react-bootstrap";

const Recorded = () => {
  return (
    <div style={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
      <Card className="p-2">
      <iframe
        maxWidth="400"
        height="315"
        src="https://www.youtube.com/embed/X98wzCCtkDo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen="true"
      ></iframe>
      <h4>How to earn on ETC Vibes as a student</h4>
      <h6>By: ETC Vibes</h6>
    </Card>
    <Card className="p-2">
      <iframe
        maxWidth="400"
        height="315"
        src="https://www.youtube.com/embed/CY_8h-lYT-w?si=1gfODiBSZV4MgmI_"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen="true"
      ></iframe>
      <h4>How to navigate through ETC Vibes</h4>
      <h6>By: ETC Vibes</h6>
    </Card>
      <Card className="p-2">
        <iframe
          maxWidth="500"
          height="315"
          src="https://www.youtube.com/embed/4FLbbqQsouM?si=ArAZvRpTivjulLIO"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen="true"
        ></iframe>
        <h4>ETC Vibes relauch session.</h4>
        <h6>By: Mr VIctor</h6>
      </Card>

    </div>

  );
};

export default Recorded;

import Accordion from "react-bootstrap/Accordion";
const HelpCenter = () => {
  return (
    <section classNam="mt-4">
      <div className="row row-cols-1 row-cols-md-2 py-4 g-4">
        <div className="col">
          <h2 className="lato-24-700 text-blue">A.I. Chat Room</h2>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                What features are available in the A.I. chat room for students?
              </Accordion.Header>
              <Accordion.Body>
                You can prompt AI vibes to give you the requested information,
                and it can assist you with your assignments, studying, and many
                more.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How can students benefit from using the A.I. chat room for their
                studies?
              </Accordion.Header>
              <Accordion.Body>
                Prompt AI Vibes and get useful information for free.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can students connect with tutors through the A.I. chat room for
                quick questions?
              </Accordion.Header>
              <Accordion.Body>No.</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col">
          <h2 className="lato-24-700 text-blue">Payment and Earnings</h2>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Are there any transaction fees for payments made on the
                platform?
              </Accordion.Header>
              <Accordion.Body>
                Yes, but very minimal. Paystack handles our payment.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How often are tutors paid for their services?
              </Accordion.Header>
              <Accordion.Body>Immediately</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                What payment methods are accepted for subscriptions and virtual
                classes?
              </Accordion.Header>
              <Accordion.Body>Transfer, USSD, Card, Bank</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Is there a referral program for users to earn benefits by
                inviting others to the platform?
              </Accordion.Header>
              <Accordion.Body>Not yet live. Soon!</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col">
          <h2 className="lato-24-700 text-blue">Tutorials</h2>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How do tutors schedule virtual tutorial classes on the platform?
              </Accordion.Header>
              <Accordion.Body>
                Sign up as a tutor. On scheduling a tutorial, complete your Zoom
                account from the link ETC Vibes sent to your email address.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Is there a maximum number of students allowed in a virtual
                class?
              </Accordion.Header>
              <Accordion.Body>Yes, 100 students.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                How are virtual classes conducted, and what platform is used for
                the sessions?
              </Accordion.Header>
              <Accordion.Body>
                Students or Tutors can schedule a tutorial. Once tutors schedule
                a tutorial, all students in the tutor's faculty get a
                notification for that tutorial, and you can decide to join the
                class. For students, the scheduled tutorial goes to all tutors
                in that faculty, and the first to accept takes the course.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Can tutors customize the content and materials for their virtual
                classes?
              </Accordion.Header>
              <Accordion.Body>Yes.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                What happens if a student misses a scheduled virtual class?
              </Accordion.Header>
              <Accordion.Body>
                There will be an upload of the recorded session if the tutor
                decides to upload it, otherwise, the session is gone. Students
                can schedule another one if they need a session.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Can tutors provide feedback and assessments to students after
                virtual classes?
              </Accordion.Header>
              <Accordion.Body>
                Not available in this current version.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Is there a rating and review system for tutors and virtual
                classes?
              </Accordion.Header>
              <Accordion.Body>
                Not available in this current version.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col">
          <h2 className="lato-24-700 text-blue">Study Resources</h2>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Are study resources available for different academic levels and
                subjects?
              </Accordion.Header>
              <Accordion.Body>
                Yes, but not for all in the current version.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Can students download study resources for offline access?
              </Accordion.Header>
              <Accordion.Body>No.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                How is the subscription fee for study resources structured?
                Monthly or annually?
              </Accordion.Header>
              <Accordion.Body>For now, monthly.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Is there a limit to the number of study resources a student can
                access with a Subscription?
              </Accordion.Header>
              <Accordion.Body>
                Yes. Five courses are for the basic plan, while ten are for the
                silver plan. Gold plan is coming soon.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                How often are study resources updated to reflect curriculum or
                academic standards changes?
              </Accordion.Header>
              <Accordion.Body>As soon as there is a change</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Are there interactive elements in the study resources, such as
                quizzes or practice exams?
              </Accordion.Header>
              <Accordion.Body>Yes</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Can students provide feedback on study resources or suggest
                topics for future content?
              </Accordion.Header>
              <Accordion.Body>
                No, not in this current version, but will be implemented in the
                next.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="col">
          <h2 className="lato-24-700 text-blue">Technical Support</h2>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How can users reset their passwords or recover their accounts if
                they forget login credentials?
              </Accordion.Header>
              <Accordion.Body>Use the forget password button</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Is there a customer support team that can be contacted for
                assistance?
              </Accordion.Header>
              <Accordion.Body>
                Yes, you can also contact us via social media.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default HelpCenter;

import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import AddTexbook from "./AddTexbook";
import { useState } from "react";
import AdminTextbookTable from "./AdminTextbookTable";
import AdminSidebar from "../../../AdminSidebar";
import LogoutModal from "../../../../../components/LogoutModal";
import AdminHeader from "../../../AdminHeader";

const AdminTextbooks = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const closeAddModal = () => {
    setOpenAddModal(false);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid ">
          <button
            className="viga-20-400 text-grey back-btn mb-4"
            onClick={goBack}
          >
            Back
          </button>
          <div className="d-flex align-items-center mb-3">
            <h5 className="text-blue lato-20-700 ps-2">Texbook Resources</h5>
            <button
              className="blue-filled-btn ms-auto"
              onClick={() => setOpenAddModal(true)}
            >
              Add Texbook
            </button>
          </div>
          <AdminTextbookTable />
          <AddTexbook show={openAddModal} handleClose={closeAddModal} />
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default AdminTextbooks;

import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import notificationIcon from "../assets/img/notificationIcon.svg";

const NotificationModal = () => {
  const notifications = [
    {
      name: "Upcoming Tutorial",
      type: "Group Tutorial",
      content: "You have Machine Learning 202 Tutorial",
      date: "March 27 at 4pm.",
      id: 1,
    },
    {
      name: "Subscription",
      type: "Premium Subscription plan",
      content:
        "Congratulations!  Your Subscription has been upgraded to premium plan",
      date: "March 27 at 4pm.",
      id: 2,
    },
    {
      name: "Upcoming Tutorial",
      type: "Group Tutorial",
      content: "You have Machine Learning 202 Tutorial",
      date: "March 27 at 4pm.",
      id: 3,
    },
  ];
  return (
    <div className="dropdown">
      {/* <FontAwesomeIcon
        icon={faBell}
        className="bi bi-bell px-2 dropdown-toggle fs-4"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      /> */}
      <div className="dropdown-menu p-0 notification-menu">
        <div className="card">
          <div className="card-header d-flex">
            <span className="viga-20-400">Notifications</span>
            <Link to="/notifications" className="ms-auto lato-20-400">
              See All
            </Link>
          </div>
          <div className="card-body">
            <h5 className="lato-16-600 text-grey">Recent Notifications</h5>
            <div className="container p-3">
              {notifications.map((notification) => (
                <div
                  className="d-flex mb-3 notification-cell"
                  key={notification.id}
                >
                  <div className="notification-icon pe-2 pe-md-4">
                    <img src={notificationIcon} alt="" />
                  </div>
                  <div className="notification-body">
                    <div className="d-flex align-items-center">
                      <h4 className="viga-14-400">{notification.name}</h4>
                      <h5 className="lato-14-400 text-grey ps-4">
                        {notification.type}
                      </h5>
                    </div>
                    <p className="lato-14-400">{notification.content}</p>
                    <div className="notification-date text-grey mb-2">
                      <i className="bi bi-calendar pe-2"></i>
                      {notification.date}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;

import { useState } from "react";
import "../tutor.css";
import TutorSidebar from "../TutorSidebar";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import TutorCards from "./TutorCards";
import ScheduleTut from "../Tutorials/ScheduleTut";
import { JellyTriangle } from "@uiball/loaders";
import { useEffect } from "react";
import axios from "../../../api/axios";
import { ToastContainer, Zoom } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MobileTutorNav from "../../../components/MobileTutorNav";
import ZoomNotify from "../ZoomNotify";

const TutorDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const tutorID = sessionStorage.getItem("tutorId");
  const navigate = useNavigate();
  const [zoomModal, setZoomModal] = useState(false);

  const tutorname = sessionStorage.getItem("tutorname");
  const tutorEmail = sessionStorage.getItem("tutorEmail");
  const tutorPhone = sessionStorage.getItem("tutorPhone");

  const closeModal = () => setZoomModal(false);
  const closeScheduleModal = () => {
    setShowScheduleModal(false);
  };
  useEffect(() => {
    if (tutorID === "" || tutorID === null) {
      navigate("/login");
    }
    setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`ShowTutorProfile/${tutorID}`);

        const userdata = response.data.data.UserRecord;
        sessionStorage.setItem("tutorname", userdata.FullName);
        sessionStorage.setItem("tutorEmail", userdata.EmailAddress);
        sessionStorage.setItem("tutorPhone", userdata.PhoneNumber);
        sessionStorage.setItem("tutorSchool", userdata.School);
        sessionStorage.setItem("tutorQualification", userdata.Qualification);
        // Check if tutor has activated zoom

        const zoomcheck = await axios.get(
          `VerifyZoomUsersStatus/${userdata.EmailAddress}`
        );
        const zcheck = zoomcheck.data.data;
        if (zcheck.status === "failed") {
          setZoomModal(true);
          sessionStorage.setItem("zoom", "N");
        } else {
          setZoomModal(false);
          sessionStorage.setItem("zoom", "Y");
        }
        console.log(zcheck);
        setLoading(false);
      } catch (error) {}
    };
    fetchUserData();
  }, [tutorID, navigate, tutorEmail]);

  return (
    <>
      {loading ? (
        <div className="container-fluid d-flex justify-content-center align-items-center preloader-container">
          <div className="container d-flex justify-content-center">
            <JellyTriangle size={80} speed={1.75} color="#044aac" />
          </div>
        </div>
      ) : (
        <div className="container-fluid p-0">
          <TutorSidebar />
          <LogoutModal />
          <div className="main-content p-1 p-md-3 tutorDashboard ">
            <TutorHeader
              tutorEmail={tutorEmail}
              tutorPhone={tutorPhone}
              tutorname={tutorname}
            />
            <div className="d-flex mb-3 px-3 align-items-center">
              <h3 className="lato-24-700 text-blue">Upcoming Tutorials</h3>

              <button
                className="ms-auto blue-filled-btn"
                onClick={(e) => setShowScheduleModal(true)}
              >
                +Schedule Tutorial
              </button>
              <ScheduleTut
                show={showScheduleModal}
                handleClose={closeScheduleModal}
              />
            </div>
            <TutorCards />
          </div>
          {/* Zoom Notification */}
          <ZoomNotify show={zoomModal} handleClose={closeModal} />
          <MobileTutorNav />
          <ToastContainer transition={Zoom}></ToastContainer>
        </div>
      )}
    </>
  );
};

export default TutorDashboard;

import React from "react";
import "./admin.css";
import { NavLink } from "react-router-dom";
import logout from "../../assets/img/logoutGold.svg";
import whiteLogo from "../../assets/img/whiteLogo.svg";
const AdminSidebar = () => {
  return (
    <div className="admin-sidebar">
      <div className="sidebar-logo">
        <img src={whiteLogo} alt="Logo" />
      </div>
      <nav className="navbar">
        <NavLink
          to="/admin"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-grid-fill nav-icon"></i>
            Finances
          </div>
        </NavLink>
        <NavLink
          to="/adminresource"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi-file-earmark-richtext nav-icon"></i>
            Resources
          </div>
        </NavLink>
        <NavLink
          to="/admintutorial"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-people nav-icon"></i>
            Tutorials
          </div>
        </NavLink>
        <NavLink
          to="/admintutor"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-clipboard nav-icon"></i>
            Tutors
          </div>
        </NavLink>
        <NavLink
          to="/adminstudent"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-building nav-icon"></i>
            Students
          </div>
        </NavLink>
        <NavLink
          to="/adminnotifications"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-bell nav-icon"></i>
            Notifications
          </div>
        </NavLink>
      </nav>

      <div className="sidebar-tools">
        <nav className="navbar">
          <NavLink
            to="/adminpost"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-envelope-arrow-up nav-icon"></i>
              Posts
            </div>
          </NavLink>
          <NavLink
            to="#"
            // className={({ isActive }) =>
            //   isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            // }
          >
            <div
              className="nav-item d-flex btn"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <img src={logout} className="px-3" alt="" />
              Logout
            </div>
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default AdminSidebar;

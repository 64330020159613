import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";
import LogoutModal from "../../../components/LogoutModal";
import { Link } from "react-router-dom";

const ResourceDetails = () => {
  const resource = [
    {
      id: 1,
      department: "Computer Science",
      courseTitle: "Machine Learning",
      university: "Redeemers University",
      type: "Past Question",
      uploader: "ETC Admin",
      pages: "24 pages",
      date: "24/05/2023",
      time: "5:30 PM",
    },
  ];
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid page-details">
          <div className="d-flex align-items-center">
            <Link to="/adminresource">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
            <span className="viga-24-400">Resource Details</span>
          </div>
          <div className="d-flex mb-3">
            <button className="grey-btn me-2 ms-auto">Edit Details</button>
            <button className="btn btn-danger mx-2">Delete</button>
          </div>
          <div className="container ">
            <div className="resource-blue p-3 mb-3">
              <i className="bi bi-file-earmark-text text-white fs-3 pe-3"></i>
              <span className="lato-20-400"> cmp201.pdf</span>
              <i className="bi bi-check-lg ms-auto fs-3 text-white"></i>
            </div>
            {resource.map((details) => (
              <div className="d-block">
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Department:</span>
                  <span className="lato-24-400">{details.department}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Course Title:</span>
                  <span className="lato-24-400">{details.courseTitle}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">University:</span>
                  <span className="lato-24-400">{details.university}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Resource Type:</span>
                  <span className="lato-24-400">{details.type}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Uploaded By:</span>
                  <span className="lato-24-400">{details.uploader}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Number of pages:</span>
                  <span className="lato-24-400">{details.pages}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Date Uploaded:</span>
                  <span className="lato-24-400">{details.date}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Time:</span>
                  <span className="lato-24-400">{details.time}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResourceDetails;

import { NavLink } from "react-router-dom";
const MobileNav = () => {
  return (
    <nav className="navbar fixed-bottom d-flex d-md-flex d-lg-none p-0 w-100">
      <div className="container-fluid mobile-nav">
        <div className="row row-cols-5 w-100 mx-0">
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              to="/userDashboard"
              activeClassName="mobile-active"
              className="nav-link"
            >
              {({ isActive }) => (
                <div
                  className={`nav-item text-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-house-door-fill" : "bi-house-door"
                    } fs-5`}
                  ></i>
                  <p className="m-0 lato-14-400">Home</p>
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              to="/courses"
              activeClassName="mobile-active"
              className="nav-link"
            >
              {({ isActive }) => (
                <div
                  className={`nav-item text-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive
                        ? "bi-file-earmark-richtext-fill"
                        : "bi-file-earmark-richtext"
                    } fs-5`}
                  ></i>
                  <p className="m-0 lato-14-400">Resources</p>
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              to="/usertutorials"
              activeClassName="mobile-active"
              className="nav-link"
            >
              {({ isActive }) => (
                <div
                  className={`nav-item text-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-people-fill" : "bi-people"
                    } fs-5`}
                  ></i>
                  <p className="m-0 lato-14-400">Tutorial</p>
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              to="/userWallet"
              activeClassName="mobile-active"
              className="nav-link"
            >
              {({ isActive }) => (
                <div
                  className={`nav-item text-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-wallet-fill" : "bi-wallet"
                    } fs-5`}
                  ></i>
                  <p className="m-0 lato-14-400">Wallet</p>
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              to="/etcbot"
              activeClassName="mobile-active"
              className="nav-link"
            >
              {({ isActive }) => (
                <div
                  className={`nav-item text-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi bi-robot" : "bi-robot"
                    } fs-5`}
                  ></i>
                  <p className="m-0 lato-14-400">AI</p>
                </div>
              )}
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileNav;

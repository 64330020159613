import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import PaystackPayment from "./PaystackPayment";
import { useState } from "react";
import { useCallback } from "react";
import { toast } from "react-toastify";

const FundWalletModal = ({ show, handleClose, closeSubConfrim }) => {
  const [amount, setAmount] = useState("");
  const payerEmail = sessionStorage.getItem("userEmail");
  const handlePay = useCallback(
    (e) => {
      e.preventDefault();
      if (!isNaN(parseFloat(amount))) {
        // setModalShow(false);
      } else {
        toast.error("Please enter a valid amount.", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    },
    [amount]
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="p-5"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <ModalHeader>
          {/* <i className="bi bi-exclamation-triangle-fill text-warning"></i> */}
          <p>You have Insufficient funds in your wallet, fund now.</p>
        </ModalHeader>
        <div className="d-block">
          <h1>Enter an Amount :</h1>
        </div>
        <form onSubmit={handlePay} className="p-3">
          <div className="d-flex align-items-center">
            <label htmlFor="amount" className="pe-3">
              ₦
            </label>
            <input
              type="number"
              id="amount"
              className="form-control"
              name="amount"
              step="0.01"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <div className="d-flex my-3 ">
            <button
              className="blue-outline-btn m-auto"
              onClick={() => {
                handleClose();
                closeSubConfrim();
              }}
            >
              Close
            </button>
            <PaystackPayment
              amount={parseFloat(amount) * 100}
              payerEmail={payerEmail}
            />
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default FundWalletModal;

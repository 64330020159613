import { createSlice } from "@reduxjs/toolkit";

export const tutorSlice = createSlice({
  name: "tutor",
  initialState: {
    tutor: null,
    dashboard: null,
    profile: null,
  },
  reducers: {
    adminLogin: (state, action) => {
      state.tutor = action.payload;
    },
    adminDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    adminProfile: (state, action) => {
      state.profile = action.payload;
    },
    adminlogout: (state) => {
      state.tutor = null;
      state.dashboard = null;
      state.profile = null;
    },
  },
});

export const { adminLogin, adminDashboard, adminProfile, adminlogout } =
  tutorSlice.actions;

export const selectTutor = (state) => state.tutor.tutor;
export const tutorProfile = (state) => state.tutor.profile;
export const tutorDashboard = (state) => state.tutor.dashboard;

export default tutorSlice.reducer;

import { useEffect } from "react";
import tutorialPoster from "../../../assets/img/tutImg.svg";
import TutorReadMore from "../TutorReadMore";
import { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { Pagination } from "react-bootstrap";

const TutorialUpcoming = ({ upcomingTut }) => {
  const [loading, setLoading] = useState(true);
  const [bloading, setbLoading] = useState(false);
  const tutorID = sessionStorage.getItem("tutorId");
  useEffect(() => {
    if (upcomingTut === "") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [upcomingTut]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };

  const handleSubmit = async (status, id) => {
    setbLoading(true);

    try {
      const response = await axios.post(`AcceptDeclineRequest/${id}`, {
        status: status,
        tutorID,
      });
      console.log(response.data);
      setbLoading(false);
      toast.success("Tutorial Updated", {
        autoClose: "2000",
      });
    } catch (error) {
      toast.error(error, {
        theme: "colored",
        autoClose: "2000",
      });
      setbLoading(false);
    }
  };

  const CARDS_PER_PAGE = 6;

  const [activePage, setActivePage] = useState(1);

  const totalPages = Math.ceil(upcomingTut.length / CARDS_PER_PAGE);

  const handleClick = (event) => {
    const targetPage = Number(event.target.text);
    setActivePage(targetPage);
  };
  // Filter based on status
  const filteredTutorials = upcomingTut.filter(
    (tutorial) => tutorial.Status.toLowerCase() === "pending"
  );

  const startIndex = (activePage - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;
  const visibleItems = filteredTutorials.slice(startIndex, endIndex);

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === activePage} onClick={handleClick}>
        {i}
      </Pagination.Item>
    );
  }
  return (
    <>
      {loading ? (
        <span
          className="spinner-border spinner-border-lg text-blue"
          role="status"
        ></span>
      ) : upcomingTut === "you no tutorial request or created available" ||
        upcomingTut === "failed" ? (
        <div className="p-2 mb-5">
          <p className="text-blue lato-20-600">No upcoming Tutorials</p>
        </div>
      ) : (
        <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 g-4 my-3">
          {visibleItems.map((tutorial) => (
            <div className="col d-flex justify-content-cente" key={tutorial.iD}>
              <div className="tutorial-card-container d-block p-3 mb-3">
                <div className="card border-0">
                  <div className="d-block">
                    <div className="d-flex align-items-center my-2">
                      <span className="lato-20-700 text-blue">
                        {tutorial.CourseTitle}
                      </span>

                      <span className="font-12-400 ms-auto">
                        {tutorial.Duration} mins
                      </span>
                    </div>
                    <div className="d-flex mb-3">
                      {/* <h5 className="lato-12-700">{tutorial.Status}</h5> */}
                      {/* <Link
                        to="#"
                        className="ms-auto roboto-8-400"
                        data-bs-toggle="modal"
                        data-bs-target="#tutorReadMoreModal"
                        data-bs-whatever=""
                      >
                        Read More <i className="bi bi-arrow-right"></i>
                      </Link> */}
                      <span className="lato-10-400 ">
                        {formatDateTime(tutorial.TutorialTime)}
                      </span>
                    </div>
                    <div className="d-flex">
                      <button
                        className="blue-filled-btn mb-3"
                        onClick={() => handleSubmit("Accepted", tutorial.iD)}
                      >
                        {bloading ? (
                          <span
                            className="spinner-border spinner-border-sm text-blue"
                            role="status"
                          ></span>
                        ) : (
                          <> Accept</>
                        )}
                      </button>
                      <button
                        className="blue-filled-btn ms-auto h-100 bg-danger"
                        onClick={() => handleSubmit("Decline", tutorial.iD)}
                      >
                        {bloading ? (
                          <span
                            className="spinner-border spinner-border-sm text-blue"
                            role="status"
                          ></span>
                        ) : (
                          <>Decline</>
                        )}
                      </button>
                    </div>
                  </div>
                  <img
                    src={tutorialPoster}
                    alt=""
                    className="card-img tutorial-card-img"
                  />
                </div>
              </div>
            </div>
          ))}

          <TutorReadMore />
        </div>
      )}
      <div className="card-pagination d-flex">
        <Pagination className="ms-auto me-4">{paginationItems}</Pagination>
      </div>
    </>
  );
};

export default TutorialUpcoming;

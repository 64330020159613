import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Download from "../../../assets/img/DownloadSmall.svg";
import subcription from "../../../assets/img/Wallet.svg";
import coins from "../../../assets/img/ArrowRight.svg";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";

export const PlanActivity = () => {
  const userID = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const activitiesPerPage = 10;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`transactions/${userID}`);
        const resp = response.data.data;
        if (resp.status === "success") {
          const userActivity = resp.message;
          // Sort activities by timestamp in descending order
          userActivity.sort(
            (a, b) =>
              new Date(b.TransactionDateTime) - new Date(a.TransactionDateTime)
          );
          setActivities(userActivity);
        } else if (resp.status === "failed") {
          setActivities([]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userID]);
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleString("en-US", options)}`;
  };
  // Calculate the index of the last activity on the current page
  const indexOfLastActivity = currentPage * activitiesPerPage;
  // Calculate the index of the first activity on the current page
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  // Get the activities for the current page
  const currentActivities = activities.slice(
    indexOfFirstActivity,
    indexOfLastActivity
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="container p-0">
      <h2 className="viga-24-400">Recent Activities</h2>
      {loading ? (
        <span className="spinner-border spinner-border-lg" role="status"></span>
      ) : (
        <div className="d-block">
          {currentActivities.length === 0 ? ( // Check if activities array is empty
            <p className="lato-20-400 text-blue">No transactions</p>
          ) : (
            currentActivities.map((activity, index) => {
              if (
                activity?.TransactionType === "credit" ||
                activity?.TransactionType === "Credit"
              ) {
                return (
                  <div
                    className="wallet-activity p-2 p-md-3 d-flex mb-3"
                    key={index}
                  >
                    <div className="wallet-activity-icon-1">
                      <img src={Download} className="m-auto" alt="" />
                    </div>
                    <div className="d-block ms-2 ms-md-4">
                      <p className="lato-24-400">{activity.TransactionDesc}</p>
                      <span className="lato-12-400">
                        {formatDateTime(activity.TransactionDateTime)}
                      </span>
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                      <span className="lato-24-700 text-blue px-2 text-nowrap">
                        ₦ {activity.TransactionAmount}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown}
                        className="text-blue"
                      />
                    </div>
                  </div>
                );
              } else if (activity?.TransactionType === "coin") {
                return (
                  <div
                    className="wallet-activity p-2 p-md-3 d-flex mb-3"
                    key={index}
                  >
                    <div className="wallet-activity-icon-2">
                      <img src={coins} className="m-auto" alt="" />
                    </div>
                    <div className="d-block ms-2 ms-md-4">
                      <p className="lato-24-400">{activity.TransactionDesc}</p>
                      <span className="lato-12-400">
                        {formatDateTime(activity.TransactionDateTime)}
                      </span>
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                      <span
                        className="lato-24-700 px-2 text-nowrap"
                        style={{ color: "#f0d36a" }}
                      >
                        ₦ {activity.TransactionAmount}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown}
                        style={{ color: "#f0d36a" }}
                      />
                    </div>
                  </div>
                );
              } else if (activity?.TransactionType === "debit") {
                return (
                  <div
                    className="wallet-activity p-2 p-md-3 d-flex mb-3"
                    key={index}
                  >
                    <div className="wallet-activity-icon-3">
                      <img src={subcription} className="m-auto" alt="" />
                    </div>
                    <div className="d-block ms-2 ms-md-4">
                      <p className="lato-24-400">{activity.TransactionDesc}</p>
                      <span className="lato-12-400">
                        {formatDateTime(activity.TransactionDateTime)}
                      </span>
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                      <span className="lato-24-700 text-danger px-2 text-nowrap">
                        - ₦{activity.TransactionAmount}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown}
                        className="text-danger"
                      />
                    </div>
                  </div>
                );
              } else if (activity?.TransactionType === "Debit") {
                return (
                  <div
                    className="wallet-activity p-2 p-md-3 d-flex mb-3"
                    key={index}
                  >
                    <div className="wallet-activity-icon-3">
                      <img src={subcription} className="m-auto" alt="" />
                    </div>
                    <div className="d-block ms-2 ms-md-4">
                      <p className="lato-24-400">{activity.TransactionDesc}</p>
                      <span className="lato-12-400">
                        {formatDateTime(activity.TransactionDateTime)}
                      </span>
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                      <span className="lato-24-700 text-danger px-2 text-nowrap">
                        - ₦{activity.TransactionAmount}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronCircleDown}
                        className="text-danger"
                      />
                    </div>
                  </div>
                );
              }
              return null;
            })
          )}
          {/* Pagination */}
          <ul className="pagination">
            {Array.from(
              { length: Math.ceil(activities.length / activitiesPerPage) },
              (_, i) => i + 1
            ).map((page, index) => (
              <li
                key={index}
                className={`page-item ${page === currentPage ? "active" : ""}`}
              >
                <button onClick={() => paginate(page)} className="page-link">
                  {page}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

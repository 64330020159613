import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./PDF.css";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

function MyPdfViewer() {
  const { source } = useParams();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const userID = sessionStorage.getItem("userID");
  const activeResourceID = sessionStorage.getItem("activeResourceID");
  const activeResourceType = sessionStorage.getItem("activeResourceType");
  useEffect(() => {
    const userID = sessionStorage.getItem("userID");
    if (userID === "" || userID === null) {
      navigate("/login");
    }

    const createView = async () => {
      const obj = {
        reviewable_type: activeResourceType,
        reviewable_id: parseInt(activeResourceID),
      };
      try {
        await axios.post(`CreateView/${userID}`, obj);
      } catch (error) {
        toast.error("Error creating view");
      }
    };
    createView();
  }, [userID, navigate, activeResourceID, activeResourceType]);
  return (
    <section id="ppddff" className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content">
        <DashboardHeader />
        <div className="course-header px-2 pt-0 pb-3">
          <div className="d-flex">
            <p className="viga-24-400">Resources</p>
            <Link
              to=""
              className="viga-24-400 text-grey ms-auto"
              onClick={goBack}
            >
              <i className="bi bi-arrow-left"></i>
              Back
            </Link>
          </div>
        </div>

        <div className="resource-container p-3 p-md-5">
          <p style={{ textAlign: "center" }}>
            Page {pageNumber} of {numPages}
          </p>
          <div className="nav-but">
            {pageNumber > 1 ? (
              <Button
                className="mr-2"
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
              >
                Previous
              </Button>
            ) : (
              ""
            )}
            {pageNumber < numPages ? (
              <Button
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              >
                Next
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>
            <Document
              file={decodeURIComponent(source)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
                className="pdf-page"
                pageNumber={pageNumber}
              />
            </Document>
          </div>
        </div>
      </div>
      <MobileNav />
    </section>
  );
}

export default MyPdfViewer;

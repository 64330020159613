import React from "react";
// import { Link } from "react-router-dom";
import whiteLogo from "../../../assets/img/whiteLogo.svg";
import blueLogo from "../../../assets/img/blueLogo.svg";
import { useState } from "react";
import api from "../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";

const ResetPassword = () => {
  const [email, setemail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const obj = { email };

    api
      .post("ForgetPassword", obj)
      .then((response) => {
        setIsLoading(false);
        let resp = response.data.data;
        if (resp.status === "success") {
          const message = response.data.data;
          toast.success(resp.message, {
            theme: "colored",
            autoClose: 4000,
          });
          console.log(message);
        } else {
          toast.error(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Signup failed, error:" + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 reset-left">
          <img src={whiteLogo} className="logo ms-5" alt="Logo" />
          <div className="ps-5 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-dark viga-24-400">ETC Vibes</h2>
              <p className="text-dark w-75">
                providing you with everything required to make your striaght As!
                on a single platform..
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-white p-3 d-flex">
          <div className="container form-container-lg px-3 m-auto">
            <div className="d-flex justify-content-center d-flex d-md-none">
              <img src={blueLogo} className="logo " alt="Logo" />
            </div>
            <h3 className="viga-24-400 text-blue my-4">Forget Password</h3>
            <span className="span-black-400">
              Enter the email associated with your account and we'll send you a
              link to reset your password.
            </span>
            <form action="" className="login-form" onSubmit={forgotPassword}>
              <div className="my-3 d-flex">
                <label htmlFor="email" className="black-500">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  className="ps-4"
                  placeholder="yourname@gmail.com"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <span className="font-12 my-3">
                Need help? Contact support at{" "}
                <a href="mailto:contact@etcvibes.com">contact@etcvibes.com</a>
              </span>

              <div className="mt-4">
                <button type="submit" className="submit-btn">
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  ) : (
                    <span>Reset Password</span>
                  )}
                </button>
              </div>
              <ToastContainer transition={Zoom}></ToastContainer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

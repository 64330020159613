import AdminSidebar from "../AdminSidebar";
import LogoutModal from "../../../components/LogoutModal";
import AdminHeader from "../AdminHeader";
import TutorialTable from "./TutorialTable";

const AdminTutorial = () => {
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <TutorialTable />
      </div>
    </section>
  );
};

export default AdminTutorial;

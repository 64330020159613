import { Link } from "react-router-dom";

const CalendarComponent = () => {
  const userPlan = sessionStorage.getItem("userPlan");
  return (
    <div className="p-3">
      <div className="plan-preview p-2 text-center mb-5 mb-md-0">
        <div className="d-flex mt-3">
          <div className="col d-block text-center">
            <span className="text-grey lato-16-400">
              Active subscription plan
            </span>
            <h3 className="plan-text ">{userPlan}</h3>
          </div>
        </div>
        <Link to="/userwallet">
          <button className="blue-filled-btn">My Wallet</button>
        </Link>
      </div>
    </div>
  );
};

export default CalendarComponent;

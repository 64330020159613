import TutorSidebar from "../TutorSidebar";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import TutorialUpcoming from "./TutorialUpcoming";
import ScheduleTut from "./ScheduleTut";
import { useEffect, useState } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import axios from "../../../api/axios";
import TutorScheduled from "./TutorScheduled";
import MobileTutorNav from "../../../components/MobileTutorNav";

const TutorTutorial = () => {
  const tutorID = sessionStorage.getItem("tutorId");
  const tutorFaculty = sessionStorage.getItem("tutorFaculty");
  const [loading, setLoading] = useState(true);
  const [upcomingTut, setUpcomingTut] = useState([]);
  const [RequestedTut, setRequestedTut] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const closeScheduleModal = () => {
    setShowScheduleModal(false);
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const scheduled = await axios.get(`Dashboard/${tutorID}`);
        const requested = await axios.get(
          `Requested_Tutorial__/${tutorFaculty}`
        );
        const scheduledTut = scheduled.data.data;
        const requestedTut = requested.data.data;
        console.log(requestedTut.message);
        console.log(scheduledTut);
        if (
          scheduledTut.CreatedTutorial ===
          "you no tutorial request or created available"
        ) {
          setRequestedTut([]);
        } else {
          setRequestedTut(scheduledTut.CreatedTutorial);
        }

        if (requestedTut === "you no tutorial request or created available") {
          setUpcomingTut([]);
        } else {
          setUpcomingTut(requestedTut.message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [tutorID, tutorFaculty]);
  return (
    <section className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 tutorTutorial">
        <TutorHeader />
        <div className="d-flex mb-3 px-3">
          <button
            className="ms-auto blue-filled-btn"
            onClick={(e) => setShowScheduleModal(true)}
          >
            +Schedule Tutorial
          </button>
          <ScheduleTut
            show={showScheduleModal}
            handleClose={closeScheduleModal}
          />
        </div>
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-lg my-5 text-blue"
              role="status"
            ></span>
          </div>
        ) : (
          <div className="container-fluid">
            <h2 className="text-grey viga-20-400">Requested Tutorials</h2>
            <TutorialUpcoming upcomingTut={upcomingTut} />
            <h2 className="text-grey viga-20-400">Scheduled Tutorials</h2>
            <TutorScheduled RequestedTut={RequestedTut} />
          </div>
        )}
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <MobileTutorNav />
    </section>
  );
};

export default TutorTutorial;

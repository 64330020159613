import { useState } from "react";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import InsufficientWallet from "../StudyResources/InsufficientWallet";
import MobileNav from "../../../components/MobileNav";
import LogoutModal from "../../../components/LogoutModal";
import { useEffect } from "react";
const WalletSubscription = () => {
  const [planName, setPlanName] = useState("");
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [showFundWalletModal, setshowFundWalletModal] = useState(false);
  const [basicLoading, setBasicLoading] = useState(false);
  const [silverLoading, setSilverLoading] = useState(false);
  const autoRenewStatus = sessionStorage.getItem("userAutoRenew");
  const [autoRenew, setAutoRenew] = useState(false);
  const currentPlan = sessionStorage.getItem("userPlan");

  const subCourse = (selectedPrice, selectedPlanName) => {
    setIsLoading(true);
    if (selectedPlanName === "Basic") {
      setBasicLoading(true);
    } else {
      setSilverLoading(true);
    }
    const obj = {
      plan: selectedPlanName,
      userid: userId,
      amount: `${selectedPrice}`,
    };
    axios
      .post("SubcribeFromWallet", obj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp);
          toast.success(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setBasicLoading(false);
          setSilverLoading(false);
          sessionStorage.setItem("userPlan", "Plan");
        } else {
          toast.warning(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          openFundWalletModal(selectedPrice, selectedPlanName);
          setBasicLoading(false);
          setSilverLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setBasicLoading(false);
        setSilverLoading(false);
      });
  };
  const openFundWalletModal = (price, planName) => {
    setPlanName(planName);
    setPrice(price);
    setshowFundWalletModal(true);
  };
  const closeFundWalletModal = () => {
    setshowFundWalletModal(false);
  };
  const cancelSubscription = () => {
    setIsLoading(true);
    axios
      .get(`CancelSubcription/${userId}`)
      .then((response) => {
        setIsLoading(false);
        toast.success("Subscription has been cancelled");
        sessionStorage.setItem("userPlan", "cancel");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          theme: "colored",
          autoClose: 3000,
        });
      });
  };
  const handleToggle = () => {
    const newAutoRenew = !autoRenew;
    setAutoRenew(newAutoRenew);
    console.log(newAutoRenew);
    const autoRenewValue = newAutoRenew ? "Y" : "N";
    const obj = { userId, Status: autoRenewValue };
    console.log(obj);
    axios
      .post("setAutoRenew", obj)
      .then((response) => {
        if (autoRenewValue === "Y") {
          toast.success("Auto renewal enabled");
        } else {
          toast.success("Auto renewal disabled");
        }
      })
      .catch((error) => {
        toast.error(error);
        console.error("Error sending autorenew value:", error);
      });
  };
  useEffect(() => {
    if (autoRenewStatus === "Y") {
      setAutoRenew(true);
    } else if (autoRenewStatus === "N") {
      setAutoRenew(false);
    }
  }, [autoRenewStatus]);
  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <div className="main-content p-1 p-md-3 ">
        <DashboardHeader />
        <div className="d-block px-3">
          <button
            className="btn p-0 mb-3 text-grey lato-24-600"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
          <div className="title">
            <h3 className="lato-24-400 text-blue">Subcription Plans</h3>
          </div>
          <div className="d-block d-md-flex mt-2 my-md-3">
            <div className="d-flex align-items-center">
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={autoRenew}
                  onChange={handleToggle}
                />
                <span className="sub-slider"></span>
              </label>
              <label className="lato-24-600 text-blue ps-3">Auto Renew</label>
            </div>
            <button
              className="blue-outline-btn ms-auto my-2"
              onClick={cancelSubscription}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm mx-5"
                  role="status"
                ></span>
              ) : (
                <span> Cancel Subscription</span>
              )}
            </button>
          </div>
        </div>

        <div className="mb-4 subcription-page">
          <div className="d-block d-md-flex justify-content-center p-5 p-md-1">
            <div className="basic-card-sub me-md-2 mb-4 p-0">
              <div className="sub-card-header p-3 p-md-5">
                <h5 className="blue-32-600 text-center">Basic</h5>
              </div>
              <div className="card-body text-center p-3 p-md-5">
                <h2 className="text-white poppins sub-price">₦1200</h2>
                <p className="text-white">/month</p>
                <ul className="plan-card-list">
                  <li>1 User Per Account</li>
                  <li>Access to 5 course study materials</li>
                  <li>Access to ETC AI Vibes</li>
                </ul>

                <button
                  className={`blue-filled-btn mt-1 mt-md-5 w-100 ${
                    currentPlan === "Basic" ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (currentPlan !== "Basic") {
                      subCourse(1200, "Basic");
                    }
                  }}
                  disabled={currentPlan === "Basic"}
                >
                  {basicLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></span>
                  ) : (
                    <>Subscribe</>
                  )}
                </button>
              </div>
            </div>
            <div className="silver-card-sub p-0">
              <div className="sub-card-header p-3 p-md-5">
                <h5 className="blue-32-600 text-center">Silver</h5>
              </div>
              <div className="card-body text-center p-3 p-md-5">
                <h2 className="text-white sub-price">₦2500</h2>
                <p className="text-white">/month</p>
                <ul className="plan-card-list">
                  <li>1 User Per Account</li>
                  <li>Access to 10 course study materials</li>
                  <li>Access to ETC AI Vibes</li>
                </ul>
                <button
                  className={`blue-filled-btn mt-1 mt-md-5 w-100 ${
                    currentPlan === "Silver" ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (currentPlan !== "Silver") {
                      subCourse(2500, "Silver");
                    }
                  }}
                  disabled={currentPlan === "Silver"}
                >
                  {silverLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></span>
                  ) : (
                    <>Subscribe</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileNav />
      <LogoutModal />
      <InsufficientWallet
        price={price}
        planName={planName}
        show={showFundWalletModal}
        handleClose={closeFundWalletModal}
      />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default WalletSubscription;

import { useState } from "react";
import "./auth.css";
import { Link } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { useEffect } from "react";
import Logo from "../../components/Logo";
import LoginStudent from "./LoginStudent";
import LoginTutor from "./LoginTutor";
const Login = () => {
  const [isStudentForm, setIsStudentForm] = useState(true);
  const [formType, setForm] = useState(<LoginStudent />);

  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const handleFormUser = () => {
    setForm(<LoginStudent />);
    setIsStudentForm(true);
  };
  const handleFormTutor = () => {
    setForm(<LoginTutor />);
    setIsStudentForm(false);
  };

  return (
    <div className="container-fluid">
      <ToastContainer transition={Zoom}></ToastContainer>
      <div className="row">
        <div className="col-md-5 signup-left">
          <Logo />
          <div className="px-4 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-white viga-24-400">ETC Vibes</h2>
              <p className="text-white w-75">
                providing you with everything required to make your striaght As!
                on a single platform.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-7 auth-white p-3 d-flex">
          <div className="container form-container px-3 m-auto">
            <div className="d-flex justify-content-center d-flex d-md-none">
              <Logo />
            </div>
            <h3 className="my-3 viga-24-400 text-blue login-header">
              Welcome back!
            </h3>
            <p className="span-grey">
              Login to Your Account and Keep the Momentum Going
            </p>
            <div className="d-block d-md-flex align-items-center">
              <div className="account-btn-group d-flex my-3 m-auto m-md-0">
                <button
                  className={
                    isStudentForm ? "btn btn-blue px-4" : "btn btn-white px-4"
                  }
                  id="student-btn"
                  type="button"
                  onClick={handleFormUser}
                >
                  As a Student
                </button>
                <button
                  className={
                    !isStudentForm ? "btn btn-blue px-4" : "btn btn-white px-4"
                  }
                  id="tutor-btn"
                  type="button"
                  onClick={handleFormTutor}
                >
                  As a Tutor
                </button>
              </div>
            </div>
            {formType}
            <span className="login-redirect">
              Dont have an account?
              <Link to="/signup" className="blue-span ps-1">
                Sign up
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

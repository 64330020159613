import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";
import LogoutModal from "../../../components/LogoutModal";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const StudentDetails = () => {
  const { studentId } = useParams(); // Get the student ID from the URL
  const [resource, setResource] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(`ShoweachStudentRecord/${studentId}`);
        const studentDetails = response.data.data.message;
        setResource(studentDetails);
        setloading(false);
      } catch (error) {
        toast.error("Error fetching student details:", error);
        setloading(false);
      }
    };

    // Call the fetchStudentDetails function
    fetchStudentDetails();
  }, [studentId]);

  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid page-details">
          <div className="d-flex align-items-center">
            <Link to="/adminstudent">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
            <span className="viga-24-400">Student Profile</span>
          </div>
          <div className="container">
            <div className="d-block d-md-flex mb-3 align-items-center">
              <div className="d-flex ms-auto">
                <button className="grey-btn me-2 ">Suspend Account</button>
                <button className="btn btn-danger mx-2">Delete Account</button>
              </div>
            </div>
          </div>
          {loading ? (
            <span
              className="spinner-border spinner-border-lg text-primary"
              role="status"
            ></span>
          ) : (
            <div className="container">
              {resource.map((details) => (
                <div className="d-block mb-4" key={details.id}>
                  <div className="student-img-admin">
                    <img src={details.Profile} alt="profile-pic" />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Name: </span>
                    <span className="lato-24-400">{details.Fullname}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Nickname: </span>
                    <span className="lato-24-400">{details.Nickname}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Email :</span>
                    <span className="lato-24-400">
                      {details["Email Address"] || "N/A"}
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Phone Number:</span>
                    <span className="lato-24-400">
                      {details["Phone Number"]}
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">University:</span>
                    <span className="lato-24-400">{details.School}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Department:</span>
                    <span className="lato-24-400">{details.Program}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Level:</span>
                    <span className="lato-24-400">{details.Level}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Wallet:</span>
                    <span className="lato-24-400">{details.Amount}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Subscription:</span>
                    <span className="lato-24-400">{details.Subscription}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default StudentDetails;

import { Link } from "react-router-dom";

const AdminResourceCardTwo = () => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">TextBooks</h3>
          <Link to="/adminresource/textbooks" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Notes</h3>
          <Link to="/adminresource/notes" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Past Questions</h3>
          <Link to="/adminresource/pq" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Videos </h3>
          <Link to="/adminresource/videos" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Quiz </h3>
          <Link to="/adminresource/quiz" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">FlashCards </h3>
          <Link to="/adminresource/flashcards" className="text-blue">
            Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminResourceCardTwo;

import { useState } from "react";
import AdminAllPost from "./AdminAllPost";
import AdminPublishedPost from "./AdminPublishedPost";
import AdminDraftPosts from "./AdminDraftPosts";
import { ToastContainer, Zoom } from "react-toastify";

const AdminPostContainer = () => {
  const [isAllPostActive, setisAllPostActive] = useState(true);
  const [isPubPostActive, setisPubPostActive] = useState(false);
  const [isDraftPostActive, setisDraftPostActive] = useState(false);
  const [content, setContent] = useState(<AdminAllPost />);

  const handleAllPost = () => {
    setisAllPostActive(true);
    setisDraftPostActive(false);
    setisPubPostActive(false);
    setContent(<AdminAllPost />);
  };
  const handlePublishedPost = () => {
    setisAllPostActive(false);
    setisDraftPostActive(false);
    setisPubPostActive(true);
    setContent(<AdminPublishedPost />);
  };
  const handleDraftPost = () => {
    setisAllPostActive(false);
    setisDraftPostActive(true);
    setisPubPostActive(false);
    setContent(<AdminDraftPosts />);
  };

  const allPostBtn = isAllPostActive
    ? "course-btn-active"
    : "course-btn-inactive";
  const pubPostBtn = isPubPostActive
    ? "course-btn-active"
    : "course-btn-inactive";
  const draftPostBtn = isDraftPostActive
    ? "course-btn-active"
    : "course-btn-inactive";

  return (
    <section className="px-1 px-md-2">
      <div className="d-flex mb-3">
        <button className={allPostBtn} onClick={handleAllPost}>
          <h3 className="font-24-700 pe-2 ps-1">All</h3>
        </button>
        <button className={pubPostBtn} onClick={handlePublishedPost}>
          <h3 className="font-24-700 px-2">Published</h3>
        </button>
        <button className={draftPostBtn} onClick={handleDraftPost}>
          <h3 className="font-24-700 px-2">Draft</h3>
        </button>
      </div>
      <div className="d-flex ps-2">
        <select className="post-select" aria-label="Default select example">
          <option value="general">General</option>
          <option value="student">Student</option>
          <option value="tutor">Tutor</option>
        </select>
      </div>
      <div>{content}</div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default AdminPostContainer;

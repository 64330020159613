import React from "react";

const TutorReadMore = () => {
  return (
    <div
      className="modal fade"
      id="tutorReadMoreModal"
      aria-labelledby="tutorReadMoreModalLabel"
      aria-hidden="true"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header readmore-haeader">
            <h5 className="modal-title" id="tutorReadMoreModalLabel">
              Biology
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="tutorial-info p-3">
                <div className="d-flex">
                  <span className="lato-12-300 pe-1">Date: </span>
                  <div className="d-block">
                    <p className="lato-12-400 fw-bold">
                      28th/ March/ 2023
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <span className="lato-12-300 pe-1">Time: </span>
                  <div className="d-block">
                    <p className="lato-12-400 fw-bold ">8pm</p>
                  </div>
                </div>
                <div className="d-flex">
                  <span className="lato-12-300 pe-1">Status:</span>
                  <div className="d-block">
                    <p className="lato-12-400 text-blue fw-bold">
                      Upcoming (Requested)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorReadMore;

import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
const GoogleLoginDiv = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCredentialResponse = async (response) => {
    setLoading(true);
    let userObject = jwtDecode(response.credential);
    const email = userObject.email;
    const password = "DummyPassword123";
    const logobj = { email, password };

    axios
      .post("LoginUsers", logobj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log("Login successful!");
          console.log(resp);
          setLoading(false);
          navigate("/userdashboard");
          sessionStorage.setItem("userID", resp.UserId);
        } else {
          toast.error("Invalid Username or Password", {
            theme: "colored",
            autoClose: 3000,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Login failed due to:" + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </span>
      ) : (
        <GoogleLogin
          onSuccess={handleCredentialResponse}
          onError={() => {
            console.log("Login Failed");
          }}
          className="google-btn"
        />
      )}
    </>
  );
};

export default GoogleLoginDiv;

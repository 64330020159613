import courseIcon from "../../../assets/img/courses.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import axios from "../../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { JellyTriangle } from "@uiball/loaders";
import UploadAndEarnModal from "./UploadAndEarnModal";
const MyCourse = ({ redirect }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const userID = sessionStorage.getItem("userID");
  const [myCourseResources, setMyCourseResources] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`StudyResources/${userID}`);
        const userdata = response.data.data.message;
        if (
          userdata === null ||
          userdata === "" ||
          userdata === "No courses has been Added on my course" ||
          typeof userdata !== "object"
        ) {
          setMyCourseResources([]);
        } else {
          setMyCourseResources(userdata);
        }
        setLoading(false);
      } catch (error) {
        toast.error("failed to fetch user data", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    };
    fetchUserData();
  }, [userID]);
  const openDeleteConfirmation = (courseId) => {
    setSelectedCourseId(courseId);
    setShowDeleteConfirmation(true);
  };
  const closeDeleteConfirmation = () => {
    setSelectedCourseId(null);
    setShowDeleteConfirmation(false);
  };
  const handleConfirmDelete = async () => {
    try {
      await axios.post(`RemoveCourses/${userID}`, {
        data: { CoursesId: selectedCourseId },
      });
      const updatedCourseResources = myCourseResources.filter(
        (course) => course.courseId !== selectedCourseId
      );
      setMyCourseResources(updatedCourseResources);
      closeDeleteConfirmation();
      toast.success("Course removed", {
        theme: "colored",
        autoClose: "2000",
      });
    } catch (error) {
      toast.error("Failed to delete course", {
        theme: "colored",
        autoClose: 3000,
      });
    }
  };

  //
  const openModal = () => setUploadModal(true);
  const closeModal = () => setUploadModal(false);
  return (
    <>
      {loading ? (
        <div className="container d-flex justify-content-center pt-5">
          <JellyTriangle size={80} speed={1.75} color="#044aac" />
          <ToastContainer transition={Zoom}></ToastContainer>
        </div>
      ) : (
        <section className="container-fluid p-3 p-md-3 mb-5 mb-md-2 mb-lg-0">
          <div className="my-course-header d-flex my-3">
            <button className="blue-filled-btn ms-auto " onClick={openModal}>
              Upload, and Earn money!
            </button>
          </div>
          {/* No plan */}
          {myCourseResources.length === 0 && (
            <div className="container text-center mt-5">
              <p>You have not added any course </p>
              <button
                className="blue-filled-btn"
                onClick={() => {
                  redirect();
                }}
              >
                Add Courses
              </button>
            </div>
          )}
          {/* Basic plan */}
          {myCourseResources.length > 0 && (
            <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 ">
              {myCourseResources.map((mycourse) => (
                <div className="col" key={mycourse.CourseId}>
                  <div className="my-course-card mb-4 mb-md-5">
                    <div className="my-courses-img">
                      <img src={courseIcon} className="my-course-img" alt="" />
                    </div>
                    <div className="my-course-body p-3 w-100">
                      <div className="d-block">
                        <h4 className="roboto-14-700">{mycourse.CourseName}</h4>
                        {/* <h5 className="roboto-12-400">{mycourse.CourseCode}</h5> */}
                        <span className="roboto-12-400">
                          Everything You need to pass this course is right in
                          here just click to have access to all our amazing
                          study resources
                        </span>
                      </div>
                      <div className="d-flex mt-4">
                        <Link
                          to={`/courses/coursepage/${
                            mycourse.CourseId
                          }?courseName=${encodeURIComponent(
                            mycourse.CourseName
                          )}`}
                          className="ms-auto roboto-10-400 text-decoration-none"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <DeleteConfirmationModal
            show={showDeleteConfirmation}
            handleClose={closeDeleteConfirmation}
            handleConfirmDelete={handleConfirmDelete}
          />
          <UploadAndEarnModal show={uploadModal} handleClose={closeModal} />
        </section>
      )}
    </>
  );
};

export default MyCourse;

import React from "react";
import whiteLogo from "../../assets/whiteLogo.png";
import iconSuccess from "../../assets/img/successIcon.svg";
import "./auth.css";
import { Link } from "react-router-dom";

const RegistrationSuccess = () => {
  return (
    <div className="container-fluid container-gradient">
      <div className="logo-center text-center">
        <img src={whiteLogo} alt="Logo" className="logo" />
      </div>
      <div
        className="container congratulations text-center"
        style={{ width: "fit-content" }}
      >
        <h1 className="viga-32-400 text-white mb-5">
          Congratulations !
          <img src={iconSuccess} alt="" className="blue-check" />
        </h1>

        <h5 className="font-20-600 text-white">Your account is now active.</h5>

        <div className="toDashboard my-2">
          <Link to="/courses">
            <button className="white-btn mt-4 span-white-600">
              Start Learning
            </button>
          </Link>
        </div>

        <span className="text-white">
          Explore our study materials and tutorials.
        </span>
      </div>
    </div>
  );
};

export default RegistrationSuccess;

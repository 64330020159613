import { useState } from "react";
import AllTutorial from "./AllTutorial";
import RequestedTut from "./RequestedTut";
import { Link } from "react-router-dom";
import RequestTutModal from "./RequestTutModal";
import Recorded from "./Recorded";
export const TutorialContainer = () => {
  const [tutorialState, setTutorialState] = useState(<AllTutorial />);
  const [allTutBtn, setAllTutBtn] = useState("tutorial-active");
  const [reqTutBtn, setReqTutBtn] = useState("tutorial-inactive");
  const [recTutBtn, setRecTutBtn] = useState("tutorial-inactive");
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const handleAllTut = () => {
    setReqTutBtn("tutorial-inactive");
    setRecTutBtn("tutorial-inactive");
    setAllTutBtn("tutorial-active ");
    setTutorialState(<AllTutorial />);
  };
  const handleRecordedTut = () => {
    setReqTutBtn("tutorial-inactive");
    setAllTutBtn("tutorial-inactive ");
    setRecTutBtn("tutorial-active ");
    setTutorialState(<Recorded />);
  };
  const handleRequestedTut = () => {
    setAllTutBtn("tutorial-inactive ");
    setRecTutBtn("tutorial-inactive ");
    setReqTutBtn("tutorial-active");
    setTutorialState(<RequestedTut />);
  };
  const closeRequestModal = () => {
    setOpenRequestModal(false);
  };

  return (
    <div className="container-fluid tutorial-container p-2 mb-5 mb-md-0">
      <div className="d-block d-md-flex tutorial-top-nav">
        <div className="tutorial-routes d-flex">
          <div className="px-2">
            <p className={allTutBtn} role="button" onClick={handleAllTut}>
              All 
            </p>
          </div>
          <div className="px-2">
            <p className={reqTutBtn} role="button" onClick={handleRequestedTut}>
              Requested 
            </p>
          </div>
          <div className="px-2">
            <p className={recTutBtn} role="button" onClick={handleRecordedTut}>
              Recorded 
            </p>
          </div>
        </div>
        <div className="d-flex mb-2 ms-auto align-items-center">
          <Link className="mx-2">
            <button
              className="blue-filled-btn"
              onClick={() => setOpenRequestModal(true)}
            >
              <i className="bi bi-plus"></i> Request Tutorial
            </button>
          </Link>
          <RequestTutModal
            show={openRequestModal}
            handleClose={closeRequestModal}
          />
        </div>
      </div>

      {tutorialState}
    </div>
  );
};

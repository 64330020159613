import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../../../api/axios";

const AdminTextbookTable = () => {
  const [loading, setLoading] = useState(false);
  const [textbook, setTextBooks] = useState([]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get("listtextbook");
        const resp = response.data.data;

        if (resp.message === "" || resp.message === "Record was not found") {
          setTextBooks([]);
        } else {
          setTextBooks(resp.message);
        }
        setLoading(false);
        console.log(resp.message);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <section>
      {loading ? (
        <span className="spinner-border spinner border-sm text-blue"></span>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="resource-head">
                <th scope="col">S/N</th>
                <th scope="col">Name</th>
                <th scope="col">Course</th>
                <th scope="col">Course Code</th>
                <th scope="col">Department</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {textbook.map((book, index) => (
                <tr key={book.id}>
                  <td>{index + 1}</td>
                  <td>{book.Name}</td>
                  <td>{book.Course}</td>
                  <td>{book.VideoCourseCode}</td>
                  <td>{book.Department}</td>
                  <td>
                    <i className="bi bi-trash-fill text-danger"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default AdminTextbookTable;

import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import MobileProfile from "../../../components/MobileProfile";
import DashboardHeader from "../DashboardHeader";
import BotInterface from "./BotInterface";
import MobileNav from "../../../components/MobileNav";
import bot from "../../../assets/aibot.jpg";
const EtcBot = () => {
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <MobileProfile />
      <div className="main-content p-1 p-md-3 aivibes">
        <DashboardHeader />
        <div className="px-3 d-flex align-items-center">
          <div className="d-block">
            <p className="viga-20-400">Get Answers in few Seconds!</p>
            <span className="lato-20-400">
              Ask any question using our Ai Vibes chats
            </span>
          </div>
          <div className="ai-icon ms-auto">
            <img style={{ maxWidth: "120px" }} src={bot} alt="" />
          </div>
        </div>
        <div className="container">
          <BotInterface />
        </div>
      </div>
      <MobileNav />
    </div>
  );
};

export default EtcBot;

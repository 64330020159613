import { Link } from "react-router-dom";
import aboutpic from "../../assets/unsplash.png";
import underpic from "../../assets/Vector 9.png";

const Aboutus = (props) => {
  return (
    <div className={props.tutor}>
      <div className="aboutus">
        <div className="aboutpic">
          <img src={aboutpic} alt="" />
        </div>
        <div className="abouttext" id="about">
          <div className="abouth1">
            <h2>About US</h2>
            <img className="underpic" src={underpic} alt="" />
          </div>
          <h3>
            At ETC Vibes, we're on a mission to revolutionize education through
            technology, aimed at improving academic performace. With a passion
            for empowering learners worldwide, we provide a dynamic platform
            where students can access personalized and collaborative learning
            experiences. From personalized tutorials tailored to individual
            needs to engaging group sessions that foster community learning,
            Quizzes, Past Questions, Flashcards, Notes, AI, and many more. ETC
            Vibes is your ultimate destination for academic excellence. Join us
            on this journey of discovery, innovation, and growth!
          </h3>
          <Link to="/signup">
            <button>Get Started</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;

import React from "react";
import RevenueChart from "./RevenueChart";

const RevenueChartComponent = ({ finData }) => {
  // Check if finData is defined and has TotalRevenue property
  const formattedTotalRevenue =
    finData && finData.TotalRevenue
      ? finData.TotalRevenue.toLocaleString()
      : "N/A";
  return (
    <div className="revenue-chart-component">
      <div className="revenue-chart-header p-2 p-md-4">
        <div className="d-flex">
          <h5 className="text-grey lato-16-400">Revenue</h5>
          <div className="dropdown ms-auto">
            <span
              className="dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Last 30 days
            </span>
            <ul className="dropdown-menu">
              <li className="dropdown-item">Today</li>
              <li className="dropdown-item">Last Week</li>
              <li className="dropdown-item">Last year</li>
            </ul>
          </div>
        </div>
        <div className="d-block">
          <h4 className="viga-40-400 total-revenue-value">
            ₦ {formattedTotalRevenue}
          </h4>
          <span className="text-grey">Total Money Generated </span>
        </div>
      </div>
      <div className="revenue-chart-container py-3 py-md-4">
        <RevenueChart />
      </div>
    </div>
  );
};

export default RevenueChartComponent;

import { useEffect } from "react";
import { useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
const AdminDepartmentsTable = () => {
  const [tableData, setTableData] = useState([]);
  const itemsPerPage = 20; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [loading, setLoading] = useState(false);
  const [dloading, setdLoading] = useState(false);
  const [faculty, setfaculty] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get("listDepartment");
        const facData = await axios.get("Faculty");
        const resp = response.data.data;
        const fac = facData.data.data;
        setfaculty(fac.message);
        setTableData(resp.message);
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getFacultyNameById = (facultyId) => {
    const foundFaculty = faculty.find((f) => f.id === parseInt(facultyId, 10));
    console.log(faculty);
    return foundFaculty ? foundFaculty.faculty_name : "Unknown Faculty";
  };

  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = tableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const deleteDepartment = async (uni) => {
    setdLoading(true);
    try {
      const response = await axios.get(`deleteDepartment/${uni}`);
      const resp = response.data.data.message;
      toast(resp);
      setdLoading(false);
    } catch (error) {
      toast.error(error);
      setdLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <span
          className="spinner-border spinner-border-xl text-blue"
          role="status"
        ></span>
      ) : (
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  S/N
                </th>
                <th scope="col" className="py-3">
                  Department
                </th>
                <th scope="col" className="py-3">
                  Faculty
                </th>

                <th scope="col" className="py-3"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} className="table-row">
                  <td>{item.id}</td>
                  <td>{item.dept_name}</td>
                  <td>{getFacultyNameById(item.faculty_id)}</td>
                  <td>
                    {dloading ? (
                      <span className="spinner-border text-danger"></span>
                    ) : (
                      <i
                        className="bi bi-trash3-fill text-danger"
                        onClick={() => deleteDepartment(item.id)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(tableData.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className={`btn pagination-button mx-1 ${
                    currentPage === index + 1
                      ? "btn-primary active"
                      : "btn-secondary"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDepartmentsTable;

import TutorSidebar from "../TutorSidebar";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import TutorHelpCenter from "./TutorHelpCenter";
import MobileTutorNav from "../../../components/MobileTutorNav";

const TutorHelp = () => {
  return (
    <section className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 tutorDashboard">
        <TutorHeader />
        <div className="container-fluid help-container bg-white px-4 mb-5 mb-md-2">
          <div className="help-header text-center py-2">
            <span className="viga-24-400">Help Center</span>
          </div>
          <TutorHelpCenter />
        </div>
      </div>
      <MobileTutorNav />
    </section>
  );
};

export default TutorHelp;

import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import LogoutModal from "../../../components/LogoutModal";
import AdminHeader from "../AdminHeader";
import FinanceHomeCards from "./FinanceHomeCards";
import RevenueChartComponent from "./RevenueChartComponent";
import RevenueInfo from "./RevenueInfo";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AdminFinance = () => {
  const [finData, setFinData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("FinanceRecord");
        const resp = response.data.data;
        setFinData(resp.message);
        console.log(resp);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid">
          <h2 className="lato-20-700 text-blue">Finance Overview</h2>
          <FinanceHomeCards finData={finData} />
          <div className="d-flex align-items-center mb-4">
            <span className="roboto-24-700">Coin Conversion Requests</span>
            <Link to="/admin/coinrequest">
              <button className="blue-filled-btn ms-5">View Details</button>
            </Link>
          </div>
          <RevenueChartComponent finData={finData} />
          <RevenueInfo />
        </div>
      </div>
    </section>
  );
};

export default AdminFinance;

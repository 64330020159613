import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const AdminAllPost = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [dataloader, setDataloader] = useState(true);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [venue, setVenue] = useState("");
  const [loading, setLoading] = useState(false);

  const publish = async () => {
    setLoading(true);
    const obj = {
      title,
      message,
      link,
      date,
      time,
      venue,
    };
    try {
      const response = await axios.post("PublishAnnouncement", obj);
      const resp = response.data;

      if (resp.message === "Announcement published successfully") {
        toast.success("Announcement published!", {
          theme: "colored",
        });
        const updatedPosts = await axios.get("fetchPublishedAnnouncement");
        setAllPosts(updatedPosts.data);

        // Clear the input fields
        setTitle("");
        setMessage("");
        setLink("");
        setDate("");
        setTime("");
        setVenue("");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const saveDraft = async () => {
    setLoading(true);
    const obj = {
      title,
      message,
      link,
      date,
      time,
      venue,
    };
    try {
      const response = await axios.post("SaveAnnouncement", obj);
      const resp = response.data;

      if (resp.message === "Announcement saved as draft successfully") {
        toast.success("Announcement saved!", {
          theme: "colored",
        });
        // Clear the input fields
        setTitle("");
        setMessage("");
        setLink("");
        setDate("");
        setTime("");
        setVenue("");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getAllPosts = async () => {
      try {
        const response = await axios.get("fetchPublishedAnnouncement");
        setAllPosts(response.data);
        setDataloader(false);
      } catch (error) {
        toast.error(error.message, {
          theme: "colored",
        });
      }
    };
    getAllPosts();
  }, []);

  return (
    <main className="px-2 py-3">
      <div className="d-flex">
        <div className="new-post-container">
          <div className="d-flex border-bottom px-2">
            <input type="checkbox" defaultChecked />
            <span className="viga-20-400 ps-3">Latest Announcement</span>
          </div>
          <div className="d-flex border-bottom px-2">
            <input type="checkbox" />
            <input
              type="text"
              placeholder="Title"
              className="new-post-input ps-3"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="d-flex border-bottom align-items-center px-2">
            <input type="checkbox" />
            <textarea
              placeholder="Write message"
              className="new-post-input ps-3 w-100"
              rows={3}
              cols={50}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="d-flex w-100">
            <div className="px-2 d-flex">
              <input type="checkbox" defaultChecked />
              <input
                type="date"
                className="new-post-input ps-3"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="px-2 d-flex">
              <input type="checkbox" defaultChecked />
              <input
                type="time"
                className="new-post-input ps-3"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <div className="px-2 d-flex">
              <input type="checkbox" defaultChecked />
              <input
                type="text"
                className="new-post-input ps-3"
                placeholder="Venue"
                value={venue}
                onChange={(e) => setVenue(e.target.value)}
              />
            </div>
            <div className="px-2 d-flex">
              <input type="checkbox" />
              <input
                type="text"
                className="new-post-input ps-3 w-75"
                placeholder="Add link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="p-2 d-flex">
          <div className="mt-auto">
            <button className="save-draft-btn mb-3" onClick={saveDraft}>
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm text-blue"
                  role="status"
                ></span>
              ) : (
                <>Save</>
              )}
            </button>{" "}
            <br />
            <button className="blue-filled-btn" onClick={publish}>
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                ></span>
              ) : (
                <>Publish</>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="admin-posts-highlights">
        <div className="posts-highlight-container">
          {dataloader ? (
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            allPosts &&
            allPosts.map((post, index) => (
              <div className="posts-highlight-item p-2 p-md-3 my-3" key={index}>
                <h2 className="viga-24-400 ps-3">Latest Announcement</h2>
                <div className="d-flex align-items-center">
                  <p className="text-blue viga-20-400">{post.title}</p>
                  <p className="text-secondary ms-4">
                    <i className="bi bi-calendar-check pe-2"></i>
                    {post.date}
                    <span> at </span>
                    {post.time}
                  </p>
                </div>
                <p>{post.message}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </main>
  );
};

export default AdminAllPost;

import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
const RequestTutModal = ({ show, handleClose }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [TutorialTitle, setTutorialTitle] = useState("");
  const [CourseTitle, setCourseTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [Courseid, setCourseId] = useState("");
  const [Duration, setDuration] = useState("45");
  const userId = sessionStorage.getItem("userID");
  const [facultyId, setFacultyId] = useState("");

  useEffect(() => {
    axios
      .get("Department")
      .then((response) => {
        setDepartments(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleSelectChange = (event) => {
    const selectedDepartmentId = event.target.value;
    setCourseId(selectedDepartmentId);

    // Find the selected department in the departments array
    const filteredDepartments = departments.filter(
      (department) =>
        department.DepartmentId === parseInt(selectedDepartmentId, 10)
    );

    console.log(filteredDepartments[0]);
    console.log(filteredDepartments[0].FaultyId);
    // Extract and set the facultyId from the selected department
    if (filteredDepartments) {
      setFacultyId(filteredDepartments[0].FaultyId);
    }
  };
  const handleRadioChange = (event) => {
    setSelectedType(event.target.value); // Update the state when a radio button is clicked
  };
  const validateForm = () => {
    let result = true;
    if (TutorialTitle === "" || TutorialTitle === null) {
      result = false;
      toast.warning("Select a course");
    }

    if (selectedType === "" || selectedType === null) {
      result = false;
      toast.warning("Select a tutorial type");
    }
    if (selectedTime === "" || selectedTime === null) {
      result = false;
      toast.warning("Pick a time");
    }
    if (selectedDate === "" || selectedDate === null) {
      result = false;
      toast.warning("Pick a date");
    }
    if (CourseTitle === "" || CourseTitle === null) {
      result = false;
      toast.warning("Write your desired topic");
    } else {
      const currentDate = new Date();
      const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);

      if (selectedDateTime < currentDate) {
        result = false;
        toast.warning("Selected date and time must be in the future");
      }
    }
    return result;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      setIsLoading(true);
      // Convert selectedDate and selectedTime to the desired format
      const datetime = `${selectedDate}T${selectedTime}:00Z`;

      const requestData = {
        Userid: userId,
        DepartmentId: Courseid,
        TutorialTitle,
        Course: CourseTitle,
        Duration,
        time: datetime,
        faultyid: facultyId,
      };
      console.log(requestData);
      // Send the request to the API.
      axios
        .post("RequestTutorial", requestData)
        .then((response) => {
          const resp = response.data.data;
          if (resp.status === "success") {
            toast.success("Tutorial request successful");
          } else {
            toast.warning(resp.message);
          }
          setIsLoading(false);
          handleClose();
        })
        .catch((error) => {
          toast.error("Error submitting request:", {
            theme: "colored",
            autoClose: "2000",
          });
          setIsLoading(false);
          handleClose();
        });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <div className="form request-tutorial">
          <h3 className="poppins-24-600 text-blue text-center">
            Request Tutorial
          </h3>
          <div className="d-flex justify-content-center my-4">
            <div className="form-check px-3">
              <label className="form-check-label pe-2">Personal Tutorial</label>
              <input
                className="requestTutorialType"
                type="radio"
                value="personal" // Set the value for the personal radio button
                checked={selectedType === "personal"} // Check if it's selected
                onChange={handleRadioChange} // Handle the change event
              />
            </div>
            {/* <div className="form-check px-3">
              <label className="form-check-label pe-2">Group Tutorial</label>
              <input
                className="requestTutorialType"
                type="radio"
                name="group"
                value="group" // Set the value for the group radio button
                checked={selectedType === "group"} // Check if it's selected
                onChange={handleRadioChange} // Handle the change event
              />
            </div> */}
          </div>
          <div className="mb-4">
            <label htmlFor="Course" className="poppins-24-500">
              Department
            </label>
            <select
              className="login-input"
              value={Courseid}
              onChange={handleSelectChange}
            >
              <option value="">Select a course</option>
              {departments.map((course) => (
                <option key={course.DepartmentId} value={course.DepartmentId}>
                  {course.Department}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-24-500">
              Tutorial Title
            </label>
            <input
              type="text"
              className="login-input"
              value={TutorialTitle}
              placeholder="Machine learning"
              onChange={(e) => setTutorialTitle(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-24-500">
              Course Code
            </label>
            <input
              type="text"
              className="login-input"
              value={CourseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
              placeholder="CSC 101"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="" className="poppins-24-500">
              Duration
            </label>
            <select
              value={Duration}
              onChange={(e) => setDuration(e.target.value)}
              className="login-input"
            >
              <option value="45">45 Minutes</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="Topic ?" className="poppins-24-500">
              Date
            </label>
            <input
              type="date"
              className="login-input"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Time" className="poppins-24-500">
              Time
            </label>
            <input
              type="time"
              className="login-input"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="poppins-24-500"> Price</label>
            <input
              type="text"
              disabled
              placeholder="₦2000"
              className="login-input"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="blue-filled-btn"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <>Send request</>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestTutModal;

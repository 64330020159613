import { Link } from "react-router-dom";

const AdminResourceCards = () => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Universities</h3>
          <Link to="/adminresource/universities" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Faculties</h3>
          <Link to="/adminresource/faculty" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Departments</h3>
          <Link to="/adminresource/departments" className="text-blue">
            Details
          </Link>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Courses </h3>
          <Link to="/adminresource/courses" className="text-blue">
            Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminResourceCards;

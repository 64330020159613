import { useState } from "react";
import TutorHeader from "./TutorHeader";
import TutorSidebar from "./TutorSidebar";
import LogoutModal from "../../components/LogoutModal";
import MobileTutorNav from "../../components/MobileTutorNav";
import axios from "../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";

const TutorProfile = () => {
  const tutorId = sessionStorage.getItem("tutorId");
  const initialProfile = {
    tutorname: sessionStorage.getItem("tutorname"),
    tutorPic: sessionStorage.getItem("tutorPic"),
    tutorNickname: sessionStorage.getItem("tutorNickname"),
    tutorEmail: sessionStorage.getItem("tutorEmail"),
    tutorPhone: sessionStorage.getItem("tutorPhone"),
    tutorSchool: sessionStorage.getItem("tutorSchool"),
    tutorQualification: sessionStorage.getItem("tutorQualification"),
  };

  const [profile, setProfile] = useState(initialProfile);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSaveProfile = () => {
    const formData = new FormData();
    formData.append("NickName", profile.tutorNickname);
    formData.append("PhoneNumber", profile.tutorPhone);

    if (profilePicFile) {
      formData.append("ProfilePic", profilePicFile);
    } else {
      formData.append("ProfilePic", profile.userPic);
    }

    setSaving(true);

    axios
      .post(`UpdateTutorProfile/${tutorId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp.message);
          toast.success("Profile Updated", {
            theme: "colored",
            autoClose: 2000,
          });
          setProfile({
            ...profile,
            userPic: resp.UserRecord.ProfilePicName,
            nickname: resp.UserRecord.NickName,
            userPhone: resp.UserRecord.PhoneNumber,
          });
          setProfilePicFile(null);
          setSaving(false);
          setEditMode(false);
        } else {
          toast.error(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setSaving(false);
        }
      })
      .catch((err) => {
        toast.error("Edit failed: " + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setSaving(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfilePicChange = (e) => {
    setProfilePicFile(e.target.files[0]);
  };
  return (
    <section className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 userDashboard">
        <TutorHeader />
        <div className="p-4">
          <h2 className="viga-24-400">My Profile</h2>
          <div className=" mb-3"></div>
          <div className=" mb-3">
            <img
              src={profile.tutorPic}
              className="profile-pic-lg mb-2"
              alt="Profile pic"
            />
            {editMode ? (
              <input
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
                className="form-control w-50"
              />
            ) : (
              ""
            )}
          </div>
          <div className="profile-info">
            <div className="d-block d-md-block">
              {/* full name */}
              <div className="d-flex mb-2 me-2 me-md-5">
                <i className="bi bi-person-circle profile-grey me-2"></i>
                <div className="d-block ">
                  <p className="profile-grey m-0">Full Name</p>
                  <span className="profile-name lato-20-600">
                    {profile.tutorname}
                  </span>
                </div>
              </div>
              {/* email */}
              <div className="d-flex mb-2">
                <i className="bi bi-envelope profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Email Address</p>
                  <span className="profile-name lato-20-600">
                    {profile.tutorEmail}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-block d-md-block">
              {/* School */}
              <div className="d-flex mb-3">
                <i className="bi bi-building profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">School</p>
                  <span className="profile-name lato-20-600">
                    {profile.tutorSchool}
                  </span>
                </div>
              </div>
              {/* Qualification */}
              <div className="d-flex mb-3">
                <i className="bi bi-journal-medical profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Qualification</p>
                  <span className="profile-name lato-20-600">
                    {profile.tutorQualification}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-block ">
              {/* Phone Number */}
              <div className="d-flex mb-2 me-2 me-md-5">
                <i className="bi bi-telephone profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Phone Number</p>
                  {editMode ? (
                    <input
                      type="text"
                      name="tutorPhone"
                      className="form-control"
                      value={profile.tutorPhone}
                      onChange={handleChange}
                    />
                  ) : (
                    <span className="profile-name lato-20-600">
                      {profile.tutorPhone}
                    </span>
                  )}
                </div>
              </div>
              {/* Nickname */}
              <div className="d-flex mb-2 me-2 me-md-5">
                <i className="bi bi-person-arms-up profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Nick Name</p>
                  {editMode ? (
                    <input
                      type="text"
                      name="tutorNickname"
                      className="form-control"
                      value={profile.tutorNickname}
                      onChange={handleChange}
                    />
                  ) : (
                    <span className="profile-name lato-20-600">
                      {profile.tutorNickname}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex">
              {editMode ? (
                <>
                  <button
                    className="blue-filled-btn ms-auto"
                    onClick={handleSaveProfile}
                    disabled={saving}
                  >
                    {saving ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="grey-btn ms-2"
                    onClick={handleToggleEditMode}
                    disabled={saving}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className="blue-filled-btn ms-auto"
                  onClick={handleToggleEditMode}
                >
                  Edit Profile
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <MobileTutorNav />
    </section>
  );
};

export default TutorProfile;

import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const AdminDraftPosts = () => {
  const [draftPosts, setDraftPosts] = useState([]);
  const [dataloader, setDataloader] = useState(true);
  useEffect(() => {
    const getAllPosts = async () => {
      try {
        const response = await axios.get("fetchSavedAnnouncement");
        setDraftPosts(response.data);
        setDataloader(false);
      } catch (error) {
        toast.error(error.message, {
          theme: "colored",
        });
      }
    };
    getAllPosts();
  }, []);
  return (
    <main className="px-2 py-2">
      <div className="row row-cols-1 row-cols-md-2 g-3">
        {dataloader ? (
          <div className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          draftPosts &&
          draftPosts.map((post, index) => (
            <div className="col" key={index}>
              <div className="posts-highlight-item p-2 p-md-3 my-3">
                <div className="d-flex">
                  <h2 className="viga-24-400 ps-3">Latest Announcement</h2>
                  <button className="ms-auto blue-outline-btn">Publish</button>
                </div>
                <div className="d-flex align-items-center">
                  <p className="text-blue viga-20-400">{post.title}</p>
                  <p className="text-secondary ms-4">
                    <i className="bi bi-calendar-check pe-2"></i>
                    {post.date}
                    <span> at </span>
                    {post.time}
                  </p>
                </div>
                <p>{post.message}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </main>
  );
};

export default AdminDraftPosts;

import image from "../../../assets/img/resourceImg5.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TutorScheduled = ({ RequestedTut }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (RequestedTut === "") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [RequestedTut]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };

  const CARDS_PER_PAGE = 6;

  const [activePage, setActivePage] = useState(1);

  const totalPages = Math.ceil(RequestedTut.length / CARDS_PER_PAGE);

  const handleClick = (event) => {
    const targetPage = Number(event.target.text);
    setActivePage(targetPage);
  };
  const startIndex = (activePage - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;
  const visibleItems = RequestedTut.slice(startIndex, endIndex);

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === activePage} onClick={handleClick}>
        {i}
      </Pagination.Item>
    );
  }
  return (
    <section>
      {loading ? (
        <span className="spinner-border spinner-border-sm text-blue"></span>
      ) : (
        <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 g-4 mt-3">
          {visibleItems.map((video) => (
            <div className="col" key={video.id}>
              <a href={`${video.meeting_link}`}>
                <div
                  className="card resource-card border-0"
                  // onClick={() => navigate(`${video.meeting_link}`)}
                  // onClick={() =>
                  //   navigate(`/tutortutorials/livetutorial/${video.meeting_link}`)
                  // }
                >
                  <img src={image} className="card-img-top" alt="" />
                  <div className="card-img-overlay mt-5">
                    <h4 className="viga-24-400 text-white">{video.tt_title}</h4>
                    <h5 className="viga-16-400 my-course-notename">
                      {video.tt_course}
                    </h5>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{video.title}</h5>
                    <div className="footnote-grey">
                      <span className="lato-12-400">
                        {formatDateTime(video.tt_time)}
                      </span>
                      <span className="px-2 bi bi-dot"></span>
                      <span className="lato-12-400">{video.tt_type}</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
      <div className="card-pagination d-flex">
        <Pagination className="ms-auto me-4">{paginationItems}</Pagination>
      </div>
    </section>
  );
};

export default TutorScheduled;

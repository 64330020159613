import { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

const MakeWithdrawal = ({ show, handleClose }) => {
  const tutorId = sessionStorage.getItem("tutorId");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const debitAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`DebitAccount/${tutorId}/${amount}`);
      const resp = response.data.data;
      console.log(resp);
      if (resp.status === "success") {
        toast.success(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
      } else {
        toast.warning(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
      }
      handleClose();
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="d-block">
            <h5 className="lato-24-500 text-grey">Withdraw Funds</h5>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="lato-20-600 form-control"
              placeholder="Enter amount"
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="mx-3 blue-filled-btn">
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                <span onClick={debitAccount}>Make Withdrawal</span>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MakeWithdrawal;

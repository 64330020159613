import { useState } from "react";
import { NavLink } from "react-router-dom";

const MobileSideBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <section className="d-block d-md-block d-lg-none ms-3">
      {isOpen ? (
        <i className="bi bi-x fs-3" role="button" onClick={toggleSidebar}></i>
      ) : (
        <i
          className="bi bi-list fs-3"
          role="button"
          onClick={toggleSidebar}
        ></i>
      )}
      <div className={`sidebar-sm ${isOpen ? "open" : ""}`}>
        <nav className="navbar">
          <NavLink to="/profile" className="navactive py-2">
            <div className="nav-item d-flex poppins-14-500">
              <i className="bi bi-person nav-icon"></i>
              Profile
            </div>
          </NavLink>
          <NavLink to="/notifications" className="navactive py-2">
            <div className="nav-item d-flex poppins-14-500">
              <i className="bi bi-bell nav-icon"></i>
              Notification
            </div>
          </NavLink>
          <NavLink to="/help" className="navactive py-2">
            <div className="nav-item d-flex poppins-14-500">
              <i className="bi bi-question-circle nav-icon"></i>
              Help
            </div>
          </NavLink>
          <NavLink
            className="navactive py-2"
            data-bs-toggle="modal"
            data-bs-target="#logoutModal"
          >
            <div className="nav-item d-flex poppins-14-500">
              <i className="bi bi-box-arrow-right nav-icon"></i>
              Logout
            </div>
          </NavLink>
        </nav>
      </div>
    </section>
  );
};

export default MobileSideBar;

import { Link } from "react-router-dom";

const CGPAcomponent = () => {
  return (
    <div className="p-3">
      <div className="cgpa-preview">
        <h3>Calculate Your CGPA Easily!</h3>
        <p>Track your grades with ease using our CGPA calculator. Try it now</p>
        <Link to="/userdashboard/cgpacalc">
          <button className="blue-filled-btn">Calculate CGPA</button>
        </Link>
      </div>
    </div>
  );
};

export default CGPAcomponent;

import { useState } from "react";
import LogoutModal from "../../../../components/LogoutModal";
import MobileProfile from "../../../../components/MobileProfile";
import DashboardHeader from "../../DashboardHeader";
import DashboardSidebar from "../../DashboardSidebar";
import UserAddResource from "./UserAddResource";
import UserUploadTable from "./UserUploadTable";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";

const UploadedResources = () => {
  const navigate = useNavigate();
  const [uploadModal, setuploadModal] = useState(false);
  const openModal = () => setuploadModal(true);
  const closeModal = () => setuploadModal(false);

  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <MobileProfile />
      <div className="main-content p-1 p-md-3">
        <DashboardHeader />
        <div className="dahboard-title px-3">
          <h3 className="viga-24-400">Upload Study Resources </h3>
        </div>
        <div className="d-flex px-3">
          <button
            className="btn p-0 mb-1 text-grey lato-24-600"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
          <button
            className="blue-light-btn ms-auto align-items-center"
            onClick={openModal}
          >
            Upload File
          </button>
        </div>
        <UserUploadTable />
        <UserAddResource show={uploadModal} handleClose={closeModal} />
        <ToastContainer transition={Zoom}></ToastContainer>
      </div>
    </div>
  );
};

export default UploadedResources;

import whiteLogo from "../../../assets/img/whiteLogo.svg";
import blueLogo from "../../../assets/img/blueLogo.svg";
import { useState } from "react";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";

const UserVerify = () => {
  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const verifyUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const email = sessionStorage.getItem("userEmail");
    const type = "verify";

    const obj = { otp, email, type };
    try {
      const response = await axios.post("VerifyAccount", obj);
      let resp = response.data.data;
      if (resp.status === "success") {
        toast.success(resp.message, {
          theme: "colored",
          autoClose: 3000,
        });
        console.log(resp.message);
        sessionStorage.setItem("userID", resp.userid);
        navigate("/acctsetup");
      } else if (
        resp.message === "Your Email Address has been verified Already"
      ) {
        sessionStorage.setItem("userID", resp.userid);
        navigate("/acctsetup");
      } else {
        setIsLoading(false);
        toast.warning(resp.message, {
          theme: "colored",
          autoClose: 3000,
        });
        console.log(resp.message);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("error:" + err.message, {
        theme: "colored",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 create-left">
          <img src={whiteLogo} className="logo" alt="Logo" />
          <div className="px-4 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-white viga-24-400">ETC Vibes</h2>
              <p className="text-white w-75">
                providing you with everything required to make your striaght As!
                on a single platform..
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-white p-3 d-flex">
          <div className="container form-container-lg px-3 m-auto">
            <div className="d-flex justify-content-center d-flex d-md-none">
              <img src={blueLogo} className="logo " alt="Logo" />
            </div>
            <h3 className="viga-24-400 text-blue my-4">Verify Account</h3>
            <span className="poppins-16-400 text-grey">
              An OTP token has been sent to your email. <br /> Kindly check your
              spam if you can't find it in your inbox.
            </span>
            <form action="" className="login-form" onSubmit={verifyUser}>
              <div className="my-3 d-flex">
                <input
                  type="number"
                  name="otp"
                  className="ps-4"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </div>

              <div className="mt-4">
                <button type="submit" className="submit-btn">
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  ) : (
                    <span>Verify Account</span>
                  )}
                </button>
              </div>
              <ToastContainer transition={Zoom}></ToastContainer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVerify;

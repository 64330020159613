import DashboardSidebar from "../DashboardSidebar";
import DashboardHeader from "../DashboardHeader";
import { useNavigate } from "react-router-dom";
import MobileNav from "../../../components/MobileNav";
import LogoutModal from "../../../components/LogoutModal";
import { useState } from "react";
import CoinConversionModal from "./CoinConversionModal";
import { ToastContainer, Zoom } from "react-toastify";

const getCurrentDate = () => {
  const date = new Date();
  const options = { month: "long", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
const CoinPage = () => {
  const balance = sessionStorage.getItem("coin");
  const navigate = useNavigate();
  const currentDate = getCurrentDate();
  const [coinModal, setCoinModal] = useState(false);
  const closeModal = () => setCoinModal(false);
  const openModal = () => setCoinModal(true);
  const [amountInCoin, setAmountInCoin] = useState("");
  const [equivalentAmountInNaira, setEquivalentAmountInNaira] = useState("");
  const formattedWalletBalance = parseFloat(balance).toLocaleString("en-US");

  const handleConversion = (amount) => {
    const equivalentAmount = amount / 10; // Assuming 10 Coins = 1 Naira
    setEquivalentAmountInNaira(equivalentAmount);
  };
  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <div className="main-content p-1 p-md-3">
        <DashboardHeader />
        <div className="d-block px-3">
          <div className="title">
            <h2 className="viga-24-400 text-blue">Coin Wallet</h2>
          </div>
          <button
            className="btn p-0 mb-3 text-grey lato-24-600"
            onClick={() => {
              navigate("/userWallet");
            }}
          >
            Back
          </button>
        </div>
        <div className="container mb-5">
          <div className="d-block d-md-flex mb-4">
            <div className="coin-wallet-balance p-3 mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <p className="lato-32-700">Coin Balance</p>
                <span className="lato-16-600">{currentDate}</span>
              </div>
              <div className="d-flex balance-number">
                <i className="bi bi-coin text-gold pe-2"></i>
                <span>{formattedWalletBalance}</span>
              </div>
            </div>
            <div className="coin-wallet-rates ms-auto p-3 mb-3">
              <p className="lato-20-400">Convesrion Rates</p>
              <div className="d-block lato-16-600">
                <p>10 Coins = 1Naira </p>
                <p>100 Coins = 10Naira</p>
                <p>1000 Coins = 100Naira</p>
              </div>
            </div>
          </div>
          <div className="conversion-box">
            <div className="mb-3">
              <p className=" ps-3 lato-16-600">Coin</p>
              <input
                type="number"
                className="coin-wallet-input"
                placeholder="How much coin do you want to withdraw?"
                value={amountInCoin}
                onChange={(e) => {
                  const amount = e.target.value;
                  setAmountInCoin(amount);
                  handleConversion(amount);
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <i className="bi bi-arrow-down-up coin-conversion-arrow"></i>
            </div>
            <div className="mb-3">
              <p className="ps-3 lato-16-600">Naira (₦)</p>
              <input
                disabled
                type="number"
                className="coin-wallet-input"
                placeholder="Amount in Naira"
                value={equivalentAmountInNaira}
                onChange={(e) => setEquivalentAmountInNaira(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                className={`${
                  amountInCoin > balance ? "grayed-btn" : "blue-filled-btn"
                }`}
                disabled={amountInCoin > balance}
                onClick={openModal}
              >
                {amountInCoin > balance ? "Insufficient coins" : "Withdraw"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CoinConversionModal
        show={coinModal}
        handleClose={closeModal}
        amount={amountInCoin}
      />
      <MobileNav />
      <LogoutModal />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default CoinPage;

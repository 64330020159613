import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";

const AdminUploadTable = () => {
  const [tableData, setTableData] = useState([]);
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = tableData.slice(firstIndex, lastIndex);

  const navigate = useNavigate();
  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleRowClick = (id, type) => {
    navigate(`/adminresource/uploaddetails/${type}/${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("AdminViewUserContent");
        const resp = response.data.resources;
        if (resp === null || resp === "") {
          setTableData([]);
        } else {
          setTableData(resp);
        }
        console.log(resp);
        setLoading(false);
      } catch (error) {
        toast.error(error, {
          theme: "colored",
          autoClose: "2000",
        });
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <span
          className="spinner-border spinner-border-xl text-blue"
          role="status"
        ></span>
      ) : (
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  File Name
                </th>
                <th scope="col" className="py-3">
                  Course Title
                </th>
                <th scope="col" className="py-3">
                  Type
                </th>
                <th scope="col" className="py-3">
                  Status
                </th>
                <th scope="col" className="py-3">
                  Coins Earned
                </th>

                <th scope="col" className="py-3"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr
                  key={item.id}
                  className="table-row"
                  onClick={() => handleRowClick(item.id, item.resource_type)}
                >
                  <td>{item.resource_name}</td>
                  <td>{item.course_name}</td>
                  <td>{item.resource_type}</td>
                  <td>
                    {item.status === 1 ? (
                      <span className="text-success">Approved</span>
                    ) : item.status === 2 ? (
                      <div className="text-danger">Declined</div>
                    ) : (
                      <div className="text-blue">Under Review</div>
                    )}
                  </td>
                  <td>{item.coin}</td>
                  <td>
                    {item.status === 1 || item.status === 2 ? (
                      <span className="text-dark">verified</span>
                    ) : (
                      <span className="text-blue">verify</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(tableData.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className={`btn pagination-button mx-1 ${
                    currentPage === index + 1
                      ? "btn-primary active"
                      : "btn-secondary"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminUploadTable;

import { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import axios from "../../../api/axios";
import Select from "react-select";
import { toast } from "react-toastify";
import CashConversionAlert from "./CashConversionAlert";

const CoinConversionModal = ({ show, handleClose, amount }) => {
  const [options, setOptions] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState("etc");
  const userID = sessionStorage.getItem("userID");
  const [cashAlert, setCashAlert] = useState(false);

  const openCashAlert = () => setCashAlert(true);
  const closeCashAlert = () => setCashAlert(false);
  useEffect(() => {
    axios
      .get("GetBankCode")
      .then((response) => {
        const data = response.data.data.message;
        const bankOptions = data.map((bank) => ({
          value: bank.BankCode,
          label: bank.BankName,
        }));
        setOptions(bankOptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  function handleSelect(selectedOption) {
    setSelectedBank(selectedOption);
  }

  function handleAccountNumberChange(event) {
    const inputAccountNumber = event.target.value;

    if (inputAccountNumber.length >= 10) {
      const bankCode = selectedBank ? selectedBank.value : "";
      axios
        .get(`VerifyAccountNumber/${inputAccountNumber}/${bankCode}`)
        .then((response) => {
          const data = response.data.data.message;
          const verifiedAccountName = data.AccountName;

          setAccountName(verifiedAccountName);
          setAccountNameDisabled(true); // Disable manual input
        })
        .catch((error) => {
          console.error("Error verifying account number:", error);
          // Handle the error here
        });
    }

    setAccountNumber(inputAccountNumber);
  }

  function handleAccountTypeClick(accountType) {
    setSelectedAccountType(accountType);
    setAccountNumber("");
    setAccountName("");
    setSelectedBank(null);
  }
  const convertcoinsWallet = async () => {
    setIsLoading(true);
    const obj = { amount };
    try {
      const response = await axios.post(`convertcoinsWallet/${userID}`, obj);
      console.log(response.data);
      setIsLoading(false);
      handleClose();
      toast.success("Coin has been converted", {
        theme: "colored",
        autoClose: "2000",
      });
    } catch (error) {
      toast.error(error, {
        theme: "colored",
        autoClose: "2000",
      });
      setIsLoading(false);
    }
  };

  const convertcoinsCash = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const obj = {
      amount,
      account_name: accountName,
      account_number: accountNumber,
      bank_name: selectedBank.label,
    };
    console.log(obj);

    try {
      const response = await axios.post(`convertcoinsCash/${userID}`, obj);
      const resp = response.data.message;
      console.log(resp);
      toast.success("Coin converted", {
        theme: "colored",
        autoClose: "2000",
      });
      setIsLoading(false);
      openCashAlert();
      handleClose();
    } catch (error) {
      if (error.response && error.response.data) {
        // If the error response contains a message key, display it
        // if (error.response.data.message) {
        //   toast.error(error.response.data.message, {
        //     theme: "colored",
        //     autoClose: 3000,
        //   });
        // }
        // If the error response contains errors key, display the first error
        if (error.response.data.errors) {
          const firstErrorKey = Object.keys(error.response.data.errors)[0];
          const firstError = error.response.data.errors[firstErrorKey][0];
          toast.error(firstError, {
            theme: "colored",
            autoClose: 3000,
          });
        }
      } else {
        toast.error("An unexpected error occurred", {
          theme: "colored",
          autoClose: 2000,
        });
      }
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="container">
          <div className="text-center">
            <h4>Where do you want to send your money to?</h4>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button
              className={`coin-btn-${
                selectedAccountType === "etc" ? "active" : "inactive"
              }`}
              onClick={() => handleAccountTypeClick("etc")}
            >
              ETC Account
            </button>
            <button
              className={`coin-btn-${
                selectedAccountType === "bank" ? "active" : "inactive"
              } ms-3`}
              onClick={() => handleAccountTypeClick("bank")}
            >
              Bank Account
            </button>
          </div>
          {selectedAccountType === "bank" && (
            <form onSubmit={convertcoinsCash}>
              <div className="mb-3">
                <label className="lato-16-600 ps-3">Bank Name</label>
                {/* Use react-select component for better usability */}
                <Select
                  options={options}
                  value={selectedBank}
                  onChange={handleSelect}
                />
              </div>
              <div className="mb-3">
                <label className="lato-16-600 ps-3">Account Number</label>
                <input
                  type="number"
                  className="form-control"
                  value={accountNumber}
                  onChange={handleAccountNumberChange}
                />
              </div>
              <div className="mb-3">
                <label className="lato-16-600 ps-3">Account Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={accountName}
                  disabled={accountNameDisabled}
                />
              </div>
              <div className="mb-3 d-flex justify-content-center">
                <button type="submit" className="blue-filled-btn">
                  {isLoading ? (
                    <div className="spinner-border spinner-border-sm text-white"></div>
                  ) : (
                    <span>Send</span>
                  )}
                </button>
              </div>
            </form>
          )}
          {selectedAccountType === "etc" && (
            <div className="container">
              *The converted coin will be the added to your wallet balance.
              <div className=" justify-content-center d-flex">
                <button
                  className="blue-filled-btn"
                  onClick={convertcoinsWallet}
                >
                  {isLoading ? (
                    <div className="spinner-border spinner-border-sm text-white"></div>
                  ) : (
                    <span>Confirm</span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <CashConversionAlert show={cashAlert} handleClose={closeCashAlert} />
      </ModalBody>
    </Modal>
  );
};

export default CoinConversionModal;

import { useEffect, useState } from "react";
import MobileSideBar from "../../components/MobileSideBar";
import NotificationModal from "../../components/NotificationModal";
import ProfileModal from "../../components/ProfileModal";
import blueLogo from "../../assets/img/blueLogo.svg";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { profile } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";
const getCurrentDate = () => {
  const date = new Date();
  const options = { month: "long", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
const DashboardHeader = () => {
  const navigate = useNavigate();
  const currentDate = getCurrentDate();
  const dispatch = useDispatch();
  const userID = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`ShowProfile/${userID}`);
        const userdata = response.data.data.UserRecord;

        dispatch(profile(userdata));
        sessionStorage.setItem("username", userdata.FullName);
        sessionStorage.setItem("nickname", userdata.NickName);
        sessionStorage.setItem("userEmail", userdata.EmailAddress);
        sessionStorage.setItem("userPhone", userdata.PhoneNumber);
        sessionStorage.setItem("userPic", userdata.ProfilePicName);
        sessionStorage.setItem("userPicFile", userdata.ProfilePic);
        sessionStorage.setItem("userSchool", userdata.SchoolID);
        sessionStorage.setItem("userDepartment", userdata.DepartmentID);
        sessionStorage.setItem("userFaculty", userdata.FaultyID);
        sessionStorage.setItem("userRef", userdata.UserReference);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        setLoading(false);
      } finally {
        setLoading(false); // Set loading to false whether success or failure
      }
    };
    if (!userID) {
      navigate("/login");
    } else {
      fetchUserData();
    }
  }, [userID, navigate, dispatch]);

  // if (loading) {
  //   return (
  //     <div className="spinner-border spinner-border-lg my-4 mb-md-4"></div>
  //   );
  // }
  const username = sessionStorage.getItem("username");
  const nickname = sessionStorage.getItem("nickname");
  const useremail = sessionStorage.getItem("userEmail");
  const userPhone = sessionStorage.getItem("userPhone");
  const userPic = sessionStorage.getItem("userPic");
  return (
    <div className="container-fluid bg-white border-0 px-3 p-md-3 dashboard-header sticky-top my-4 mb-md-4">
      <div className="d-flex align-items-center">
        <div className="logo pe-2">
          <img src={blueLogo} className="d-block d-md-none" alt="" />
        </div>
        {loading ? (
          <div className="d-flex me-auto align-items-center">
            <h3 className="viga-24-400 user-welcome m-0">Welcome,</h3>
            <span className="spinner-border spinner-border-sm text-blue"></span>
          </div>
        ) : (
          <div className="d-block me-auto">
            <h3 className="viga-24-400 user-welcome m-0">
              Welcome, {nickname}
            </h3>
          </div>
        )}

        <div className="d-flex  align-items-center">
          {/* Notification */}
          <NotificationModal />

          <span className="user-name pe-2">{nickname}</span>
          {/* Profile Modal */}
          <ProfileModal
            useremail={useremail}
            username={username}
            userPhone={userPhone}
            nickname={nickname}
            userPic={userPic}
          />
        </div>
        {/* Mobile Nav toggle */}
        <MobileSideBar />
      </div>
      <div className="d-none d-md-flex">
        <span className="date ms-auto text-grey">{currentDate}</span>
      </div>
    </div>
  );
};

export default DashboardHeader;

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import StudentForm from "./StudentForm";
import TutorForm from "./TutorForm";
import blueLogo from "../../../assets/img/blueLogo.svg";
import GoogleSignin from "../GoogleSignin";

const AccountForm = ({ userType }) => {
  const [formType, setForm] = useState(<StudentForm />);
  const [isStudentForm, setIsStudentForm] = useState(true);

  useEffect(() => {
    if (userType === "student") {
      setForm(<StudentForm />);
      setIsStudentForm(true);
    } else if (userType === "tutor") {
      setForm(<TutorForm />);
      setIsStudentForm(false);
    }
  }, [userType]);
  const handleFormUser = () => {
    setForm(<StudentForm />);
    setIsStudentForm(true);
  };
  const handleFormTutor = () => {
    setForm(<TutorForm />);
    setIsStudentForm(false);
  };

  return (
    <div className="container form-container-lg p-0 p-md-4 m-auto">
      <div className="d-flex justify-content-center d-flex d-md-none">
        <img src={blueLogo} className="logo " alt="Logo" />
      </div>
      <h3 className="my-3 viga-24-400 text-blue create-header">
        Create Account
      </h3>
      <p className="poppins-16-400 text-grey">
        Unlock your education potentials! Create an account to access our study
        materials and tutorials.We can't wait to see you thrive
      </p>
      <div className="d-block d-md-flex align-items-center">
        <div className="account-btn-group d-flex my-3 m-auto m-md-0">
          <button
            className={
              isStudentForm ? "btn btn-blue px-4" : "btn btn-white px-4"
            }
            id="student-btn"
            type="button"
            onClick={handleFormUser}
          >
            As a Student
          </button>
          <button
            className={
              !isStudentForm ? "btn btn-blue px-4" : "btn btn-white px-4"
            }
            id="tutor-btn"
            type="button"
            onClick={handleFormTutor}
          >
            As a Tutor
          </button>
        </div>
        <div className="ms-auto d-none d-md-block">
          <GoogleSignin />
        </div>
      </div>

      {/* Forms */}
      {formType}

      <span>
        Already have an account?
        <Link to="/login" className="blue-span ps-1">
          Login
        </Link>
      </span>
    </div>
  );
};

export default AccountForm;

import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import CoinApprovalModal from "./CoinApprovalModal";

const CoinRequestTable = () => {
  const [tableData, setTableData] = useState([]);
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const adminID = sessionStorage.getItem("adminID");
  const [approveModal, setApproveModal] = useState(false);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = tableData.slice(firstIndex, lastIndex);
  const [selectedItem, setSelectedItem] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setApproveModal(true);
  };
  const closeModal = () => setApproveModal(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`AdminAllViewWithdrawals`);
        const resp = response.data.data;
        if (resp === null || resp === "") {
          setTableData([]);
        } else {
          setTableData(resp);
        }
        console.log(resp);
        setLoading(false);
      } catch (error) {
        toast.error(error, {
          theme: "colored",
          autoClose: "2000",
        });
        setLoading(false);
      }
    };
    fetchData();
  }, [adminID]);
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };

  return (
    <>
      {loading ? (
        <span
          className="spinner-border spinner-border-xl text-blue"
          role="status"
        ></span>
      ) : (
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  S/N
                </th>
                <th scope="col" className="py-3">
                  Name
                </th>
                <th scope="col" className="py-3">
                  Coin Balance
                </th>
                <th scope="col" className="py-3">
                  Conversion Request
                </th>
                <th scope="col" className="py-3">
                  Status
                </th>

                <th scope="col" className="py-3">
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr
                  key={item.id}
                  className="table-row"
                  onClick={() => openModal(item)}
                >
                  <td>{index + 1}</td>
                  <td>{item.account_name}</td>
                  <td>{item.coin_initial_bal}</td>
                  <td>{item.amount_converted}</td>
                  <td>
                    {item.status === 1 ? (
                      <span className="text-success">Approved</span>
                    ) : item.status === 2 ? (
                      <div className="text-danger">Declined</div>
                    ) : (
                      <div className="text-blue">Pending</div>
                    )}
                  </td>
                  <td>{formatDateTime(item.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(tableData.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className={`btn pagination-button mx-1 ${
                    currentPage === index + 1
                      ? "btn-primary active"
                      : "btn-secondary"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
          <CoinApprovalModal
            show={approveModal}
            handleClose={closeModal}
            item={selectedItem}
          />
          <ToastContainer transition={Zoom}></ToastContainer>
        </div>
      )}
    </>
  );
};

export default CoinRequestTable;

import React from "react";
import AdminSidebar from "../AdminSidebar";
import LogoutModal from "../../../components/LogoutModal";
import AdminHeader from "../AdminHeader";
import AdminMobileSidebar from "../AdminMobileSidebar";
import AdminPostContainer from "./AdminPostContainer";

const AdminPostPage = () => {
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="">
          <h2 className="viga-24-400 p-1 p-md-3">Posts</h2>
          <AdminPostContainer />
        </div>
      </div>
      <AdminMobileSidebar />
    </section>
  );
};

export default AdminPostPage;

import blueLogo from "../../assets/img/blueLogo.svg";
import NotificationModal from "../../components/NotificationModal";
import TutorMobileSidebar from "./TutorMobileSidebar";
import { useEffect } from "react";
import axios from "../../api/axios";
import TutorProfileModal from "../../components/TutorProfileModal";
import { useNavigate } from "react-router-dom";
const getCurrentDate = () => {
  const date = new Date();
  const options = { month: "long", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
const TutorHeader = () => {
  const tutorID = sessionStorage.getItem("tutorId");
  const navigate = useNavigate();
  const currentDate = getCurrentDate();
  useEffect(() => {
    const tutorID = sessionStorage.getItem("tutorId");
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`ShowTutorProfile/${tutorID}`);
        const userdata = response.data.data.UserRecord;
        sessionStorage.setItem("tutorname", userdata.FullName);
        sessionStorage.setItem("tutorNickname", userdata.NickName);
        sessionStorage.setItem("tutorEmail", userdata.EmailAddress);
        sessionStorage.setItem("tutorPhone", userdata.PhoneNumber);
        sessionStorage.setItem("tutorSchool", userdata.School);
        sessionStorage.setItem("tutorQualification", userdata.Qualification);
        sessionStorage.setItem("tutorFaculty", userdata.FaultyID.faculty_id);
        sessionStorage.setItem("tutorPic", userdata.profile_pic);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    if (tutorID === "" || tutorID === null) {
      navigate("/login");
    }
    fetchUserData();
  }, [tutorID, navigate]);
  const tutorname = sessionStorage.getItem("tutorname");
  const tutorNickname = sessionStorage.getItem("tutorNickname");
  const tutorEmail = sessionStorage.getItem("tutorEmail");
  const tutorPhone = sessionStorage.getItem("tutorPhone");
  const tutorPic = sessionStorage.getItem("tutorPic");
  return (
    <div className="container-fluid bg-white border-0 px-3 p-md-3 tutor-header sticky-top mb-0 mb-md-3">
      <div className="d-flex align-items-center py-3">
        <div className="logo d-block d-md-none pe-2">
          <img src={blueLogo} alt="Logo" />
        </div>
        <div className="d-block">
          <h3 className="viga-24-400 user-welcome m-0">Welcome, {tutorname}</h3>
        </div>
        {/* <div className="form ms-auto ms-2">
          <input
            type="text"
            className="form-control search-bar-grey "
            placeholder="Search"
          />
        </div> */}

        <div className="d-flex ms-auto align-items-center">
          {/* Notification */}
          <NotificationModal />

          <span className="user-name pe-2 text-nowrap">{tutorname}</span>
          {/* Profile Modal */}
          <TutorProfileModal
            tutorEmail={tutorEmail}
            tutorNickname={tutorNickname}
            tutorPhone={tutorPhone}
            tutorname={tutorname}
            tutorPic={tutorPic}
          />
        </div>
        {/* Mobile Nav toggle */}
        <TutorMobileSidebar />
      </div>
      <div className="d-none d-md-flex">
        <span className="date ms-auto text-grey">{currentDate}</span>
      </div>
    </div>
  );
};

export default TutorHeader;

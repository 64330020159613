import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../api/axios";

const AdminSignupForm = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [phone_number, setPhone] = useState("");
  const [c_password, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const validateForm = () => {
    if (fullname.trim() === "") {
      toast.warning("Please enter your full name", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (email.trim() === "") {
      toast.warning("Please enter your email address", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.trim() === "") {
      toast.warning("Please enter a password", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password !== c_password) {
      toast.warning("Passwords do not match", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.length < 8) {
      toast.warning("Password should be at least 8 characters long", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (
      !password.match(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      toast.warning(
        "Password should contain at least one letter, one number, and one special character",
        {
          theme: "colored",
          autoClose: 3000,
        }
      );
      return false;
    }

    return true;
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcPassword = () => {
    setShowcPassword(!showcPassword);
  };
  const handleSignup = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const role = "0";
      const regobj = {
        username,
        fullname,
        email,
        password,
        role,
        phone_number,
      };

      try {
        const response = await axios.post("AdminRegister", regobj);

        setIsLoading(false);
        const userdata = response.data.data;
        sessionStorage.setItem("adminID", userdata.AdminId);
        sessionStorage.setItem("adminName", userdata.fullname);
        navigate("/admin");
      } catch (error) {
        setIsLoading(false);
        toast.error("Signup failed, error:" + error.message, {
          theme: "colored",
          autoClose: 3000,
        });
      }
    }
  };
  return (
    <form className="login-form text-center mt-5 mb-3" onSubmit={handleSignup}>
      <div className="mb-4 d-flex ">
        <input
          type="text"
          className="login-input"
          placeholder="Username"
          value={username}
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="text"
          className="login-input"
          placeholder="Full Name"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="text"
          className="login-input"
          placeholder="Nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="email"
          className="login-input"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="number"
          className="login-input"
          placeholder="Phone Number"
          value={phone_number}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showPassword ? "text" : "password"}
          className="login-input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPassword}
        ></i>
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showcPassword ? "text" : "password"}
          className="login-input"
          placeholder="Confirm Password"
          value={c_password}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showcPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowcPassword}
        ></i>
      </div>
      <div className="mt-4">
        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span style={{ color: "white" }}>Create Account</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default AdminSignupForm;

import React, { useState, useRef, useEffect } from "react";
import axios from "../../../api/axios";
import botIcon from "../../../assets/aibot.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
const BotInterface = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const messagesContainerRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  // Load chat messages from sessionStorage when the component mounts
  useEffect(() => {
    const storedMessages = sessionStorage.getItem("chatMessages");
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, []);

  // Save chat messages to sessionStorage whenever they change
  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputValue.trim() === "") {
      return;
    }
    setIsSending(true);

    const userMessage = {
      role: "user",
      content: inputValue,
    };
    try {
      // Add user message to the chat history
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // Clear input field
      setInputValue("");
      const response = await axios.post(
        "Chat",
        { text: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const botMessage = {
        role: "chatbot",
        content: response.data.choices[0].message.content,
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSending(false);
    }
  };
  const handleCopy = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        console.log("Text copied to clipboard:", content);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  useEffect(() => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  }, [messages]);

  let userPic = sessionStorage.getItem("userPic");

  return (
    <div className="chat-interface">
      <div className="container chatbot-container shadow mt-3 py-3">
        <div className="chat-history" ref={messagesContainerRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-2 message ${
                message.role === "user" ? "user" : "chatbot-message"
              }`}
            >
              <div
                className={`mb-2  p-md-3 message-content ${
                  message.role === "chatbot" ? "chat-response" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <div className="message-icon pe-2">
                    {message.role === "user" ? (
                      <img src={userPic} className="profile-pic-sm" alt="" />
                    ) : (
                      <img src={botIcon} className="profile-pic-sm" alt="" />
                    )}
                  </div>
                  <div className="message-text">{message.content}</div>
                  {message.role === "chatbot" && (
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={() => handleCopy(message.content)}
                      className="ps-3"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center fixed-bottom inputbar-container py-3 py-md-4">
          <form className="chat-input d-flex" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Type a message..."
              value={inputValue}
              onChange={handleInputChange}
              className="bot-chatbar"
            />
            <button
              type="submit"
              className={`bi ${
                isSending ? "spinner-border spinner-border-sm" : "bi-send-fill"
              } btn ms-auto chatbot-send`}
              disabled={isSending}
              role="status"
            ></button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BotInterface;

import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="firstfoot">
          <h5>ETC Vibes</h5>
          <h6>
            Best rated all-in-one educational platform in Nigeria and raise
            academic performance across student populations.
          </h6>
          <div className="contact-icons d-flex">
            <a
              href="https://www.linkedin.com/company/etc-vibes/"
              className="text-decoration-none text-white fs-4"
            >
              <i className="bi bi-linkedin px-2"></i>
            </a>
            <a
              href="https://instagram.com/etc.vibes"
              className="text-decoration-none text-white fs-4"
            >
              <i className="bi bi-instagram px-2"></i>
            </a>
            <a
              href="https://chat.whatsapp.com/GOcl0qcR102FGcy9PmlvZZ"
              className="text-decoration-none text-white fs-4"
            >
              <i className="bi bi-whatsapp px-2"></i>
            </a>
            <a href="https://tiktok.com/@etc.vibes" className="text-white fs-4">
              <i className="bi bi-tiktok px-2"></i>
            </a>
          </div>
        </div>
        <div className="secondfoot">
          <h3>Join Our Newsletter</h3>
          <form action="">
            <div className="input-group my-3">
              <input
                type="email"
                name="email"
                id="email"
                className="form-control suscribe-input"
              />
              <button type="submit" className="input-group-text subscribe-btn">
                Suscribe
              </button>
            </div>
          </form>
          <p>
            * Will send you weekly updates for your better Academic Performance.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

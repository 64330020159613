import React from "react";
import { Link } from "react-router-dom";

const RevenueInfo = () => {
  return (
    <div className="my-4">
      <div className="d-flex justify-content-between mb-3">
        <h4 className="roboto-24-700">Total Earnings</h4>
        <p className="text-blue lato-24-400">₦90000000</p>
        <Link to="/admin/earnings">View Details</Link>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <h4 className="roboto-24-700">Total Payouts</h4>
        <p className="text-warning lato-24-400">₦90000000</p>
        <Link to="/admin/payouts">View Details</Link>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <h4 className="roboto-24-700">Total Subscriptions</h4>
        <p className="text-success lato-24-400">₦90000000</p>
        <Link to="/admin/subscriptions">View Details</Link>
      </div>
    </div>
  );
};

export default RevenueInfo;

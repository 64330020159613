import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import Vector from "../../../assets/Vector.png";

const UserAnnouncements = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [dataloader, setDataloader] = useState(true);
  useEffect(() => {
    const getAllPosts = async () => {
      try {
        const response = await axios.get("fetchPublishedAnnouncement");
        setAllPosts(response.data);
        setDataloader(false);
      } catch (error) {
        toast.error(error.message, {
          theme: "colored",
        });
      }
    };
    getAllPosts();
  }, []);
  return (
    <div className="posts-highlight-item">
      <div className="d-flex justify-content-between align-items-center px-3">
        <h2 className="viga-18-400">🔔Latest Announcement </h2>
        <img className="mb-2" src={Vector} alt="" />
      </div>
      <div className="posts-highlight-container">
        {dataloader ? (
          <div className="spinner-grow" role="status"></div>
        ) : (
          allPosts &&
          allPosts.map((post, index) => (
            <div className="p-3 each-announcement p-md-3 my-3" key={index}>
              <div className="d-flex align-items-center ">
                <p className="text-blue viga-16-400">{post.title}</p>
                <p className="text-secondary ms-4 lato-14-400">
                  <i className="bi bi-calendar-check pe-2"></i>
                  {post.date}
                  <span> at </span>
                  {post.time}
                </p>
              </div>
              <p className="lato-14-400 annoncement-message">{post.message}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserAnnouncements;

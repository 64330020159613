import React, { useEffect } from "react";
import TutorSidebar from "../TutorSidebar";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import { useState } from "react";
import notificationIcon from "../../../assets/img/notificationIcon.svg";
import MobileNav from "../../../components/MobileNav";
import axios from "../../../api/axios";
import { JellyTriangle } from "@uiball/loaders";

const TutorNotifications = () => {
  const [recentBtn, setRecentBtn] = useState("notification-active");
  const [allBtn, setAllBtn] = useState("notification-inactive");
  const tutorId = sessionStorage.getItem("tutorId");
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);
  useEffect(() => {
    setLoading(true);
    const fetchNoty = async () => {
      const response = await axios(`tutorNotification/${tutorId}`);
      const notification = response.data.data.message;
      if (
        notification === "You do not have any notification yet" ||
        notification === ""
      ) {
        setNotifications([]);
      } else {
        setNotifications(notification);
      }
      setLoading(false);
    };
    fetchNoty();
  }, [tutorId]);

  const handleRecentNot = () => {
    setRecentBtn("notification-active");
    setAllBtn("notification-inactive");
  };
  const handleAllNot = () => {
    setRecentBtn("notification-inactive");
    setAllBtn("notification-active");
  };

  // Function to get the current notifications to display based on the current page and notifications per page.
  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  return (
    <div className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 tutorDashboard">
        <TutorHeader />
        <section className="container-fluid px-3">
          <div className="notification-header">
            <h4 className="viga-20-400">Manage Notifications</h4>

            <div className="d-flex">
              <h5 className={recentBtn} role="button" onClick={handleRecentNot}>
                Recent Notification
              </h5>
              <h5 className={allBtn} role="button" onClick={handleAllNot}>
                All Notifications
              </h5>
            </div>
            <div className="notification-container">
              {loading ? (
                <div className="container-fluid d-flex justify-content-center align-items-center py-5">
                  <div className="container d-flex justify-content-center">
                    <JellyTriangle size={80} speed={1.75} color="#044aac" />
                  </div>
                </div>
              ) : (
                <div className="d-block px-0 py-4">
                  {currentNotifications.map((notification) => (
                    <div
                      className="d-flex mb-3 notification-cell"
                      key={notification.id}
                    >
                      <div className="notification-icon pe-4">
                        <img src={notificationIcon} alt="" />
                      </div>
                      <div className="notification-body">
                        <div className="d-block d-md-flex align-items-center">
                          <h5 className="viga-20-400">
                            {notification.subject}
                          </h5>
                          <h5 className="lato-20-400 text-grey ps-0 ps-md-4">
                            {notification.comment}
                          </h5>
                        </div>
                        <p className="lato-20-400">{notification.decription}</p>
                        <div className="notification-date text-grey mb-2">
                          <i className="bi bi-calendar pe-2"></i>
                          {formatDate(notification.created_at)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {allBtn === "notification-active" && (
              <ul className="pagination">
                {Array.from(
                  {
                    length: Math.ceil(
                      notifications.length / notificationsPerPage
                    ),
                  },
                  (_, i) => (
                    <li
                      key={i}
                      className={`page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => paginate(i + 1)}
                        className="page-link"
                      >
                        {i + 1}
                      </button>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
          <MobileNav />
        </section>
      </div>
    </div>
  );
};

export default TutorNotifications;

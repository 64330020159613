import React from "react";

const BuyCoinsModal = () => {
  return (
    <div
      className="modal fade"
      id="buyCoinsModal"
      aria-labelledby="buyCoinsModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h5 className="modal-title" id="buyCoinsModalLabel">
              Buy More coins
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="coins-menu">
                <div className="d-flex align-items-center mb-2">
                  <h6 className="poppins-20-400 m-0">
                    200 Coins <span className=" ps-2 text-green">₦500</span>
                  </h6>
                  <button className="buy-btn px-3 py-1 ms-auto">Buy</button>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <h6 className="poppins-20-400 m-0">
                    500 Coins <span className="ps-2 text-green">₦1000</span>
                  </h6>
                  <button className="buy-btn px-3 py-1 ms-auto">Buy</button>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <h6 className="poppins-20-400 m-0">
                    1000 Coins <span className="ps-2 text-green">₦1500</span>
                  </h6>
                  <button className="buy-btn px-3 py-1 ms-auto">Buy</button>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <h6 className="poppins-20-400 m-0">
                    1500 Coins <span className="ps-2 text-green">₦2500</span>
                  </h6>
                  <button className="buy-btn px-3 py-1 ms-auto">Buy</button>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <h6 className="poppins-20-400 m-0">
                    2000 Coins <span className="ps-2 text-green">₦3000</span>
                  </h6>
                  <button className="buy-btn px-3 py-1 ms-auto">Buy</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCoinsModal;

import { useEffect, useState } from "react";
import bookIcon from "../../../../assets/img/textbook.jpg";
import { Link } from "react-router-dom";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const Textbooks = ({ courseId }) => {
  const [textbooks, setTextbooks] = useState([]);
  const DepartmentId = sessionStorage.getItem("userDepartment");
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState({});
  const userId = sessionStorage.getItem("userID");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`loadtextbooks/${courseId}`);
        const resp = response.data.data;
        console.log(resp.message);

        if (
          resp.message === "" ||
          resp.message === "No textbook was Found" ||
          resp.message === "No record Found" ||
          resp.message === "No record found"
        ) {
          setTextbooks([]);
        } else {
          setTextbooks(resp.message);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [DepartmentId, courseId]);
  // Function to handle rating changes
  const handleRatingChange = (Textbookid, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [Textbookid]: rating,
    }));

    // Send rating to endpoint
    sendRatingToEndpoint(Textbookid, rating);
  };
  // Function to send rating to an endpoint
  const sendRatingToEndpoint = async (Textbookid, rating) => {
    const obj = {
      user_id: userId,
      resource_type: "textbook",
      resource_id: parseInt(Textbookid),
      rating,
    };
    try {
      const response = await axios.post(`reviews/${userId}`, obj);
      const resp = response.data;
      if (resp.message === "Review added successfully") {
        toast.success("Review added", {
          theme: "colored",
          autoClose: "2000",
        });
      } else {
        toast.warning("Error !", {
          theme: "colored",
          autoClose: "2000",
        });
      }
    } catch (error) {
      // Handle error
      console.error("Error sending rating:", error);
    }
  };
  return (
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : (
        <>
          {textbooks.length === 0 ? (
            <div className="text-center">
              <p>No resource available</p>
            </div>
          ) : (
            <>
              {textbooks.map(
                (textbook) =>
                  textbooks !== null && (
                    <div className="col" key={textbook.Textbookid}>
                      <div className="card resource-card border-0">
                        <Link
                          to={`/courses/coursepage/resourcepage/document/${encodeURIComponent(
                            textbook.TextbookLink
                          )}`}
                          className="text-decoration-none text-dark"
                          onClick={() => {
                            sessionStorage.setItem(
                              "activeResourceID",
                              textbook.Textbookid
                            );
                            sessionStorage.setItem(
                              "activeResourceType",
                              "textbook"
                            );
                          }}
                        >
                          <img src={bookIcon} className="card-img-top" alt="" />
                        </Link>

                        <div className="card-body">
                          {" "}
                          <div className=" me-auto rating-overlay">
                            <span className="text-white">
                              {" "}
                              Rated {textbook.rating}
                            </span>
                            <span className="text-gold fs-5">★</span>
                          </div>
                          <div className=" d-flex align-items-center">
                            <h6 className="card-title">
                              {textbook.TextbookName}
                            </h6>
                            <i className="bi bi-eye pe-2 ms-auto"></i>
                            {textbook.views}
                          </div>
                          <div className="footnote-grey">
                            <div className="d-flex">
                              <div>
                                <span className="viga-14-400">
                                  Author:{" "}
                                  {textbook.TexttbookAutor == null
                                    ? "ETC Vibes"
                                    : textbook.TexttbookAutor}
                                </span>
                                <div className="viga-14-400">
                                  From: {textbook.institution}
                                </div>
                              </div>
                              <div className="ms-auto ratings">
                                {/* 5-star rating input */}
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    onClick={() =>
                                      handleRatingChange(
                                        textbook.Textbookid,
                                        star
                                      )
                                    }
                                    onMouseEnter={() =>
                                      setRatings({
                                        ...ratings,
                                        hoverRating: star,
                                      })
                                    }
                                    onMouseLeave={() =>
                                      setRatings({
                                        ...ratings,
                                        hoverRating: null,
                                      })
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color:
                                        star <=
                                        (ratings.hoverRating ||
                                          ratings[textbook.Textbookid] ||
                                          0)
                                          ? "gold"
                                          : "gray",
                                      transition: "color 0.2s ease-in-out", // Animation duration
                                    }}
                                  >
                                    ★
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Textbooks;

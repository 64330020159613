import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import LogoutModal from "../../../components/LogoutModal";
import AdminHeader from "../AdminHeader";
import PayoutsCard from "./PayoutsCard";
import { useNavigate } from "react-router-dom";
import PayoutsTable from "./PayoutsTable";
import axios from "../../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";

const PayoutsPage = () => {
  const navigate = useNavigate();
  const [payoutDetail, setpayoutDetail] = useState([]);
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("PaymentRequest");
        const resp = response.data.data.message;
        setpayoutDetail(resp);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid ">
          <button
            className="viga-20-400 text-grey back-btn mb-4"
            onClick={goBack}
          >
            Back
          </button>
          <h5 className="text-blue lato-20-700 ps-2">
            Tutors Payouts Overview
          </h5>
          <PayoutsCard />
          <PayoutsTable payoutDetail={payoutDetail} />
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default PayoutsPage;

import { useState } from "react";
import icon1 from "../../assets/Group 35538.png";
import icon2 from "../../assets/Group 35539.png";

const Newfaq = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (faq) => {
    setActive((prev) => (prev === faq ? null : faq));
  };

  return (
    <div id="faq">
      <div className="FaQ">
        <h3>Frequently Asked Questions (FAQ) - ETC Vibes</h3>
        <div className="faqcard">
          <div className="faqhead" onClick={() => handleToggle("quest1")}>
            <h5>How does ETC Vibes work?</h5>
            <img src={active === "quest1" ? icon1 : icon2} alt="" />
          </div>
          {active === "quest1" && (
            <h6>
              Vibes offers a range of educational resources, a virtual community
              for support, and virtual tutoring services. Students can access
              materials, connect with peers, and receive personalized assistance
              to enhance their learning experience.
            </h6>
          )}
        </div>
        <div className="faqcard">
          <div className="faqhead" onClick={() => handleToggle("quest2")}>
            <h5>Can I become a ETC Vibes Tutor?</h5>
            <img src={active === "quest2" ? icon1 : icon2} alt="" />
          </div>
          {active === "quest2" && (
            <h6>
              Definitely, everyone has the opportunity to become a tutor after
              passing a verification process.
            </h6>
          )}
        </div>
        <div className="faqcard">
          <div className="faqhead" onClick={() => handleToggle("quest3")}>
            <h5>What kind of educational materials does ETC Vibes offer?</h5>
            <img src={active === "quest3" ? icon1 : icon2} alt="" />
          </div>
          {active === "quest3" && (
            <h6>
              Notes, Past questions & Solution, Flashcards, Quizzes, AI and many
              more.
            </h6>
          )}
        </div>
        <div className="faqcard">
          <div className="faqhead" onClick={() => handleToggle("quest4")}>
            <h5>How can I connect with other learners on ETC Vibes</h5>
            <img src={active === "quest4" ? icon1 : icon2} alt="" />
          </div>
          {active === "quest4" && <h6>Coming soon</h6>}
        </div>
      </div>
    </div>
  );
};

export default Newfaq;

import React from "react";
import AdminSidebar from "../AdminSidebar";
import LogoutModal from "../../../components/LogoutModal";
import AdminHeader from "../AdminHeader";
import { useNavigate } from "react-router-dom";
import SubscriptionsCard from "./SubscriptionsCard";

const SubscriptionsDetails = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid ">
          <button
            className="viga-20-400 text-grey back-btn mb-4"
            onClick={goBack}
          >
            Back
          </button>
          <h5 className="text-blue lato-20-700 ps-2">
            Subscription Overview
          </h5>
          <SubscriptionsCard/>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionsDetails;

import React from "react";
import AccountForm from "./AccountForm";
import whiteLogo from "../../../assets/img/whiteLogo.svg";
import { useParams } from "react-router-dom";
const CreateAccount = () => {
  const { userType } = useParams();
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 create-left">
          <img src={whiteLogo} className="logo" alt="Logo" />
          <div className="px-4 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-white viga-24-400">ETC Vibes</h2>
              <p className="text-white w-75">
                providing you with everything required to make your striaght As!
                on a single platform..
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-white p-3 d-flex">
          <AccountForm userType={userType} />
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

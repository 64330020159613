import TutorSidebar from "../TutorSidebar";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import WalletCards from "./WalletCards";
import MakeWithdrawal from "./MakeWithdrawal";
import WalletActivities from "./WalletActivities";
import MobileTutorNav from "../../../components/MobileTutorNav";
import { useState } from "react";
import { ToastContainer, Zoom } from "react-toastify";
const TutorWallet = () => {
  const [WithdrawModal, setWithdrawModal] = useState(false);
  const closeWithdrawModal = () => setWithdrawModal(false);
  return (
    <section className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 tutorTutorial">
        <TutorHeader />
        <div className="d-flex mb-3 px-3">
          <button
            className="ms-auto blue-filled-btn"
            onClick={() => setWithdrawModal(true)}
          >
            Make Withdrawal
          </button>
          <MakeWithdrawal
            show={WithdrawModal}
            handleClose={closeWithdrawModal}
          />
        </div>
        <div className="p-3">
          <WalletCards />
          <WalletActivities />
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <MobileTutorNav />
    </section>
  );
};

export default TutorWallet;

import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import WalletComponent from "./WalletComponent";
import MobileNav from "../../../components/MobileNav";
import { useEffect } from "react";
import PlanRenewalNotice from "../../../components/PlanRenewalNotice";
import { useState } from "react";
const UserWallet = () => {
  const [showPlanRenewalNotice, setShowPlanRenewalNotice] = useState(false);
  useEffect(() => {
    // Open the PlanRenewalNotice modal when this component loads
    setShowPlanRenewalNotice(false);

    // You can also set a timeout to automatically close the modal after a certain duration
    const timeoutId = setTimeout(() => {
      // setShowPlanRenewalNotice(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    };
  }, []);
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3">
        <DashboardHeader />
        <PlanRenewalNotice
          show={showPlanRenewalNotice}
          handleClose={() => setShowPlanRenewalNotice(false)}
        />
        <WalletComponent />
        <MobileNav />
      </div>
    </div>
  );
};

export default UserWallet;

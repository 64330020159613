import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

const AddDepartments = ({ show, handleClose }) => {
  const [department, setDepartment] = useState("");
  const [university, setUniversity] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [faculty, setFaculty] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [isloading, setisLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uniData = await axios.get("University");
        const facultyData = await axios.get("Faculty");
        const uni = uniData.data.data.message;
        const fac = facultyData.data.data.message;
        setUniversity(uni);
        setFaculty(fac);
      } catch (error) {}
    };
    fetchData();
  }, []);
  const submitForm = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const obj = {
      DepartmentName: department,
      UniversityId: selectedUniversity,
      facultyID: selectedFaculty,
      DepartmentCode: "0000",
    };
    try {
      const response = await axios.post("AddDepartment", obj);
      const resp = response.data.data;
      toast(resp.message, {
        autoClose: 3000,
        theme: "colored",
      });
      handleClose();
      setisLoading(false);
    } catch (error) {
      toast.error(error);
      setisLoading(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        <h3 className="fw-bold text-blue">Add Department</h3>
      </ModalHeader>
      <ModalBody>
        <form className="container" onSubmit={submitForm}>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              University
            </label>
            <select
              className="resource-input"
              onChange={(e) => setSelectedUniversity(e.target.value)}
              value={selectedUniversity}
            >
              <option value="">Select University</option>
              {university.map((uni) => (
                <option key={uni.id} value={uni.id}>
                  {uni.sc_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Faculty
            </label>
            <select
              className="resource-input"
              onChange={(e) => setSelectedFaculty(e.target.value)}
              value={selectedFaculty}
            >
              <option value="">Select Faculty</option>
              {faculty.map((fac) => (
                <option key={fac.id} value={fac.id}>
                  {fac.faculty_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Department
            </label>
            <input
              type="text"
              onChange={(e) => setDepartment(e.target.value)}
              className="resource-input"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button className="blue-filled-btn text-center" type="submit">
              {isloading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span> Add</span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddDepartments;

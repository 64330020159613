import { useCallback, useState } from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import { ToastContainer, Zoom, toast } from "react-toastify";
import Download from "../../../assets/img/Download.svg";
import PaystackPayment from "./PaystackPayment";

const AddMoneyButton = ({ reloadWalletData }) => {
  const [modalShow, setModalShow] = useState(false);
  const [amount, setAmount] = useState("");
  const payerEmail = sessionStorage.getItem("userEmail");

  const handlePay = useCallback(
    (e) => {
      e.preventDefault();
      if (!isNaN(parseFloat(amount))) {
        setModalShow(false);
      } else {
        toast.error("Please enter a valid amount.", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    },
    [amount]
  );

  return (
    <div>
      <div className="wallet-op wallet-op-1" onClick={() => setModalShow(true)}>
        <img src={Download} alt="" className="m-auto" />
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        className="p-5"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <h1>Enter an Amount</h1>
        </ModalHeader>
        <form onSubmit={handlePay} className="p-3">
          <div className="d-flex align-items-center">
            <label htmlFor="amount" className="pe-3">
              ₦
            </label>
            <input
              type="number"
              id="amount"
              className="form-control"
              name="amount"
              step="0.01"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <div className="d-flex my-3 ">
            <button
              className="blue-outline-btn m-auto"
              onClick={() => setModalShow(false)}
            >
              Close
            </button>
            <PaystackPayment
              amount={parseFloat(amount) * 100}
              payerEmail={payerEmail}
              reloadWalletData={reloadWalletData}
            />
          </div>
        </form>
      </Modal>
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default AddMoneyButton;

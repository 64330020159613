import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../../../api/axios";
import pdf from "../../../assets/ETC Privacy.pdf";
const TutorForm = () => {
  const [FullName, setFullname] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [c_password, setCPassword] = useState("");
  const [School, setSchool] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [University, setUniversity] = useState([]);

  useEffect(() => {
    const getUniversity = async () => {
      try {
        const response = await axios.get(`schools`);
        const University = response.data.data;
        setUniversity(University);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    getUniversity();
  }, []);
  const validateForm = () => {
    if (FullName.trim() === "") {
      toast.warning("Please enter your full name", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (Email.trim() === "") {
      toast.warning("Please enter your email address", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }
    if (School.trim() === "") {
      toast.warning("Please pick a school", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (Password.trim() === "") {
      toast.warning("Please enter a password", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }
    if (Password !== c_password) {
      toast.warning("Passwords do not match", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (Password.length < 8) {
      toast.warning("Password should be at least 8 characters long", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (!Password.match(/^(?=.*\d)(?=.*[@$!%*?&])/)) {
      toast.warning(
        "Password should contain at least one letter, one number, and one special character",
        {
          theme: "colored",
          autoClose: 3000,
        }
      );
      return false;
    }

    return true;
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcPassword = () => {
    setShowcPassword(!showcPassword);
  };
  const handlecheckbox = (e) => {
    setIschecked(!isChecked);
  };
  const handleOptionChange = (e) => {
    setSchool(e.target.value);
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      // const roleNumber = 1;
      let regobj = { FullName, Email, Password, School };

      try {
        const response = await axios.post("CreateTutor", regobj);

        setIsLoading(false);
        const userdata = response.data.data;
        console.log(userdata);
        console.log(userdata.TutorId);
        sessionStorage.setItem("tutorId", userdata.TutorId);
        sessionStorage.setItem("tutorEmail", Email);
        navigate("/tutorverify");
      } catch (error) {
        setIsLoading(false);
        toast.error("Signup failed, error:" + error.message, {
          theme: "colored",
          autoClose: 3000,
        });
      }
    }
  };
  return (
    <form
      action=""
      className="login-form text-center mt-5 mb-3"
      onSubmit={handleSignup}
    >
      <div className="mb-4 d-flex ">
        <input
          type="text"
          className="login-input"
          placeholder="Full Name"
          value={FullName}
          onChange={(e) => setFullname(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="email"
          className="login-input"
          placeholder="Email Address"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="mb-4 d-flex">
        <input
          type={showPassword ? "text" : "password"}
          className="login-input"
          placeholder="Password"
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPassword}
        ></i>
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showcPassword ? "text" : "password"}
          className="login-input"
          placeholder="Confirm Password"
          value={c_password}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showcPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowcPassword}
        ></i>
      </div>
      <div className="mb-4 d-flex">
        <select
          value={School}
          onChange={handleOptionChange}
          className="form-select text-grey p-0"
        >
          <option value="">University</option>
          {University.map((university) => (
            <option value={university.id} key={university.id}>
              {university.schoolName}
            </option>
          ))}
        </select>
      </div>

      <div className="">
        <input type="checkbox" checked={isChecked} onChange={handlecheckbox} />
        <span>
          {" "}
          By signing up, I accept ETC Vibes'{" "}
          <a href={pdf} target="blank">
            Privacy Policy
          </a>
        </span>
      </div>
      <div className="mt-4">
        <button
          type="submit"
          className={`${isChecked ? "submit-btn" : "checked-btn"}`}
          disabled={isLoading || !isChecked}
        >
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span style={{ color: "white" }}>Create Account</span>
          )}
        </button>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </form>
  );
};

export default TutorForm;

import jwtDecode from "jwt-decode";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

import { useState } from "react";
const GoogleSignin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCredentialResponse = async (response) => {
    setLoading(true);
    // console.log("Encoded JWT ID token: " + response.credential);
    let userObject = jwtDecode(response.credential);
    const fullname = userObject.family_name + " " + userObject.given_name;
    const email = userObject.email;
    const password = "DummyPassword123";
    const regobj = { fullname, email, password };

    try {
      const response = await axios.post("CreateUsers", regobj);

      const userdata = response.data.data;
      console.log(userdata);
      console.log(userdata.UserId);
      sessionStorage.setItem("userID", userdata.UserId);
      sessionStorage.setItem("userEmail", userdata.Email);
      setLoading(false);
      navigate("/userverify");
    } catch (error) {
      toast.error("Signup failed, error:" + error.message, {
        theme: "colored",
        autoClose: 3000,
      });
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </span>
      ) : (
        <GoogleLogin
          onSuccess={handleCredentialResponse}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      )}
    </>
  );
};

export default GoogleSignin;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Jan",
    earnings: 4000,
    payouts: 2400,
    subscriptions: 2000,
  },
  {
    name: "Feb",
    earnings: 3000,
    payouts: 1398,
    subscriptions: 2110,
  },
  {
    name: "Mar",
    earnings: 2000,
    payouts: 9800,
    subscriptions: 2290,
  },
  {
    name: "Apr",
    earnings: 2780,
    payouts: 3908,
    subscriptions: 2400,
  },
  {
    name: "May",
    earnings: 6000,
    payouts: 4800,
    subscriptions: 2681,
  },
  {
    name: "June",
    earnings: 2390,
    payouts: 3800,
    subscriptions: 2500,
  },
  {
    name: "July",
    earnings: 3490,
    payouts: 4300,
    subscriptions: 2200,
  },
];

const RevenueChart = () => {
  return (
    <LineChart
      width={800}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="payouts"
        stroke="#FBD033"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="earnings" stroke="#044AAC" />
      <Line type="monotoneX" dataKey="subscriptions" stroke="#42B549" />
    </LineChart>
  );
};

export default RevenueChart;

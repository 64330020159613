import { Link } from "react-router-dom";
import planImg from "../../../assets/img/planImg.jpg";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import PlanPayment from "./PlanPayment";
import axios from "../../../api/axios";
import { useState } from "react";
import { toast } from "react-toastify";
import FundWalletModal from "../Wallet/FundWalletModal";

const SubConfirmationModal = ({ show, handleClose, price, planName }) => {
  const payerEmail = sessionStorage.getItem("userEmail");
  const userId = sessionStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [showFundModal, setShowFundModal] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const debitWallet = () => {
    setIsLoading(true);
    const obj = {
      plan: planName,
      userid: userId,
      amount: `${price}`,
    };
    axios
      .post("SubcribeFromWallet", obj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp);
          sessionStorage.setItem("userPlan", "Plan");
          console.log(sessionStorage.getItem("userPlan"));
          toast.success(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
        } else {
          toast.warning(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setIsPaymentSuccessful(true);
          setShowFundModal(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          theme: "colored",
          autoClose: 3000,
        });
      });
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader>
        <div className="d-block">
          <img src={planImg} alt="" />
        </div>
        <div className="d-block ps-2">
          <h3 className="lato-20-500 text-blue">{planName} Plan</h3>
        </div>
        <div className="ms-auto">
          <Link>
            {/* <button className="btn btn-outline-danger">Change</button> */}
          </Link>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <h5 className="text-blue lato-16-500">Total</h5>
          <h5 className="text-blue ms-auto lato-16-500">₦{price}</h5>
        </div>
        <div className="d-flex justify-content-center">
          <PlanPayment
            amount={price * 100}
            handleClose={handleClose}
            payerEmail={payerEmail}
            planName={planName}
          />
          <button
            className="blue-filled-btn m-auto"
            onClick={() => debitWallet()}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
              ></span>
            ) : (
              <span> Pay with Wallet</span>
            )}
          </button>
        </div>
        {isPaymentSuccessful && (
          <FundWalletModal
            show={showFundModal}
            handleClose={() => setShowFundModal(false)}
            closeSubConfrim={handleClose}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default SubConfirmationModal;

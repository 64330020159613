import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const AddUniModal = ({ show, handleClose }) => {
  const [university, setUniversity] = useState("");
  const [isloading, setisLoading] = useState(false);
  const submitForm = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const obj = {
      SchoolName: university,
    };
    try {
      const response = await axios.post("AddUniversity", obj);
      const resp = response.data.data;
      toast(resp.message, {
        autoClose: 3000,
        theme: "colored",
      });
      handleClose();
      setisLoading(false);
    } catch (error) {
      toast.error(error);
      setisLoading(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        <h3 className="fw-bold text-blue">Add University</h3>
      </ModalHeader>
      <ModalBody>
        <form className="container" onSubmit={submitForm}>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              University
            </label>
            <input
              type="text"
              onChange={(e) => setUniversity(e.target.value)}
              className="resource-input"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button className="blue-filled-btn text-center" type="submit">
              {isloading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span> Add University</span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddUniModal;

import "./userDashboard.css";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import MobileNav from "../../components/MobileNav";
import CalendarComponent from "./Dashboard/CalendarComponent";
import MyCourses from "./Dashboard/MyCourses";
// import RecentActivities from "./Dashboard/RecentActivities";
import LogoutModal from "../../components/LogoutModal";
import MobileProfile from "../../components/MobileProfile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { ToastContainer, Zoom } from "react-toastify";
import { useDispatch } from "react-redux";
import { dashboard } from "../../features/userSlice";
import CGPAcomponent from "./Dashboard/CGPAcomponent";
import UserAnnouncements from "./Dashboard/UserAnnouncements";
const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userID = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userID === "" || userID === null) {
      navigate("/login");
    }
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`UserDashboard/${userID}`);
        const userdata = response.data.data.UserRecord;
        // Dispatch the dashboard action with userdata
        dispatch(dashboard(userdata));

        sessionStorage.setItem("PassQuestion", userdata.PassQuestion);
        sessionStorage.setItem("Note", userdata.Note);
        sessionStorage.setItem("Textbook", userdata.Textbook);
        sessionStorage.setItem("Resources", userdata.Resources);
        sessionStorage.setItem("Courses", userdata.Courses);
        sessionStorage.setItem("tutorialNumber", userdata.tutorialNumber);
        sessionStorage.setItem(
          "userPlan",
          userdata.UserProfile.SubscriptionPlan
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userID, navigate, dispatch]);

  const UserPastQuestions = sessionStorage.getItem("PassQuestion");
  const UserNote = sessionStorage.getItem("Note");
  const UserTextbook = sessionStorage.getItem("Textbook");
  const UserCourses = sessionStorage.getItem("Courses");
  const UserResources = sessionStorage.getItem("Resources");
  const UserTutorial = sessionStorage.getItem("tutorialNumber");
  
  return (
    <div className="container-fluid p-0">
      {loading ? (
        <div className="preloader-container text-center d-flex justify-content-center">
          <div className="spinner-grow text-blue  my-auto " role="status"></div>
        </div>
      ) : (
        <>
          <DashboardSidebar />
          <LogoutModal />
          <MobileProfile />
          <div className="main-content p-1 p-md-3 userDashboard">
            <DashboardHeader />
            <div className="dahboard-title px-3">
              <h3 className="viga-24-400">Dashboard</h3>
            </div>
            <MyCourses
              UserPastQuestions={UserPastQuestions}
              UserNote={UserNote}
              UserTextbook={UserTextbook}
              UserCourses={UserCourses}
              UserResources={UserResources}
              UserTutorial={UserTutorial}
            />
            <UserAnnouncements />
            <div className="container-fluid p-0 d-block d-md-flex mt-0 mt-md-5 justify-content-between">
              <div className="left-main">
                {/* <RecentActivities /> */}
                <CGPAcomponent />
              </div>
              <div className="right-main bg-white">
                <CalendarComponent />
              </div>
            </div>
          </div>
        </>
      )}
      <MobileNav />
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default UserDashboard;

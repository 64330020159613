import React from "react";
import CourseContainer from "./CourseContainer";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";

const Courses = () => {
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3">
        <DashboardHeader />
        <CourseContainer />
        <MobileNav />
      </div>
    </div>
  );
};

export default Courses;

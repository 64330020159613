import { useEffect, useState } from "react";
import SubscriptionModal from "./SubscriptionModal";
import SubConfirmationModal from "./SubConfirmationModal";
import InsufficientWallet from "./InsufficientWallet";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
const PromptContainer = ({ courseId }) => {
  const [showSubModal, setShowSubModal] = useState(false);
  const [showSubConfirmModal, setShowSubConfirmModal] = useState(false);
  const [showFundWalletModal, setshowFundWalletModal] = useState(false);
  const [subPlan, setSubPlan] = useState(false);
  const [subPrice, setSubPrice] = useState("");
  const [planName, setPlanName] = useState("");
  const [loading, setLoading] = useState(false);
  const userID = sessionStorage.getItem("userID");
  const departmentID = sessionStorage.getItem("userDepartment");

  useEffect(() => {
    const activePlan = sessionStorage.getItem("userPlan");
    if (activePlan === "Basic") {
      setSubPlan(true);
    } else if (activePlan === "Silver") {
      setSubPlan(true);
    } else if (activePlan === "Plan") {
      setSubPlan(true);
    } else {
      setSubPlan(false);
    }
  }, [subPlan]);
  const openSubModal = () => {
    setShowSubModal(true);
  };

  const closeSubModal = () => {
    setShowSubModal(false);
  };

  const openSubConfirmModal = (price, planName) => {
    setPlanName(planName);
    setSubPrice(price);
    closeSubModal();
    setShowSubConfirmModal(true);
  };

  const closeSubConfirmModal = () => {
    setShowSubConfirmModal(false);
  };
  const openFundWalletModal = (price, planName) => {
    setPlanName(planName);
    setSubPrice(price);
    setshowFundWalletModal(true);
    closeSubModal();
  };
  const closeFundWalletModal = () => {
    setshowFundWalletModal(false);
  };

  const addCourse = async () => {
    setLoading(true);
    const obj = {
      DeptId: departmentID,
      CoursesId: courseId,
    };
    console.log(obj);
    try {
      const response = await axios.post(`AddCourses/${userID}`, obj);
      const resp = response.data.data;
      console.log(resp);
      if (resp.status === "success") {
        toast.success("Course added", {
          theme: "colored",
          autoClose: "2000",
        });
      }
      if (resp.mesaage === "You have exceeded number of chances") {
        toast.warning(
          "You have reached the maximum number of courses allowed on your plan",
          {
            theme: "colored",
            autoClose: "2000",
          }
        );
      }
      if (resp.length === 0) {
        toast.warning("Course already added", {
          theme: "colored",
          autoClose: "2000",
        });
      } else if (resp.status === "failed") {
        toast.error(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
        autoClose: "2000",
      });
      setLoading(false);
    }
  };
  return (
    <div className="d-flex mt-4">
      {!subPlan && (
        <button
          className="blue-outline-btn ms-auto"
          onClick={(e) => {
            e.preventDefault();
            openSubModal();
          }}
        >
          <i className="bi bi-plus"></i>
          Add
        </button>
      )}
      {subPlan && (
        <button
          className="blue-outline-btn ms-auto"
          onClick={(e) => {
            e.preventDefault();
            addCourse();
          }}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm text-blue"></span>
          ) : (
            <>
              <i className="bi bi-plus"></i>
              Add
            </>
          )}
        </button>
      )}
      <SubscriptionModal
        show={showSubModal}
        handleClose={closeSubModal}
        openSubConfirmModal={openSubConfirmModal}
        openFundWalletModal={openFundWalletModal}
      />
      <SubConfirmationModal
        show={showSubConfirmModal}
        handleClose={closeSubConfirmModal}
        price={subPrice}
        planName={planName}
      />
      <InsufficientWallet
        price={subPrice}
        planName={planName}
        show={showFundWalletModal}
        handleClose={closeFundWalletModal}
      />
    </div>
  );
};

export default PromptContainer;

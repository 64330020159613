import React from "react";
import whiteLogo from "../../assets/img/whiteLogo.svg";
import { NavLink } from "react-router-dom";
// import logout from "../../assets/img/logout.svg";
const DashboardSidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={whiteLogo} alt="Logo" />
      </div>
      <nav className="navbar">
        <NavLink
          to="/userdashboard"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-grid-fill nav-icon"></i>
            Dashboard
          </div>
        </NavLink>
        <NavLink
          to="/courses"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi-file-earmark-richtext nav-icon"></i>
            Study Resources
          </div>
        </NavLink>
        <NavLink
          to="/usertutorials"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-people nav-icon"></i>
            Tutorials
          </div>
        </NavLink>

        <NavLink
          to="/userwallet"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-building nav-icon"></i>
            My Wallet
          </div>
        </NavLink>
        <NavLink
          to="/notifications"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-bell nav-icon"></i>
            Notifications
          </div>
        </NavLink>
        <NavLink
          to="/etcbot"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-robot nav-icon"></i>
            AI Vibes
          </div>
        </NavLink>
      </nav>

      <div className="sidebar-tools">
        <nav className="navbar">
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-person-circle nav-icon"></i>
              Profile
            </div>
          </NavLink>
          <NavLink
            to="/help"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-question-circle nav-icon"></i>
              Help
            </div>
          </NavLink>
          <NavLink
            to="#"
            // className={({ isActive }) =>
            //   isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            // }
          >
            <div
              className="nav-item d-flex"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <i className="bi bi-box-arrow-in-left nav-icon"></i>
              {/* <img src={logout} className="px-3" alt="" /> */}
              Logout
            </div>
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default DashboardSidebar;

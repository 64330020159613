import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const TutorUploadTable = () => {
  const itemsPerPage = 15; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [TableData, setTableData] = useState([]);
  const [loading, setloading] = useState(false);
  const [dloading, setdLoading] = useState(false);

  useEffect(() => {
    setloading(true);
    const getTutors = async () => {
      try {
        const response = await axios.get("");
        const studentdata = response.data.data.message;
        setTableData(studentdata);
        setloading(false);
      } catch (error) {
        toast.error(error);
        setloading(false);
      }
    };
    getTutors();
  }, []);

  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = TableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      {loading ? (
        <span
          className="spinner-border spinner-border-xl text-blue"
          role="status"
        ></span>
      ) : (
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="user-resource-head">
                <th scope="col" className="py-3">
                  File Name
                </th>
                <th scope="col" className="py-3">
                  Course Title
                </th>
                <th scope="col" className="py-3">
                  Type
                </th>
                <th scope="col" className="py-3">
                  Status
                </th>
                <th scope="col" className="py-3">
                  Coins Earned
                </th>

                <th scope="col" className="py-3"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} className="table-row">
                  <td>
                   {item.resource_name}
                  </td>
                  <td>{item.course_name}</td>
                  <td>{item.resource_type}</td>
                  <td>{item.status}</td>
                  <td>{item.coin}</td>
                  <td>
                    {dloading ? (
                      <span className="spinner-border text-danger"></span>
                    ) : (
                      <span>Edit</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(TableData.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className={`btn pagination-button mx-1 ${
                    currentPage === index + 1
                      ? "btn-primary active"
                      : "btn-secondary"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TutorUploadTable;

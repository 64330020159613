import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    dashboard: null,
    profile: null,
    wallet: null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    dashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    profile: (state, action) => {
      state.profile = action.payload;
    },
    wallet: (state, action) => {
      state.wallet = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.dashboard = null;
      state.profile = null;
      state.wallet = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, dashboard, profile, wallet } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const dashboardData = (state) => state.user.dashboard;
export const profileData = (state) => state.user.profile;
export const walletData = (state) => state.user.wallet;

export default userSlice.reducer;

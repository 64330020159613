import React from "react";
import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import { Link } from "react-router-dom";
import RequestTutModal from "./RequestTutModal";
import ReactPlayer from "react-player";
// import video from "../../../assets/video.mp4";
const TutorialPage = () => {
  const pageName = "Tutorials";
  const url = "https://youtu.be/AQpqwlq0FkY";
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-3">
        <DashboardHeader pageName={pageName} />
        <div className="container-fluid">
          <div className="d-block d-md-flex live-tutorial-header p-2">
            <div className="d-block">
              <h3 className="viga-24-400">Live Tutorial</h3>
              <p>
                Course: <span>Machine Learning</span>
              </p>
            </div>
            <div className="d-flex mb-2 ms-auto align-items-center">
              <Link to="/userTutorials" className="viga-24-400 text-grey pe-2">
                Back
              </Link>
              <Link className="mx-2">
                <button
                  className="blue-filled-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#requestTutModal"
                >
                  <i className="bi bi-plus"></i> Request Tutorial
                </button>
              </Link>
            </div>
          </div>
          {/* Tutorial */}
          <div className="container py-3 rounded">
            <ReactPlayer
              url={url}
              width="100%"
              height="100vh"
              controls={true}
            />
          </div>
          <RequestTutModal />
        </div>
      </div>
    </div>
  );
};

export default TutorialPage;

import emojiStudent from "../../../assets/emojiStudent.png";
import emojiTeacher from "../../../assets/emojiTeacher.png";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";

const SignUp = () => {
  // const firstProp = "<StudentForm />";
  // const secondProp = "<TutorForm />";
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 signup-left">
          <Logo />
          <div className="ps-4 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-white viga-24-400">ETC Vibes</h2>
              <p className="text-white w-75">
                providing you with everything required to make your striaght As!
                on a single platform.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-white p-0 m-0 p-md-3 d-flex">
          <div className="container form-container m-auto">
            <div className="d-flex justify-content-center d-md-none">
              <Logo />
            </div>
            <h3 className="viga-24-400 text-blue signup-header">
              Join ETC Vibes
            </h3>
            <p className="text-grey d-none d-md-block d-lg-block d-flex">
              Get ahead in your studies with our comprehensive course-specific
              study materials and tutorials.
            </p>
            <div className="row row-cols-2 my-3 mx-0 my-md-5 w-100">
              <div className="col d-flex justify-content-center">
                <Link to="/createAccount/student">
                  <div className="option-container login-option">
                    <div className="d-flex justify-content-center">
                      <img src={emojiStudent} alt="" /> <br />
                    </div>
                    <span className="blue-h5">As a Student</span>
                  </div>
                </Link>
              </div>
              <div className="col d-flex justify-content-center">
                <Link to="/createAccount/tutor">
                  <div className="option-container login-option">
                    <img src={emojiTeacher} alt="" /> <br />
                    <span className="blue-h5">As a Tutor</span>
                  </div>
                </Link>
              </div>
            </div>
            <span className="login-redirect px-3 px-md-0">
              Already have an account?
              <Link to="/login" className="blue-span ps-1">
                Login
              </Link>
            </span>
            <p className=" d-sm-block d-md-none mt-5 px-3">
              Join Exam Tutorial Course Vibes to get access to study materials,
              live tutorial,text book solutions and more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";
import LogoutModal from "../../../components/LogoutModal";
import { Link } from "react-router-dom";

const AdminTutDetails = () => {
  const resource = [
    {
      id: 1,
      tutorial: "Introduction to Machine Learning",
      department: "Computer Science",
      tutor: "Mr. Isaac Olumide",
      university: "Redeemers University",
      type: "General",
      scheduler: "Tutor",
      status: "24 pages",
      date: "24/05/2023",
      time: "5:30 PM",
      regstudents: "200",
      attendees: "150",
    },
  ];
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <Link to="/admintutorial">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
            <span className="viga-24-400">Tutorial Details</span>
          </div>
          <div className="d-flex mb-3">
            <button className="grey-btn me-2 ms-auto">Edit Details</button>
            <button className="btn btn-danger mx-2">Delete</button>
          </div>
          <div className="container">
            {resource.map((details) => (
              <div className="d-block">
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Tutorial:</span>
                  <span className="lato-24-400">{details.tutorial}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Department:</span>
                  <span className="lato-24-400">{details.department}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">University:</span>
                  <span className="lato-24-400">{details.university}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Tutor:</span>
                  <span className="lato-24-400">{details.tutor}</span>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Tutorial Type:</span>
                  <span className="lato-24-400">{details.type}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Scheduled By:</span>
                  <span className="lato-24-400">{details.scheduler}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Date:</span>
                  <span className="lato-24-400">{details.date}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Time:</span>
                  <span className="lato-24-400">{details.time}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">
                    Number of Registered Students:{" "}
                  </span>
                  <span className="lato-24-400">
                    {details.regstudents} Students
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">
                    Number of Attendees :
                  </span>
                  <span className="lato-24-400">
                    {details.attendees} Students
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminTutDetails;

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
const LogoutModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/login");
  };
  return (
    <div
      className="modal fade"
      id="logoutModal"
      aria-labelledby="logoutModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-4">
            <div className="d-flex">
              <button
                type="button"
                className="btn-close ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <h3 className="lato-32-500 my-4">
              Are you sure you want to Logout?
            </h3>
            <div className="d-flex justify-content-center">
              <Link to="#" className="px-4 me-4">
                <button
                  className="btn yes-btn"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleLogout(e)}
                >
                  Yes
                </button>
              </Link>
              <button className="btn no-btn px-3" data-bs-dismiss="modal">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import students from "../../assets/Group 36639.png";
import card13 from "../../assets/Rectangle 2749 (2).png";
import physics from "../../assets/img/physics.png";
import biology from "../../assets/img/biology.png";
import chemistry from "../../assets/img/chemistry.png";
import { Link } from "react-router-dom";

function CoursesModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="grid-example">
        <div className="coursecardcc">
          <Card id="cardd" style={{ width: "296px" }}>
            <Card.Img variant="top" src={biology} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 40 students</h5> */}
              </div>
              {/* <h6>300 Study resources</h6> */}
              <Card.Title className="ccd">Biology</Card.Title>
              <Card.Text className="cardbody">
                Delve into the science of life itself with our Biology course on
                ETC Vibes. Discover the intricacies of living organisms, from
                cells and genetics to ecosystems and evolution.
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </Card>
          <Card id="cardd" style={{ width: "296px" }}>
            <Card.Img variant="top" src={chemistry} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 11 students</h5> */}
              </div>
              {/* <h6>200 Study resources</h6> */}
              <Card.Title className="ccd">Chemistry</Card.Title>
              <Card.Text className="cardbody">
                Unleash the power of atoms and molecules with our Chemistry
                course on ETC Vibes. Explore the fundamental principles of
                matter, reactions, and the periodic table in our interactive
                learning environment.
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </Card>
          <Card id="cardd" style={{ width: "296px" }}>
            <Card.Img variant="top" src={card13} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 234 students</h5> */}
              </div>
              {/* <h6>500 Study resources</h6> */}
              <Card.Title className="ccd">Zoology</Card.Title>
              <Card.Text className="cardbody">
                Explore the wonders of the animal kingdom with our Zoology
                course on ETC Vibes. From microscopic organisms to majestic
                mammals, dive into the study of animal life and behavior.
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </Card>
          <Card id="cardd" style={{ width: "296px" }}>
            <Card.Img variant="top" src={physics} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 40 students</h5> */}
              </div>
              {/* <h6>300 Study resources</h6> */}
              <Card.Title className="ccd">Physics</Card.Title>
              <Card.Text className="cardbody">
                Embark on a journey through the laws of the universe with our
                Physics course on ETC Vibes. From classical mechanics to quantum
                physics, unravel the mysteries of motion, energy, and the
                cosmos.
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CoursesModal;

import React from "react";
import fly1 from "../../assets/Group 239.png";
import fly2 from "../../assets/Group 6.png";
import BecomeNavbar from "../become tutor/BecomeNavbar";
import search from "../../assets/Vector search.png";
import { useState } from "react";
import CoursesModal from "./CoursesModal";

const Newhomehead = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <main className="herohome">
      <BecomeNavbar />
      <CoursesModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="study">
        <h1>
          <span>L</span>earn anywhere,anytime!
          {/* Learn anywhere, anytime! */}
        </h1>
        <h3>Unlock Your Academic Potential with ETC Vibes</h3>
        <div onClick={() => setModalShow(true)} className="search">
          <img src={search} alt="" />
          <h3 className="text-black">What do you want to study today?</h3>
        </div>
        <div className="sidebut1">
          <div className="flycon1">
            <img src={fly1} alt="study resources" />
            <div className="flytext">
              <h5>70+</h5>
              <h6>Study Resources</h6>
            </div>
          </div>
        </div>
        <div className="sidebut">
          <div className="flycon1">
            <img src={fly2} alt="students" />
            <div className="flytext">
              <h5>250+</h5>
              <h6>Tutorials</h6>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Newhomehead;

import React from "react";
import { Modal, ModalBody } from "react-bootstrap";

const PlanRenewalNotice = ({ show, handleClose }) => {
  const subdays = sessionStorage.getItem("userSubDays");
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="container">
          <h2 className="viga-24-400 text-blue text-center mb-3">
            🔔 Important Notice 🔔
          </h2>
          <p className="text-danger poppins-15-400">
            Subscription Renewal Notice
          </p>
          <p className="poppins-15-400">
            Your subscription is set to renew automatically from your wallet in
            the next <b>{subdays} days</b> . Please ensure your wallet has
            sufficient funds to avoid any interruption in your access.
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PlanRenewalNotice;

import React from "react";
import { Link } from "react-router-dom";
import blueLogo from "../assets/img/blueLogo.svg";

const Logo = () => {
  return (
    <div>
      <Link to="/" className="text-decoration-none">
        <img src={blueLogo} className="logo" alt="Logo" />
      </Link>
    </div>
  );
};

export default Logo;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
const MyCourses = ({ UserPastQuestions, UserTutorial, UserNote, UserTextbook, UserCourses, UserResources }) => {
  // const tutorialNumber = "10";

  return (
    <div className="container-fluid course-container ">
      <h5 className="ps-3 px-md-0 viga-24-400 text-grey">My Course Overview</h5>
      <div className="scrolling-wrapper dashboard-courses justify-content-between row p-1 p-md-0">
        {/* <div className="col">
          <div className="dashboard-card border-left-goldShade p-2 d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-goldShade"
                icon={faClipboardList}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark">Courses</p>
                <span className="viga-20-400 text-goldShade">
                  {UserCourses}
                </span>
              </div>
            </div>
          </div>
        </div> */}
         <div className="col">
          <div className="dashboard-card border-left-goldShade p-2 d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-goldShade"
                icon={faClipboardList}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark"> Academic Courses</p>
                <span className="viga-20-400 text-goldShade">
                  {UserCourses}
                </span>
              </div>
            </div>
          </div>
        </div>
    
        <div className="col">
          <div className="dashboard-card border-left-purple p-2 d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-purple"
                icon={faClipboardList}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark">Resources</p>
                <span className="viga-20-400 text-purple">
                  {UserResources}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className=" dashboard-card border-left-pink p-2  d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-pink"
                icon={faMicrophone}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark">Tutorials</p>
                <span className="viga-20-400 text-pink">{UserTutorial}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="dashboard-card border-left-blueShade p-2 d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-blue"
                icon={faClipboardList}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark">Past Questions</p>
                <span className="viga-20-400 text-blue">
                  {UserPastQuestions}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col">
          <div className=" dashboard-card border-left-purple p-2  d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-purple"
                icon={faClipboardList}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark">Notes</p>
                <span className="viga-20-400 text-purple">{UserNote}</span>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col">
          <div className="dashboard-card border-left-blueShade p-2 d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <FontAwesomeIcon
                className="fs-3 px-2 text-blue"
                icon={faClipboardList}
              />
              <div className="d-block text-center">
                <p className="lato-20-400 text-dark ">Textbooks</p>
                <span className="viga-20-400 text-blue">{UserTextbook}</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MyCourses;

import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const TutorsTable = () => {
  const [TableData, setTableData] = useState([]);
  const itemsPerPage = 15; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    const getTutors = async () => {
      try {
        const response = await axios.get("listTutor");
        const tutordata = response.data.data.message;
        console.log(tutordata);
        setTableData(tutordata);
        setloading(false);
      } catch (error) {
        toast.error(error);
        setloading(false);
      }
    };
    getTutors();
  }, []);
  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = TableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();
  const handleDetails = (id) => {
    navigate(`/admintutor/details/${id}`);
  };
  return (
    <div className="container-fluid p-0">
      <div className="container-fluid">
        <h4 className="viga-24-400 text-blue">All Tutors</h4>
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  ID
                </th>
                <th scope="col" className="py-3">
                  Tutor's Name
                </th>
                <th scope="col" className="py-3">
                  Department
                </th>
                <th scope="col" className="py-3">
                  School
                </th>
                <th scope="col" className="py-3">
                  Email
                </th>
                <th scope="col" className="py-3">
                  Earned money
                </th>
              </tr>
            </thead>
            {loading ? (
              <>
                <tbody
                  className="spinner-border spinner-border-lg text-primary"
                  role="status"
                ></tbody>
              </>
            ) : (
              <tbody>
                {currentItems.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row"
                    onClick={() => handleDetails(item.id)}
                  >
                    <td>{item.id}</td>
                    <td>{item.fullname}</td>
                    <td>{item.program}</td>
                    <td>{item.school}</td>
                    <td>{item.email}</td>
                    <td>{item.wallet}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(TableData.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                className={`btn pagination-button mx-1 ${
                  currentPage === index + 1
                    ? "btn-primary active"
                    : "btn-secondary"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TutorsTable;

import { useState } from "react";
import courseIcon from "../../../assets/img/courses.jpg";
import PromptContainer from "./PromptContainer";
import { Pagination } from "react-bootstrap";
const AllCoursesContent = ({ courses, userDepartment }) => {
  const coursesPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  // Get current courses
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <i className="bi bi-star-fill text-danger fs-3 pe-3"></i>
        <span className="viga-24-400">{userDepartment.dept_name}</span>
      </div>
      <div className="divider course-divider bg-secondary"></div>
      <div className="row row-cols-1 row-cols-md-2 p-2">
        {currentCourses.length === 0 ? (
          <p className="text-center mt-5 text-blue">No course available</p>
        ) : (
          currentCourses.map((course) => (
            <div className="col" key={course.id}>
              <div className="all-courses-card mb-5">
                <div className="all-courses-img">
                  <img src={courseIcon} className="" alt="" />
                </div>
                <div className="all-courses-body p-3 w-100">
                  <div className="d-block">
                    <h4 className="roboto-14-700">{course.coursename}</h4>
                    {/* <div className="">
                      <span className="roboto-12-400">{course.coursecode}</span>
                      <span className="roboto-12-400 ms-auto">
                        {course.courselevel} Level
                      </span>
                    </div> */}
                  </div>
                  <PromptContainer courseId={course.id} />
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <Pagination className="">
        {[...Array(Math.ceil(courses.length / coursesPerPage))].map(
          (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>
    </div>
  );
};

export default AllCoursesContent;

import AdminSidebar from "../../AdminSidebar";
import LogoutModal from "../../../../components/LogoutModal";
import AdminHeader from "../../AdminHeader";
import { Link } from "react-router-dom";
import CoinRequestTable from "./CoinRequestTable";

const CoinRequestPage = () => {
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid">
          <div className="d-block">
            <h2 className="viga-24-400">Coin Conversion Requests</h2>
            <Link to="/admin" className="">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
          </div>
          <CoinRequestTable />
        </div>
      </div>
    </section>
  );
};

export default CoinRequestPage;

import { usePaystackPayment } from "react-paystack";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../../../api/axios";

const PaystackHookExample = ({ amount, onSuccess, onClose, payerEmail }) => {
  const config = {
    reference: new Date().getTime().toString(),
    email: payerEmail,
    amount: amount,
    publicKey: "pk_live_29a0607b07f08022b021f64390d0da478954cdc8",
  };
  const initializePayment = usePaystackPayment(config);
  return (
    <div className="m-auto">
      <button
        type="submit"
        className="blue-filled-btn "
        onClick={() => {
          initializePayment(onSuccess, onClose);
        }}
      >
        Pay
      </button>
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

const PaystackPayment = ({ amount, payerEmail, reloadWalletData }) => {
  const onSuccess = (reference) => {
    const ref = reference.reference;
    console.log(reference);
    const userId = sessionStorage.getItem("userID");
    const userEmail = sessionStorage.getItem("userEmail");
    const userName = sessionStorage.getItem("username");
    const obj = {
      amount: amount / 100,
      userid: userId,
      email: userEmail,
      ref: ref,
      payer_name: userName,
    };
    const resp = axios.post("FundingWallet", obj);
    console.log(resp.data.data);
    toast.success("Payment successful", {
      theme: "colored",
      autoClose: 3000,
    });
  };

  const onClose = () => {
    console.log("closed");
    reloadWalletData();
  };
  return (
    <PaystackHookExample
      amount={amount}
      onSuccess={onSuccess}
      onClose={onClose}
      payerEmail={payerEmail}
    />
  );
};

export default PaystackPayment;

import React from "react";
import "./Newhome.css";
import "../Home/home.css";
import Newhomehead from "./Newhomehead";
import Newhomeexp from "./Newhomeexp";
import Newhomemat from "./Newhomemat";
import Newhometut from "./Newhometut";
import Partners from "../become tutor/Partners";
import Footer from "../become tutor/Footer";
import Aboutus from "../become tutor/Aboutus";
import Testimonials from "../become tutor/Testimonials";
import Newfaq from "./Newfaq";
import Newhomeplans from "./Newhomeplans";
import Newcomm from "./Newcomm";
import { JellyTriangle } from "@uiball/loaders";
import { useState, useEffect } from "react";
import bot from "../../assets/FrameBot.png";
import AIModal from "./AIModal";

const Newhome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [aishow, setaiShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {" "}
      {isLoading ? (
        <div className="container-fluid d-flex justify-content-center align-items-center preloader-container">
          <div className="container d-flex justify-content-center">
            <JellyTriangle size={80} speed={1.75} color="#044aac" />
          </div>
        </div>
      ) : (
        <div>
          <div onClick={() => setaiShow(true)} className="bot">
            <img src={bot} alt="ai vibes" />
          </div>
          <AIModal show={aishow} onHide={() => setaiShow(false)} />
          <Newhomehead />
          <Newhomeexp />
          <Newhomemat />
          <Newhometut />
          <Newcomm />
          <Testimonials head="Success Stories" />
          <Newhomeplans />
          <Aboutus tutor="abouthome" />
          <Newfaq />
          <Partners />
          <Footer />{" "}
        </div>
      )}
    </>
  );
};

export default Newhome;

import { Link, useParams } from "react-router-dom";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import TutorSidebar from "../TutorSidebar";
// import ZoomMeetingEmbedded from "../../Test";

const MeetingPage = () => {
  const { 0: wildcard } = useParams();
  const webPageUrl = wildcard;
  console.log(webPageUrl);

  const meetingNumber = "123456789";
  const password = "YOUR_ZOOM_MEETING_PASSWORD";
  const userName = sessionStorage.getItem("tutorname");
  const userEmail = sessionStorage.getItem("tutorEmail");
  return (
    <div className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 tutorTutorial">
        <TutorHeader />
        <div className="container-fluid">
          <div className="d-block d-md-flex live-tutorial-header p-2 mb-5">
            <div className="d-block">
              <h3 className="viga-24-400">Live Tutorial</h3>
              <p>
                Course: <span>Machine Learning</span>
              </p>
            </div>
            <div className="d-flex mb-2 ms-auto align-items-center">
              <Link to="/tutortutorials" className="viga-24-400 text-grey pe-2">
                Back
              </Link>
            </div>
          </div>
          {/* <div className="iframe-container shadow ">
            <ZoomMeetingEmbedded
              meetingNumber={meetingNumber}
              password={password}
              userName={userName}
              userEmail={userEmail}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MeetingPage;

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserSuccess from "../SuccessPage/UserSuccess";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../../../api/axios";
const UserSetupForm = () => {
  const refID = sessionStorage.getItem("referralID");
  const navigate = useNavigate();
  const [successModal, setsuccessModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [University, setUniversity] = useState([]);
  const [formData, setFormData] = useState({
    program: "",
    school: "",
    level: "",
    dob: "",
    nickname: "",
    gender: "",
    referral_by: refID,
  });

  const closesuccessModal = () => setsuccessModal(false);
  useEffect(() => {
    // let userID = sessionStorage.getItem("userID");
    // if (userID === "" || userID === null) {
    //   navigate("/login");
    // }
    const getDepartments = async () => {
      try {
        const response = await axios.get(`Department`);
        const departments = response.data.data;
        departments.sort((a, b) => a.Department.localeCompare(b.Department));
        setDepartments(departments);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    getDepartments();

    const getUniversity = async () => {
      try {
        const response = await axios.get(`schools`);
        const University = response.data.data;
        console.log(University);
        setUniversity(University);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    getUniversity();
  }, [navigate]);
  const slides = [
    {
      label: "Slide 1",
      fields: [
        {
          label: "What is your Programme of study",
          name: "program",
          type: "select",
          options: [
            "Accounting",
            "Anatomy",
            "Architecture",
            "Biology",
            "Banking and Finance",
            "BioChemistry",
            "Business Administration",
            "Civil Engineering",
            "Computer Engineering",
            "Computer Science",
            "Christian and Religious Studies",
            "Cyber Security",
            "Economics",
            "English",
            "Estate Management",
            "French",
            "Geology",
            "History and International Relations",
            "Industrial Chemistry",
            "Information Technology",
            "Insurance",
            "Law",
            "Mass Communication",
            "Marketing",
            "Mathematics",
            "Mechanical Engineering",
            "Microbiology",
            "Nursing Science",
            "Quantity Surveying",
            "Philosophy",
            "Physics and Electronics",
            "Physiology",
            "Physiotherapy",
            "Political Science",
            "Psychology",
            "Public Administration",
            "Sociology",
            "Statistics",
            "Theatre Arts",
            "Tourism",
            "Transport Management",
          ],
          id: 1,
        },
        {
          label: "University",
          name: "school",
          type: "select",
          options: [],
          id: 2,
        },
        {
          label: "What is your level of study",
          name: "level",
          type: "select",
          options: ["100", "200", "300", "400", "500"],
          id: 3,
        },
      ],
      id: 1,
    },
    {
      label: "Slide 2",
      fields: [
        {
          label: "What should we call you?",
          name: "nickname",
          type: "text",
          id: 1,
        },
        {
          label: "What is your date of birth?",
          name: "dob",
          type: "date",
          id: 2,
        },
        {
          label: "What is your Gender?",
          name: "gender",
          type: "radio",
          options: ["Male", "Female", "Other"],
          id: 3,
        },
        {
          label: "Enter Referral Code (Optional)",
          name: "referral_by",
          type: "ref",
          id: 4,
        },
      ],
      id: 2,
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };
  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log(formData);
      const selectedDepartment = departments.find(
        (dept) => dept.Department === formData.program
      );
      console.log(selectedDepartment);
      if (selectedDepartment) {
        const formPayload = {
          ...formData,
          faculty: selectedDepartment.FaultyId,
        };
        console.log(formPayload);
        // Get current user email from sessionStorage
        const userID = sessionStorage.getItem("userID");
        // Push data to server
        axios
          .post(`stageOne/${userID}`, formPayload)
          .then((response) => {
            let resp = response.data.data;
            if (resp.status === "success") {
              toast.success("Account is active", {
                theme: "colored",
                autoClose: 3000,
              });
              setsuccessModal(true);
            } else {
              toast.error(resp.message, {
                theme: "colored",
                autoClose: 3000,
              });
            }
          })
          .catch((err) => {
            toast.error("Error: " + err.message, {
              theme: "colored",
              autoClose: 3000,
            });
          });
      } else {
        toast.error("Selected department not found.", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    }
  };

  const validateForm = () => {
    for (const slide of slides) {
      for (const field of slide.fields) {
        const fieldValue = formData[field.name];

        if (field.type === "select" && fieldValue === "") {
          toast.warning(`Please select an option for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        } else if (
          field.type === "date" &&
          (fieldValue === null || fieldValue === "")
        ) {
          toast.warning(`Please select a date for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        } else if (field.type === "ref") {
          return true;
        } else if (
          field.type !== "select" &&
          field.type !== "date" &&
          field.type !== "ref" &&
          fieldValue.trim() === ""
        ) {
          toast.warning(`Please fill in the "${field.label}" field.`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        }
      }
    }
    return true;
  };

  return (
    <div className="carousel-form">
      <div className="d-flex w-100 align-items-center mb-4 mb-md-5">
        <div className="indicators ps-4">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`indicator ${currentSlide === index ? "active" : ""}`}
              onClick={() => handleIndicatorClick(index)}
            ></div>
          ))}
        </div>
      </div>

      <div
        className={currentSlide === slides.length - 1 ? "slides2" : "slides"}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${currentSlide === index ? "active" : ""}`}
          >
            {slide.fields.map((field) => {
              if (field.type === "select") {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="form-select carousel-select"
                    >
                      <option value="">Select an option</option>
                      {field.name === "program" &&
                        departments.map((department) => (
                          <option
                            value={department.Department}
                            key={department.DepartmentId}
                          >
                            {department.Department}
                          </option>
                        ))}
                      {field.name === "school" &&
                        University.map((university) => (
                          <option value={university.id} key={university.id}>
                            {university.schoolName}
                          </option>
                        ))}
                      {field.name !== "program" &&
                        field.options.map((option) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                );
              } else if (field.type === "date") {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type="date"
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                );
              } else if (field.type === "radio") {
                return (
                  <div className="mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <div key={field.name} className="d-flex">
                      {field.options.map((option) => (
                        <div className="form-check px-4" key={option}>
                          <input
                            type="radio"
                            name={field.name}
                            value={option}
                            checked={formData[field.name] === option}
                            onChange={handleInputChange}
                            className="form-check-input"
                          />
                          {option}
                          <label
                            className="form-check-label"
                            key={option}
                          ></label>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              } else if (field.type === "ref") {
                return (
                  <div className="d-block mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      disabled={refID !== ""}
                      type="text"
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="text-input ps-2"
                    />
                  </div>
                );
              } else {
                return (
                  <div className="d-block mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="text-input ps-2"
                    />
                  </div>
                );
              }
            })}
          </div>
        ))}
      </div>

      {currentSlide === slides.length - 1 && (
        <button type="submit" className="submit-button" onClick={handleSubmit}>
          Join Now
        </button>
      )}
      {currentSlide < slides.length - 1 && (
        <button className="next-button" onClick={handleNextSlide}>
          Move on
        </button>
      )}
      <ToastContainer transition={Zoom}></ToastContainer>
      <UserSuccess show={successModal} handleClose={closesuccessModal} />
    </div>
  );
};

export default UserSetupForm;

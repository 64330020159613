import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axios from "../../../../../api/axios";

const AddTexbook = ({ show, handleClose }) => {
  const [isloading, setisLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState("");
  const [courseId, setcourseId] = useState("");
  const [courses, setCourses] = useState([]);
  const [images, setimages] = useState("");
  const [Name, setName] = useState("");
  const [CourseTutor, setCourseTutor] = useState("");
  const [CourseCode, setCourseCode] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [Description, setDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const courseData = await axios.get("ListCourses");
        const deptData = await axios.get("listDepartment");
        const cou = courseData.data.data.message;
        setCourses(cou);
        console.log(cou);
        const dept = deptData.data.data.message;
        setDepartment(dept);
      } catch (error) {}
    };
    fetchData();
  }, []);
  const submitForm = async (e) => {
    e.preventDefault();
    setisLoading(true);

    // Create FormData object to handle file data
    const formData = new FormData();
    formData.append("Departmentid", selectedDepartment);
    formData.append("courseId", courseId);
    formData.append("Name", Name);
    formData.append("Description", Description);
    formData.append("CourseName", CourseName);
    formData.append("CourseCode", CourseCode);
    formData.append("images", images);
    formData.append("CourseTutor", CourseTutor);
    try {
      await axios.post("createtextbook", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleClose();
      toast.success("Resource added", {
        theme: "colored",
        autoClose: "2000",
      });
      setisLoading(false);
    } catch (error) {
      toast.error(error);
      setisLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        <h3 className="fw-bold text-blue">Add Textbook</h3>
      </ModalHeader>
      <ModalBody>
        <form className="container" onSubmit={submitForm}>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course
            </label>
            <select
              className="resource-input"
              onChange={(e) => setcourseId(e.target.value)}
              value={courseId}
            >
              <option value="">Select Course</option>
              {courses.map((fac, index) => (
                <option key={index} value={fac.CourseID}>
                  {fac.CourseTitle}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Department
            </label>
            <select
              className="resource-input"
              onChange={(e) => setselectedDepartment(e.target.value)}
              value={selectedDepartment}
            >
              <option value="">Select Department</option>
              {department.map((dep, index) => (
                <option key={index} value={dep.id}>
                  {dep.dept_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Name
            </label>
            <input
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Name
            </label>
            <input
              type="text"
              value={CourseName}
              onChange={(e) => setCourseName(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Code
            </label>
            <input
              type="text"
              value={CourseCode}
              onChange={(e) => setCourseCode(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Description
            </label>
            <input
              type="text"
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course Tutor
            </label>
            <input
              type="text"
              value={CourseTutor}
              onChange={(e) => setCourseTutor(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              File
            </label>
            <input
              type="file"
              onChange={(e) => setimages(e.target.files[0])}
              className="resource-input"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button className="blue-filled-btn text-center" type="submit">
              {isloading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span> Add</span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddTexbook;

import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const ScheduleTut = ({ show, handleClose }) => {
  const [DepartmentId, setDepartmentId] = useState("");
  const TutorId = sessionStorage.getItem("tutorId");
  const [TutorialTitle, setTutorialTitle] = useState("");
  const [Course, setCourse] = useState("");
  const [time, setTime] = useState("");
  const [Duration, setDuration] = useState("45");
  const [selectedDate, setDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [facultyId, setfacultyId] = useState("");
  const [price, setPrice] = useState("");
  useEffect(() => {
    axios
      .get("Department")
      .then((response) => {
        setDepartments(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const validateForm = () => {
    let result = true;
    if (TutorialTitle === "" || TutorialTitle === null) {
      result = false;
      toast.warning("Select a course");
    }
    if (price === "" || price === null) {
      result = false;
      toast.warning("Pick a Price");
    }
    if (Duration === "" || Duration === null) {
      result = false;
      toast.warning("Pick a time period");
    }
    if (time === "" || time === null) {
      result = false;
      toast.warning("Pick a time");
    }
    if (selectedDate === "" || selectedDate === null) {
      result = false;
      toast.warning("Pick a date");
    }
    if (Course === "" || Course === null) {
      result = false;
      toast.warning("Write a Course");
    } else {
      const currentDate = new Date();
      const selectedDateTime = new Date(`${selectedDate}T${time}`);

      if (selectedDateTime < currentDate) {
        result = false;
        toast.warning("Selected date and time must be in the future");
      }
    }
    return result;
  };
  const createTutorial = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const datetime = `${selectedDate}T${time}:00Z`;

      const obj = {
        TutorId,
        DepartmentId,
        TutorialTitle,
        Course,
        start_time: datetime,
        Duration,
        facultyId,
        price,
      };
      console.log(obj);
      axios
        .post("createNewMeeting", obj)
        .then((response) => {
          console.log(response.data);
          toast.success("Tutorial scheduled successfully");
          setIsLoading(false);
          handleClose();
        })
        .catch((error) => {
          console.error("Error creating meeting:", error);
          toast.error(error);
          setIsLoading(false);
        });
    }
  };
  const handleSelectChange = (event) => {
    const selectedDepartmentId = event.target.value; // Get the selected department ID from the event
    setSelectedDepartment(selectedDepartmentId); // Update the selected department in the state
    setDepartmentId(selectedDepartmentId); // Set the DepartmentId to the selected department ID

    // Find the selected department in the departments array
    const filteredDepartments = departments.filter(
      (department) =>
        department.DepartmentId === parseInt(selectedDepartmentId, 10)
    );

    console.log(filteredDepartments[0]);
    console.log(filteredDepartments[0].FaultyId);
    // Extract and set the facultyId from the selected department
    if (filteredDepartments) {
      setfacultyId(filteredDepartments[0].FaultyId);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader className="align-items-center" closeButton>
        <h3 className="poppins-24-600 text-blue text-center m-auto">
          Schedule Tutorial
        </h3>
      </ModalHeader>
      <ModalBody>
        <form className="form schedule-tutorial" onSubmit={createTutorial}>
          <div className="mb-4">
            <label htmlFor="Course ?" className="poppins-24-500">
              Department
            </label>
            <select
              className="login-input"
              value={selectedDepartment}
              onChange={handleSelectChange}
            >
              <option value="">Select a Department</option>
              {departments.map((department) => (
                <option
                  key={department.DepartmentId + department.FaultyId}
                  value={department.DepartmentId}
                >
                  {department.Department}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-24-500">
              Tutorial Title
            </label>
            <input
              type="text"
              className="login-input"
              value={TutorialTitle}
              placeholder="Machine learning"
              onChange={(e) => setTutorialTitle(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-24-500">
              Course Code
            </label>
            <input
              type="text"
              className="login-input"
              value={Course}
              placeholder="CMP 101"
              onChange={(e) => setCourse(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="" className="poppins-24-500">
              Duration
            </label>
            <select
              value={Duration}
              onChange={(e) => setDuration(e.target.value)}
              className="login-input"
            >
              <option value="45">45 Minutes</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="" className="poppins-24-500">
              Price
            </label>
            <select
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="login-input"
            >
              <option value=""></option>
              <option value="0">Free</option>
              <option value="1000">₦1000</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="poppins-24-500">Date</label>
            <input
              type="date"
              className="login-input"
              value={selectedDate}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="" className="poppins-24-500">
              Time
            </label>
            <input
              type="time"
              value={time}
              className="login-input"
              onChange={(e) => setTime(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button type="submit" className="blue-filled-btn">
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span>Schedule</span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ScheduleTut;

import { useState } from "react";
import TutorSuccess from "../SuccessPage/TutorSuccess";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "../../../api/axios";
import { ToastContainer, Zoom, toast } from "react-toastify";

const TutorSetupForm = () => {
  const navigate = useNavigate();
  const [successModal, setsuccessModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    program: "",
    qualification: "",
    Gender: "",
    ValidId: "",
    IdNumber: "",
  });
  const tutorID = sessionStorage.getItem("tutorId");
  const closesuccessModal = () => setsuccessModal(false);

  useEffect(() => {
    if (tutorID === "" || tutorID === null) {
      navigate("/login");
    }
    const getDepartments = async () => {
      try {
        const response = await axios.get(`Department`);
        const departments = response.data.data;
        departments.sort((a, b) => a.Department.localeCompare(b.Department));
        setDepartments(departments);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    getDepartments();
  }, [navigate, tutorID]);
  const slides = [
    {
      label: "Slide 1",
      fields: [
        {
          label: "What is your Major course of Specialization?",
          name: "program",
          type: "select",
          options: [
            "Accounting",
            "Anatomy",
            "Architecture",
            "Biology",
            "Banking and Finance",
            "BioChemistry",
            "Business Administration",
            "Civil Engineering",
            "Computer Engineering",
            "Computer Science",
            "Christian and Religious Studies",
            "Cyber Security",
            "Economics",
            "English",
            "Estate Management",
            "French",
            "Geology",
            "History and International Relations",
            "Industrial Chemistry",
            "Information Technology",
            "Insurance",
            "Law",
            "Mass Communication",
            "Marketing",
            "Mathematics",
            "Mechanical Engineering",
            "Microbiology",
            "Nursing Science",
            "Quantity Surveying",
            "Philosophy",
            "Physics and Electronics",
            "Physiology",
            "Physiotherapy",
            "Political Science",
            "Psychology",
            "Public Administration",
            "Sociology",
            "Statistics",
            "Theatre Arts",
            "Tourism",
            "Transport Management",
          ],
          id: 1,
        },
        {
          label: "What is your highest Qualification?",
          name: "qualification",
          type: "select",
          options: ["Bachelors Degree", "Masters Degree", "Diploma", "HND/ND"],
          id: 2,
        },
      ],
      id: 1,
    },

    {
      label: "Slide 2",
      fields: [
        {
          label: "What Valid ID Card do you have?",
          name: "ValidId",
          type: "select",
          options: [
            "NIN",
            "Drivers License",
            "Passport",
            "Matriculation Number",
          ],
          id: 1,
        },
        {
          label: "what is your ID Card number?",
          name: "IdNumber",
          type: "text",
          placeholder: "e.g 23423455334",
          id: 2,
        },
        {
          label: "What is your Gender?",
          name: "Gender",
          type: "radio",
          options: ["Male", "Female", "Other"],
          id: 3,
        },
      ],
      id: 2,
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };
  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const selectedDepartment = departments.find(
        (dept) => dept.Department === formData.program
      );
      console.log(selectedDepartment);
      if (selectedDepartment) {
        const formPayload = {
          ...formData,
          faculty: selectedDepartment.FaultyId,
        };
        // Fetch user data from the server
        axios
          .post(`TutorStageOne/${tutorID}`, formPayload)
          .then((response) => {
            let resp = response.data.data;
            if (resp.status === "success") {
              toast.success("Account is active", {
                theme: "colored",
                autoClose: 3000,
              });
              setIsLoading(false);
              setsuccessModal(true);
            } else {
              toast.error(resp.message, {
                theme: "colored",
                autoClose: 3000,
              });
              setIsLoading(false);
            }
          })
          .catch((err) => {
            toast.error("Error: " + err.message, {
              theme: "colored",
              autoClose: 3000,
            });
            setIsLoading(false);
          });
      } else {
        toast.error("Selected department not found.", {
          theme: "colored",
          autoClose: 3000,
        });
        setIsLoading(false);
      }
    }
  };
  const validateForm = () => {
    for (const slide of slides) {
      for (const field of slide.fields) {
        const fieldValue = formData[field.name];
        setIsLoading(true);
        if (field.type === "select" && fieldValue === "") {
          toast.warning(`Please select an option for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          setIsLoading(false);
          return false;
        } else if (field.type === "date" && fieldValue === null) {
          toast.warning(`Please select a date for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          setIsLoading(false);
          return false;
        } else if (
          field.type !== "select" &&
          field.type !== "date" &&
          fieldValue.trim() === ""
        ) {
          toast.warning(`Please fill in the "${field.label}" field.`, {
            theme: "colored",
            autoClose: 3000,
          });
          setIsLoading(false);
          return false;
        }
      }
    }
    return true;
  };
  return (
    <div className="carousel-form">
      <div className="d-flex w-100 align-items-center mb-4 mb-md-5">
        <div className="indicators ps-4">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`indicator ${currentSlide === index ? "active" : ""}`}
              onClick={() => handleIndicatorClick(index)}
            ></div>
          ))}
        </div>
      </div>

      <div className="slides">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${currentSlide === index ? "active" : ""}`}
          >
            {slide.fields.map((field) => {
              if (field.type === "select") {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="form-select carousel-select"
                    >
                      <option value="">Select an option</option>
                      {field.name === "program" &&
                        departments.map((department) => (
                          <option
                            value={department.Department}
                            key={department.DepartmentId}
                          >
                            {department.Department}
                          </option>
                        ))}
                      {field.name !== "program" &&
                        field.options.map((option) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                );
              } else if (field.type === "date") {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type="date"
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                );
              } else if (field.type === "radio") {
                return (
                  <div className="mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <div key={field.name} className="d-flex">
                      {field.options.map((option) => (
                        <div className="form-check px-4" key={option}>
                          <input
                            type="radio"
                            name={field.name}
                            value={option}
                            checked={formData[field.name] === option}
                            onChange={handleInputChange}
                            className="form-check-input"
                          />
                          {option}
                          <label
                            className="form-check-label"
                            key={option}
                          ></label>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="text-input ps-2"
                    />
                  </div>
                );
              }
            })}
          </div>
        ))}
      </div>

      {currentSlide === slides.length - 1 && (
        <button type="submit" className="submit-button" onClick={handleSubmit}>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span>Join Now</span>
          )}
        </button>
      )}
      {currentSlide < slides.length - 1 && (
        <button className="next-button" onClick={handleNextSlide}>
          Move on
        </button>
      )}
      <ToastContainer transition={Zoom}></ToastContainer>

      <TutorSuccess show={successModal} handleClose={closesuccessModal} />
    </div>
  );
};

export default TutorSetupForm;

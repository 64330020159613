import React from "react";
import TutorSidebar from "../TutorSidebar";
import LogoutModal from "../../../components/LogoutModal";
import TutorHeader from "../TutorHeader";
import MobileTutorNav from "../../../components/MobileTutorNav";
import { ToastContainer, Zoom } from "react-toastify";
import TutorUploadTable from "./TutorUploadTable";

const TutorUploadPage = () => {
  return (
    <section className="container-fluid p-0">
      <TutorSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 tutorTutorial">
        <TutorHeader />
        <TutorUploadTable />
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <MobileTutorNav />
    </section>
  );
};

export default TutorUploadPage;

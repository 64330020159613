import { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const PayoutsTable = ({ payoutDetail }) => {
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [loading, setLoading] = useState(false);

  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = payoutDetail.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const approve = async (id, status) => {
    setLoading(true);
    try {
      const response = await axios.post(`AcceptPaymentRequest/${id}`, {
        status: status,
      });
      const resp = response.data.data;
      if (resp.status === "success") {
        toast.success(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
      } else {
        toast.warning(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid shadow p-3 p-md-3 rounded">
      <h4 className="inter-24-400">Tutor's Payouts</h4>
      <div className="table-responsive p-2">
        <table className="table table-hover ">
          <thead>
            <tr className="resource-head">
              <th scope="col" className="py-3">
                S/N
              </th>
              <th scope="col" className="py-3">
                Tutor's Name
              </th>
              <th scope="col" className="py-3">
                Tutor School
              </th>
              <th scope="col" className="py-3">
                Payout Amount
              </th>
              <th scope="col" className="py-3">
                Status
              </th>
              <th scope="col" className="py-3"></th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index} className="table-row">
                <th>{index + 1}</th>
                <td>{item.tutorDetails.fullname}</td>
                <td>{item.tutorDetails.school}</td>
                <td>{item.amount}</td>
                <td>{item.status}</td>
                <td>
                  <div className="dropdown">
                    <i
                      className="bi bi-three-dots"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></i>
                    <ul className="dropdown-menu">
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => approve(item.tutorid, "Accepted")}
                          disabled={loading}
                        >
                          Accept
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm ms-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </li>
                      <li>
                        <span className="dropdown-item">Decline</span>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(payoutDetail.length / itemsPerPage) },
          (_, index) => (
            <button
              key={index}
              className={`btn pagination-button mx-1 ${
                currentPage === index + 1
                  ? "btn-primary active"
                  : "btn-secondary"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default PayoutsTable;

import React, { useState } from "react";
import DashboardSidebar from "../pages/UserDashboard/DashboardSidebar";
import DashboardHeader from "../pages/UserDashboard/DashboardHeader";
import LogoutModal from "./LogoutModal";
import MobileNav from "./MobileNav";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../api/axios";

const Profile = () => {
  const userID = sessionStorage.getItem("userID");
  const initialProfile = {
    username: sessionStorage.getItem("username"),
    nickname: sessionStorage.getItem("nickname"),
    useremail: sessionStorage.getItem("userEmail"),
    userPhone: sessionStorage.getItem("userPhone"),
    userPic: sessionStorage.getItem("userPic"),
    userRef: sessionStorage.getItem("userRef"),
  };

  const [profile, setProfile] = useState(initialProfile);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [buttonText, setButtonText] = useState("Copy Link");

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSaveProfile = () => {
    const formData = new FormData();
    formData.append("NickName", profile.nickname);
    formData.append("PhoneNumber", profile.userPhone);

    if (profilePicFile) {
      formData.append("ProfilePic", profilePicFile);
    } else {
      formData.append("ProfilePic", profile.userPic);
    }

    setSaving(true);

    axios
      .post(`UpdateProfile/${userID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp.message);
          toast.success("Profile Updated", {
            theme: "colored",
            autoClose: 2000,
          });
          setProfile({
            ...profile,
            userPic: resp.UserRecord.ProfilePicName,
            nickname: resp.UserRecord.NickName,
            userPhone: resp.UserRecord.PhoneNumber,
          });
          setProfilePicFile(null);
          setSaving(false);
          setEditMode(false);
        } else {
          toast.error(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setSaving(false);
        }
      })
      .catch((err) => {
        toast.error("Edit failed: " + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setSaving(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfilePicChange = (e) => {
    setProfilePicFile(e.target.files[0]);
  };

  const handleCopyLink = () => {
    const referralLink = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    const predefinedText = `Hey! 👋 Boost your grades with ETC Vibes. Get personalized study resources and even earn by referring friends, sharing past questions, notes, and tutoring! Use my referral code and join now! \n\n${referralLink}`;
    
    // Create a temporary textarea to hold the text
    const tempInput = document.createElement('textarea');
    tempInput.value = predefinedText;
    document.body.appendChild(tempInput);
    
    // Select the text in the temporary textarea
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices
    
    // Copy the text
    document.execCommand('copy');
    
    // Remove the temporary textarea
    document.body.removeChild(tempInput);

    // Show a success message and reset after some time
    setButtonText("Link Copied!");
    setTimeout(() => {
      setButtonText("Copy Link");
    }, 3000);
  };

  const generateWhatsAppLink = () => {
    const message = `Join ETC Vibes using my referral code: ${profile.userRef}. Sign up here: https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://wa.me/?text=${encodeURIComponent(message)}`;
  };

  const generateFacebookLink = () => {
    const url = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
  };

  const generateTwitterLink = () => {
    const message = `Join ETC Vibes using my referral code: ${profile.userRef}. Sign up here:`;
    const url = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&url=${encodeURIComponent(url)}`;
  };

  const generateLinkedInLink = () => {
    const url = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
  };

  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 userDashboard">
        <DashboardHeader />
        <div className="p-4">
          <h2 className="viga-24-400">My Profile</h2>
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col">
              <div className="mb-3">
                <img
                  src={profile.userPic}
                  className="profile-pic-lg mb-2"
                  alt="Profile pic"
                />
                {editMode && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfilePicChange}
                    className="form-control w-50"
                  />
                )}
              </div>
            </div>
            <div className="col">
              <div className="profile-info row row-cols-1 row-cols-md-2">
                <div className="col">
                  {/* Full Name */}
                  <div className="d-flex mb-2 me-2 me-md-5">
                    <i className="bi bi-person-circle profile-grey me-2"></i>
                    <div className="d-block">
                      <p className="profile-grey m-0">Full Name</p>
                      <span className="profile-name lato-20-600">
                        {profile.username}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col">
                  {/* Email */}
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope profile-grey me-2"></i>
                    <div className="d-block">
                      <p className="profile-grey m-0">Email Address</p>
                      <span className="profile-name lato-20-600">
                        {profile.useremail}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col">
                  {/* Phone Number */}
                  <div className="d-flex mb-2 me-2 me-md-5">
                    <i className="bi bi-telephone profile-grey me-2"></i>
                    <div className="d-block">
                      <p className="profile-grey m-0">Phone Number</p>
                      {editMode ? (
                        <input
                          type="text"
                          name="userPhone"
                          className="form-control"
                          value={profile.userPhone}
                          onChange={handleChange}
                        />
                      ) : (
                        <span className="profile-name lato-20-600">
                          {profile.userPhone}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col">
                  {/* Nickname */}
                  <div className="d-flex mb-3">
                    <i className="bi bi-person-circle profile-grey me-2"></i>
                    <div className="d-block">
                      <p className="profile-grey m-0">Nickname</p>
                      {editMode ? (
                        <input
                          type="text"
                          name="nickname"
                          className="form-control"
                          value={profile.nickname}
                          onChange={handleChange}
                        />
                      ) : (
                        <span className="profile-name lato-20-600">
                          {profile.nickname}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col">
                  {/* Referral ID */}
                  <div className="d-flex mb-2 me-2 me-md-5">
                    <i className="bi bi-person-circle profile-grey me-2"></i>
                    <div className="d-block">
                      <p className="profile-grey m-0">Referral ID</p>
                      <span className="profile-name lato-20-600">
                        {profile.userRef}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {editMode ? (
                  <>
                    <button
                      className="blue-filled-btn ms-auto"
                      onClick={handleSaveProfile}
                      disabled={saving}
                    >
                      {saving ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></span>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className="grey-btn ms-2"
                      onClick={handleToggleEditMode}
                      disabled={saving}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="blue-filled-btn ms-auto"
                    onClick={handleToggleEditMode}
                  >
                    Edit Profile
                  </button>
                )}
              </div>
            </div>
          </div>
          <hr />
          {/* Referral Container */}
          <div className="card rounded shadow p-3 border-1 referral-container">
            <h4 className="viga-24-400">Refer a Friend and get rewarded!</h4>
            <p className="lato-20-400 text-grey">
              Share your referral link with your friends. <br /> You can also copy the Referral ID at the top.
            </p>
            <div className="referal-search">
              <input
                type="text"
                disabled
                className="referal-input-bar"
                placeholder={`https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`}
              />
              <button
                onClick={handleCopyLink}
                className="blue-filled-btn ms-auto"
              >
                {buttonText}
              </button>
            </div>
            <p className="text-grey lato-20-400">Share your code via</p>
            <div className="d-flex referral-share-icons">
              <a
                href={generateWhatsAppLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="bi bi-whatsapp pe-3 fs-3"
                  style={{ color: "grey" }}
                ></i>
              </a>
              <a
                href={generateFacebookLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="bi bi-facebook pe-3 fs-3"
                  style={{ color: "grey" }}
                ></i>
              </a>
              <a
                href={generateLinkedInLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="bi bi-linkedin pe-3 fs-3"
                  style={{ color: "grey" }}
                ></i>
              </a>
              <a
                href={generateTwitterLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="bi bi-twitter-x pe-3 fs-3"
                  style={{ color: "grey" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <MobileNav />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default Profile;

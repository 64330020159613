import underr from "../../assets/Vector 1.png";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import flash from "../../assets/flash-card.png";
import notes from "../../assets/notes.png";
import quizz from "../../assets/quiz.png";
import question from "../../assets/question.png";

const Newhomemat = () => {
  return (
    <div className="materials">
      <div className="vectt">
        <h1>Curated Study Materials</h1>
        <img src={underr} alt="" />
      </div>
      <h3>
        Access a vast repository of meticulously curated study materials, from
        lecture notes to practice exams
      </h3>
      <div className="matcard">
        <Card id="mater" style={{ width: "290px" }}>
          <Card.Body>
            <div className="ball1">
              <img src={quizz} alt="" />
            </div>
            <Card.Title className="ccdd">Quizzes</Card.Title>
            <Card.Text className="cardbodyww">
              Test your knowledge and track your progress with our engaging
              quizzes tailored to your learning needs.
            </Card.Text>
            <Link to="/login">
              <Button className="matbut" variant="white">
                Start Learning
              </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card id="mater" style={{ width: "290px" }}>
          <Card.Body>
            <div className="ball2">
              <img src={flash} alt="" />
            </div>
            <Card.Title className="ccdd">Flashcards</Card.Title>
            <Card.Text className="cardbodyww">
              Master concepts effortlessly with our interactive flashcards,
              making revision both fun and effective.
            </Card.Text>
            <Link to="/login">
              <Button className="matbut" variant="white">
                Start Learning
              </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card id="mater" style={{ width: "290px" }}>
          <Card.Body>
            <div className="ball">
              <img src={question} alt="" />
            </div>
            <Card.Title className="ccdd">Past questions</Card.Title>
            <Card.Text className="cardbodyww">
              Gain insight into exam patterns and boost confidence with our
              extensive collection of past questions...
            </Card.Text>
            <Link to="/login">
              <Button className="matbut" variant="white">
                Start Learning
              </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card id="mater" style={{ width: "290px" }}>
          <Card.Body>
            <div className="ball3">
              <img src={notes} alt="" />
            </div>
            <Card.Title className="ccdd">Notes</Card.Title>
            <Card.Text className="cardbodyww">
              Access concise and informative notes to complement your studies,
              ensuring clarity and deeper understanding
            </Card.Text>
            <Link to="/login">
              <Button className="matbut" variant="white">
                Start Learning
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Newhomemat;

import { Modal, ModalBody } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useState } from "react";
import InsufficientWalletTutorial from "./InsufficientWalletTutorial";
const PayInfoModal = ({
  show,
  handleClose,
  price,
  selectedTutorial,
  reloadData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userId = sessionStorage.getItem("userID");
  const [PayModal, setPayModal] = useState(false);

  const closePayModal = () => {
    setPayModal(false);
  };
  const payTutorial = () => {
    setIsLoading(true);
    const obj = {
      UserId: userId,
      Amount: price,
      RequestTutorialID: selectedTutorial,
    };
    console.log(obj);
    axios
      .post("TutorialPaymentWallet", obj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        let resp = response.data.data;
        console.log(resp);
        if (resp.status === "success") {
          toast.success("Payment Successful", {
            theme: "colored",
            autoClose: 2000,
          });
          reloadData();
          handleClose();
        } else {
          // toast.warning(resp.message, {
          //   theme: "colored",
          //   autoClose: 2000,
          // });
          setPayModal(true);
          // handleClose();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          theme: "colored",
          autoClose: 2000,
        });
        handleClose();
      });
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="p-5"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="container">
          <p className="lato-16-300">
            You are about to use {price} Naira from your wallet to access this
            tutorial
          </p>
        </div>
        <div className="modal-buttons">
          <div className="d-flex justify-content-center">
            <button className="mx-3 readmore-pay" onClick={payTutorial}>
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <>Continue</>
              )}
            </button>
            <button
              className="mx-3 readmore-cancel"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <InsufficientWalletTutorial
          price={price}
          tutorialID={selectedTutorial}
          handleClose={closePayModal}
          show={PayModal}
          closeInfoModal={handleClose}
        />
      </ModalBody>
    </Modal>
  );
};

export default PayInfoModal;

import { usePaystackPayment } from "react-paystack";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../../../api/axios";

const PaystackHookExample = ({ amount, onSuccess, onClose, payerEmail }) => {
  const config = {
    reference: new Date().getTime().toString(),
    email: payerEmail,
    amount: amount,
    publicKey: "pk_live_29a0607b07f08022b021f64390d0da478954cdc8",
  };
  const initializePayment = usePaystackPayment(config);
  return (
    <div className="m-auto">
      <button
        type="submit"
        className="blue-outline-btn "
        onClick={() => {
          initializePayment(onSuccess, onClose);
        }}
      >
        Pay from Bank Account
      </button>
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};
const BankTutorialPayment = ({
  amount,
  handleClose,
  payerEmail,
  tutorialID,
}) => {
  const onSuccess = (reference) => {
    toast.success("Payment successful", {
      theme: "colored",
      autoClose: 3000,
    });
    const ref = reference.reference;
    const userId = sessionStorage.getItem("userID");
    // const userEmail = sessionStorage.getItem("userEmail");
    // const userName = sessionStorage.getItem("username");
    const obj = {
      tutorialID: tutorialID,
      userid: userId,
      paymentReference: ref,
      amount: "amount",
    };
    console.log(obj);
    axios.post("TutorialPaymentPaystack", obj);
    toast("Payment Successful");
    handleClose();
  };
  const onClose = () => {
    handleClose();
  };

  return (
    <PaystackHookExample
      amount={amount}
      onSuccess={onSuccess}
      onClose={onClose}
      payerEmail={payerEmail}
    />
  );
};

export default BankTutorialPayment;

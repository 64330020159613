import React from "react";
import col from "../../assets/Coll.png";
import plane from "../../assets/plane-departure.png";
import vec from "../../assets/Vector.png";
import diamond from "../../assets/diamond.png";
import heart from "../../assets/heart-circle-sharp.png";
import school from "../../assets/school.png";
import { AnimateOnScroll } from "animate-on-scroll-framer";

const Newcomm = () => {
  return (
    <div>
      <div className="newcomm">
        <div className="newcommpic">
          <AnimateOnScroll duration={2} animation="flipIn">
            <img src={col} alt="" />
          </AnimateOnScroll>
        </div>
        <div className="newcommt">
          <div className="ttee">
            <h1>Join our Community today!</h1>
            <img src={plane} alt="" />
          </div>
          <h5>
            We have a Community of students and professional groups to network
            and collaborate with for better understanding and studying!
          </h5>
          <a
            target="blank"
            href="https://chat.whatsapp.com/GOcl0qcR102FGcy9PmlvZZ"
          >
            Explore teachers and students <img src={vec} alt="" />
          </a>
        </div>
      </div>
      <div className="evidence">
        <div className="frameevi">
          <div className="fram1">
            <img src={heart} alt="" />
            <h1>3+</h1>
            <h3>Universities</h3>
          </div>
          <div className="fram1">
            <img src={diamond} alt="" />
            <h1>40+</h1>
            <h3>Valued Teachers</h3>
          </div>
          <div className="fram1">
            <img src={school} alt="" />
            <h1>1,500+</h1>
            <h3>Happy Students</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newcomm;

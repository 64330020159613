import React from "react";
import underr from "../../assets/Vector 1.png";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import students from "../../assets/Group 36639.png";
import card11 from "../../assets/Rectangle 2749.png";
import card12 from "../../assets/Rectangle 2749 (1).png";
import { Link } from "react-router-dom";
import acct from "../../assets/8878499.jpg";
import masscom from "../../assets/9616776.jpg";
import { AnimateOnScroll } from "animate-on-scroll-framer";

const Newhomeexp = () => {
  return (
    <div id="tutorial" className="personlaized">
      <div className="vectt">
        <h1>Personalized Learning Experience</h1>
        <img src={underr} alt="" />
      </div>
      <h3>
        Experience a dynamic and engaging learning journey tailored to your
        unique needs and courses
      </h3>
      {/* <div className="coursebut">
        <Button variant="primary">Computer Sci.</Button>
        <Button variant="primary">Agri Edu.</Button>
        <Button variant="primary">Chemistry Sci.</Button>
        <Button variant="primary">English</Button>
        <Button variant="primary">Physics</Button>
        <Button variant="primary">Zoology</Button>
      </div> */}
      <div className="coursecard">
        <Card id="cardd" style={{ width: "296px", minHeight: "500px" }}>
          <AnimateOnScroll duration={2} animation="flipIn">
            <Card.Img variant="top" src={masscom} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 234 students</h5> */}
              </div>
              {/* <h6>500 Study resources</h6> */}
              <Card.Title className="ccd">Mass Communication</Card.Title>
              <Card.Text className="cardbody">
                Dive into the dynamic world of media and communication with our
                Mass Communication course on ETC Vibes. From journalism to
                digital media, explore various facets of mass communication and
                develop essential skills for the industry. Join us to unlock
                your potential in this exciting field!
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </AnimateOnScroll>
        </Card>
        <Card id="cardd" style={{ width: "296px", minHeight: "500px" }}>
          <AnimateOnScroll duration={2} animation="fadeIn">
            <Card.Img variant="top" src={card11} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 40 students</h5> */}
              </div>
              {/* <h6>300 Study resources</h6> */}
              <Card.Title className="ccd">Computer Science</Card.Title>
              <Card.Text className="cardbody">
                Dive into the world of technology with our Computer Science
                course on ETC Vibes. Learn programming, algorithms, and software
                development with expert guidance. Whether you're a beginner or
                advanced coder, our curriculum suits all levels. <br /> Join us
                and unlock a world of opportunities in computer science!
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </AnimateOnScroll>
        </Card>
        <Card id="cardd" style={{ width: "296px", minHeight: "500px" }}>
          <AnimateOnScroll duration={2} animation="fadeIn">
            <Card.Img variant="top" src={card12} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 11 students</h5> */}
              </div>
              {/* <h6>200 Study resources</h6> */}
              <Card.Title className="ccd">English</Card.Title>
              <Card.Text className="cardbody">
                Explore the richness of language and literature with our English
                course on ETC Vibes. From grammar essentials to literary
                analysis, our course covers it all. Enhance your communication
                skills and delve into the fascinating world of English
                literature. <br /> Join us and embark on a journey of discovery
                and expression!
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </AnimateOnScroll>
        </Card>
        <Card id="cardd" style={{ width: "296px", minHeight: "500px" }}>
          <AnimateOnScroll duration={2} animation="flipIn">
            <Card.Img variant="top" src={acct} />
            <Card.Body>
              <div className="students">
                <img src={students} alt="" />
                {/* <h5>+ 342 students</h5> */}
              </div>
              {/* <h6>1000 Study resources</h6> */}
              <Card.Title className="ccd">Accounting</Card.Title>
              <Card.Text className="cardbody">
                Master the language of business with our Accounting course on
                ETC Vibes. Explore financial analysis and practical accounting
                techniques to excel in finance. Whether you're a professional or
                an aspiring enthusiast, our course offers valuable insights to
                navigate the world of commerce confidently. Join us and elevate
                your financial expertise
              </Card.Text>
              <Link to="/signup">
                <Button variant="primary" className="cardbut">
                  Start Learning
                </Button>
              </Link>
            </Card.Body>
          </AnimateOnScroll>
        </Card>
      </div>
    </div>
  );
};

export default Newhomeexp;

import amico from "../../../assets/amico.png";
import { PlanActivity } from "./PlanActivity";
import BuyCoinsModal from "./BuyCoinsModal";
import WalletOperations from "./WalletOperations";
import { useEffect, useCallback } from "react";
import axios from "../../../api/axios";
import { useState } from "react";
const WalletComponent = () => {
  const userID = sessionStorage.getItem("userID");
  const [walletBalance, setWalletBalance] = useState("");
  const [coinBalance, setCoinBalance] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState("");

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`wallet_balance/${userID}`);
      const userdata = response.data.data.messages;
      const wallet_balance = userdata.Wallet;
      const coin_balance = userdata.Coin;
      sessionStorage.setItem("coin", userdata.Coin);
      sessionStorage.setItem("userAutoRenew", userdata.AutoRenew);
      setWalletBalance(wallet_balance);
      setCoinBalance(coin_balance);
      setUserPlan(userdata.Subscription);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  }, [userID]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Callback function to reload wallet data
  const reloadWalletData = useCallback(() => {
    fetchUserData();
  }, [fetchUserData]);

  const formattedWalletBalance = loading ? (
    <span className="spinner-border spinner-border-sm" role="status"></span>
  ) : walletBalance !== null ? (
    parseFloat(walletBalance).toLocaleString("en-US")
  ) : (
    "0.00"
  );

  return (
    <div className="wallet-container container-fluid p-3 mb-5 mb-md-2 mb-lg-0">
      <div className="wallet-header mb-5">
        <div className="container px-0">
          <div className="balance-card d-flex align-items-center">
            <div className="d-block text-center">
              <h2 className="balance-number">
                ₦
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                ) : (
                  formattedWalletBalance
                )}
              </h2>
              <span className="lato-24-400 text-white">Available Balance</span>
            </div>
            <div className="m-auto wallet-img">
              <img src={amico} alt="" />
            </div>

            <div className="d-block text-center">
              <h2 className="balance-number">
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                ) : (
                  coinBalance
                )}
              </h2>
              <span className="lato-24-400 text-white">Coin Balance</span>
            </div>
          </div>
        </div>
      </div>
      <WalletOperations
        userPlan={userPlan}
        reloadWalletData={reloadWalletData}
      />
      <PlanActivity />
      <BuyCoinsModal />
    </div>
  );
};

export default WalletComponent;

import { Link } from "react-router-dom";
import tutorialPoster from "../../../assets/img/tutImg.svg";
import Textbook from "../../UserDashboard/Dashboard/Textbook.svg";
import notes from "../../UserDashboard/Dashboard/notes.svg";
import TutorReadMore from "../TutorReadMore";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { Pagination } from "react-bootstrap";

const TutorCards = () => {
  const tutorID = sessionStorage.getItem("tutorId");
  const [loading, setLoading] = useState(true);
  // const [upcomingTut, setUpcomingTut] = useState([]);
  const [RequestedTut, setRequestedTut] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`Dashboard/${tutorID}`);
        const resp = response.data.data;
        const currentDateTime = new Date();

        // Filter tutorials with tt_time past the current date
        const filteredTutorials = resp.CreatedTutorial.filter(
          (tutorial) => new Date(tutorial.tt_time) > currentDateTime
        );

        setRequestedTut(filteredTutorials);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [tutorID]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };

  const CARDS_PER_PAGE = 6;

  const [activePage, setActivePage] = useState(1);

  const totalPages = Math.ceil(RequestedTut.length / CARDS_PER_PAGE);

  const handleClick = (event) => {
    const targetPage = Number(event.target.text);
    setActivePage(targetPage);
  };
  const startIndex = (activePage - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;
  const visibleItems = RequestedTut.slice(startIndex, endIndex);

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === activePage} onClick={handleClick}>
        {i}
      </Pagination.Item>
    );
  }
  return (
    <section className="container-fluid">
      {loading ? (
        <span
          className="spinner-border spinner-border-lg text-blue"
          role="status"
        ></span>
      ) : RequestedTut === "you no tutorial request or created available" ? (
        <div className="container mb-5">
          <p>No upcoming Tutorials</p>
        </div>
      ) : RequestedTut.length === 0 ? (
        <div className="container mb-5">
          <p>No upcoming Tutorials</p>
        </div>
      ) : (
        <section>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 g-4">
            {visibleItems.map((tutorial) => (
              <div
                className="col d-flex justify-content-center"
                key={tutorial.id}
              >
                <div className="tutorial-card-container d-block p-3 mb-3">
                  <div className="card border-0">
                    <div className="d-block">
                      <div className="d-flex align-items-center my-2">
                        <span className="status-upcoming">Upcoming</span>
                        <span className="lato-8-400 ms-auto">
                          {formatDateTime(tutorial.tt_time)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <h5 className="lato-12-700">{tutorial.tt_title}</h5>
                      </div>
                      <p className="font-12-400 ">{tutorial.tt_course}</p>
                      {/* <Link to={`${tutorial.meeting_link}`}>
                        <button className="blue-filled-btn mb-3">
                          Join Tutorial
                        </button>
                      </Link> */}
                      <a href={`${tutorial.meeting_link}`}>
                        <button className="blue-filled-btn mb-3">
                          Join Tutorial
                        </button>
                      </a>
                      {/* <Link
                        to={`/tutortutorials/livetutorial/${tutorial.meeting_link}`}
                      >
                        <button className="blue-filled-btn mb-3">
                          Join Tutorial
                        </button>
                      </Link> */}
                      {/* <a href={tutorial.join_meeting_link}>Join</a> */}
                    </div>
                    <img
                      src={tutorialPoster}
                      alt="tutorial-icon"
                      className="card-img tutorial-card-img"
                    />
                  </div>
                </div>
              </div>
            ))}
            <TutorReadMore />
          </div>
          <div className="card-pagination d-flex">
            <Pagination className="ms-auto me-4">{paginationItems}</Pagination>
          </div>
        </section>
      )}
      <div className="tutor-recent-activities bg-white mb-5">
        <div className="tutor-activites-card p-3 bg-white mb-4">
          <div className="d-flex">
            <h2 className="viga-24-400 text-grey p-2">Recent Activities</h2>
            <Link className="lato-12-400 ms-auto">
              October, 2022
              <i className="bi bi-caret-down-fill"></i>
            </Link>
          </div>
          <div className="row row-cols-2 row-cols-md-2 p-3">
            <div className="col d-flex mb-3">
              <img src={Textbook} alt="" className="activity-icon" />
              <div className="activity-detail">
                <Link className="font-16-600 activity-link">
                  Introduction to Programming General
                </Link>
                <br />
                <span className="font-10-300">04 Oct, 09:20AM</span>
              </div>
            </div>
            <div className="col d-flex mb-3">
              <img src={notes} alt="" className=" activity-icon" />
              <div className="activity-detail">
                <Link className="font-16-600 activity-link">
                  Periodic Table Notes
                </Link>
                <br />
                <span className="font-10-300">04 Oct, 09:20AM</span>
              </div>
            </div>
            <div className="col d-flex mb-3">
              <img src={Textbook} alt="" className="activity-icon" />
              <div className="activity-detail">
                <Link className="font-16-600 activity-link">
                  Introduction to Programming General
                </Link>
                <br />
                <span className="font-10-300">04 Oct, 09:20AM</span>
              </div>
            </div>
            <div className="col d-flex mb-3">
              <img src={notes} alt="" className="activity-icon" />
              <div className="activity-detail">
                <Link className="font-16-600 activity-link">
                  Periodic Table Notes
                </Link>
                <br />
                <span className="font-10-300">04 Oct, 09:20AM</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TutorCards;

import React from "react";
import { Link } from "react-router-dom";

const Newhomeplans = () => {
  return (
    <div className="planp">
      <section className="container-fluid">
        <div className="container my-4">
          <h2 className="font-48 text-center">
            Get Started With <span className="logo-name-48">ETC Vibes</span>{" "}
            Today!
          </h2>
          <div className="my-5">
            <h3 className="plantext">
              Choose a flexible Plan and get Started!
            </h3>
          </div>
          <div className="offers">
            <div className="offer1">
              <div className="head p-3">
                <h3 className="p-5 text-primary">Basic</h3>
              </div>
              <div className="text-primary">
                <h1>₦1200</h1>
              </div>
              <div className="">
                <ul className="plan-card-list text-start mx-lg-5">
                  <li className="py-2">1 User Per Account</li>
                  <li className="py-2">Access to 5 course study materials</li>
                  <li className="py-2">Access to ETC AI Vibes</li>
                </ul>
                <div className="mt-1 mt-md-5">
                  <Link to="/signup">
                    <button className="getStarted-btn">Get Started</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="offer2">
              <div className="head p-3">
                <h3 className="p-5 text-primary">Silver</h3>
              </div>
              <div className="text-primary">
                <h1>₦2500</h1>
              </div>
              <div className="card-body px-2 px-md-3">
                <ul className="plan-card-list text-start mx-lg-5">
                  <li className="py-2">1 User Per Account</li>
                  <li className="py-2">Access to 10 course study materials</li>
                  <li className="py-2">Access to ETC AI Vibes</li>
                </ul>
                <div className="mt-1 mt-md-5">
                  <Link to="/signup">
                    <button className="getStarted-btn">Get Started</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newhomeplans;

// import { Button } from "react-bootstrap";
import whiteLogo from "../../assets/whiteLogo.svg";
import { Link, NavLink } from "react-router-dom";
import CoursesModal from "../new home/CoursesModal";
import { useState } from "react";

const BecomeNavbar = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <nav className="navbar navbar-expand-lg navbar-light p-0">
      <CoursesModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="container-fluid p-0">
        <div className="logo-container">
          <img src={whiteLogo} alt="Logo" className="logo" />
        </div>
        <button
          className="navbar-toggler me-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 px-5 ">
            <li className="px-2 my-auto">
              <NavLink
                className={({ isActive }) => (isActive ? "activetab" : "")}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="px-2 my-auto">
              <NavLink
                className={({ isActive }) => (isActive ? "activetab" : "")}
                to="/createAccount/tutor"
              >
                Become a Tutor
              </NavLink>
            </li>
            <li className="px-2 my-auto">
              <a href="#about">About US</a>
            </li>
            <li className="px-2 my-auto">
              {/* <a target="blank" href="https://app.ibloov.com/event/etcvibes"> */}
              <a target="blank" href="https://app.ibloov.com/event/etcvibes_academic_excellence">
                Event
              </a>
            </li>
            <li className="nav-item px-2">
              <Link to="/login" className="nav-link login-btn">
                Log In
              </Link>
            </li>
            <li className="nav-item px-2">
              <Link to="/signup" className="nav-link signup-btn">
                Sign Up
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default BecomeNavbar;

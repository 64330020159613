import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/img/blueLogo.svg";
import { Link } from "react-router-dom";
const UploadAndEarnModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="my-4">
            <img src={Logo} className="logo" alt="" />
          </div>
          <div className="d-block">
            <h3 style={{ color: "#3B3F5C" }}>Upload and Earn!</h3>
            <p>
              📚 Want to earn while you learn? Share your study resources with
              fellow students and earn tokens for your efforts!
            </p>
          </div>
          <Link to="/courses/uploadandearn">
            <button className="blue-filled-btn p-3">
              Upload Study Resources
            </button>
          </Link>
          <div className="d-block mt-4">
            <p>
              🎓 Upload your notes, summaries, flashcards, or any study
              materials you've created. Help your peers ace their exams while
              you earn rewards.
            </p>
            <p>
              💰 Every resource you contribute earns you tokens that can be
              redeemed for amazing rewards. It's a win-win!
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadAndEarnModal;

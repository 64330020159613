import { useState } from "react";
import whiteLogo from "../../../assets/img/whiteLogo.svg";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../api/axios";

const EmailReset = () => {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { email, token } = useParams();
  const navigate = useNavigate();
  const validateForm = () => {
    if (password.trim() === "") {
      toast.warning("Please enter a password", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password !== cpassword) {
      toast.warning("Passwords do not match", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.length < 8) {
      toast.warning("Password should be at least 8 characters long", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      toast.warning(
        "Password should contain at least one letter and one number",
        {
          theme: "colored",
          autoClose: 3000,
        }
      );
      return false;
    }
    return true;
  };
  const handleReset = async (e) => {
    e.preventDefault(e);
    if (validateForm) {
      setLoading(true);
      const obj = {
        password,
        email,
        token,
      };
      try {
        const response = await axios.post("ResetPassword", obj);
        const resp = response.data.data;
        console.log(resp);
        if (resp.status === "success" || resp.status === "Success") {
          toast.success(resp.message, {
            theme: "colored",
            autoClose: "2000",
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          toast.warning(resp.message);
          setLoading(false);
        }
      } catch (error) {
        toast.warning(error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 reset-left">
          <img src={whiteLogo} className="logo ms-5" alt="Logo" />
          <div className="ps-5 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-dark logo-h3">E.T.C Study Vibes</h2>
              <p className="text-dark w-75">
                providing you with everything required to make your striaght As!
                on a single platform..
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-white p-3 d-flex">
          <div className="container form-container-lg px-3 m-auto">
            <h3 className="viga-24-400 text-blue my-4">Reset Password</h3>
            <span className="span-black-400 fw-bold">Enter new password</span>
            <form action="" className="login-form" onSubmit={handleReset}>
              <div className="my-3 d-flex">
                <input
                  type="password"
                  name="newpassword"
                  placeholder="New Password"
                  className="login-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="my-3 d-flex">
                <input
                  type="password"
                  name="newpassword"
                  placeholder="Confirm New Password"
                  className="login-input"
                  value={cpassword}
                  onChange={(e) => setCPassword(e.target.value)}
                />
              </div>

              <div className="mt-5">
                <button type="submit" className="submit-btn">
                  {loading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    <>Reset Password</>
                  )}
                </button>
              </div>
              <ToastContainer transition={Zoom}></ToastContainer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailReset;

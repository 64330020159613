import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ai from "../../assets/aibot.jpg";
import search from "../../assets/search-normal.png";
import check from "../../assets/Check icon.png";
import { Link } from "react-router-dom";

const AIModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="grid-example">
        <div className="aimodal">
          <div className="aihead">
            <img style={{ width: "80px" }} src={ai} alt="" />
            <h3>AI Vibes</h3>
          </div>
          <div className="searchai">
            <input type="text" disabled placeholder="Search anything..." />
            <img src={search} alt="" />
          </div>
          <div className="sign">
            <div className="signcard">
              <img src={check} alt="" />
              <div className="text">
                <h3>You don't have an account on ETC Vibes?</h3>
                <h5>Sign up to have unlimited access!</h5>
              </div>
            </div>
            <Link to="/signup">
              <Button>Sign Up</Button>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AIModal;

import { useEffect, useState } from "react";
import axios from "../../../api/axios";

const WalletActivities = () => {
  const tutorID = sessionStorage.getItem("tutorId");
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`TutorTransaction/${tutorID}`);
        const resp = response.data.data;
        if (resp.status === "success") {
          const userActivity = resp.message;
          setActivities(userActivity);
        } else if (resp.status === "failed") {
          setActivities([]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchUserData();
  }, [tutorID]);
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleString("en-US", options)}`;
  };
  return (
    <div className="container-fluid tutor-wallet-activity mt-5 p-1 p-md-4">
      <h3 className="lato-24-600">Recent Activities</h3>
      {loading ? (
        <span className="spinner-border spinner-border-lg" role="status"></span>
      ) : (
        <div className="d-block">
          {activities.length === 0 ? ( // Check if activities array is empty
            <p className="lato-20-400 text-blue">No transactions</p>
          ) : (
            <div className="row row-cols-1">
              {activities.map((activity) => (
                <div className="col" key={activity.id}>
                  <div className="tutor-activity-box">
                    <div className="d-flex" id={activity.type}>
                      <span className="lato-24-800">{activity.type}</span>
                      <span className="lato-24-800 ms-auto">
                        {activity.amount}
                      </span>
                    </div>
                    <span className="lato-24-400">{activity.desc}</span> <br />
                    <span className="lato-14-400">
                      {formatDateTime(activity.date)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default WalletActivities;

import whiteLogo from "../../assets/img/whiteLogo.svg";
import logout from "../../assets/img/logoutG.png";
import { NavLink } from "react-router-dom";
const TutorSidebar = () => {
  return (
    <div className="tutor-sidebar">
      <div className="sidebar-logo">
        <img src={whiteLogo} alt="Logo" />
      </div>
      <div className="navbar">
        <NavLink
          to="/tutor"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-grid-fill nav-icon"></i>
            Dashboard
          </div>
        </NavLink>
        <NavLink
          to="/tutortutorials"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-people nav-icon"></i>
            Tutorials
          </div>
        </NavLink>
        <NavLink
          to="/tutorwallet"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-building nav-icon"></i>
            Wallet
          </div>
        </NavLink>
        <NavLink
          to="/tutornotifications"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-bell nav-icon"></i>
            Notifications
          </div>
        </NavLink>
        <NavLink
          to="/tutorupload"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-cloud-arrow-up nav-icon"></i>
            Upload
          </div>
        </NavLink>
      </div>
      <div className="tutor-sidebar-tools">
        <nav className="navbar">
          <NavLink
            to="/tutorprofile"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-person-circle nav-icon"></i>
              Profile
            </div>
          </NavLink>
          <NavLink
            to="/tutorhelp"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-question-circle nav-icon"></i>
              Help
            </div>
          </NavLink>
          <NavLink
            to="#"
            // className={({ isActive }) =>
            //   isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            // }
          >
            <div
              className="nav-item d-flex"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <img src={logout} className="px-3" alt="" />
              Logout
            </div>
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default TutorSidebar;

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const QuizInfo = ({ show, handleClose, openQuizModal }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h3>Rules of this Quiz</h3>
        <div className="instructions">
          <ol>
            <li>You will have only 15 seconds per question</li>
            <li>Once you select the answer it can't be undone.</li>
            <li>You can't select two options at once.</li>
            <li>You'll get points on the basis of your correct answers.</li>
          </ol>
        </div>
        <div className="buttons d-flex align-items-center">
          <span
            className="ms-auto me-3 text-blue"
            onClick={() => {
              handleClose();
            }}
            role="button"
          >
            Exit
          </span>
          <Link to="#">
            <button
              className="mx-2 blue-filled-btn"
              onClick={() => {
                handleClose();
                openQuizModal();
              }}
            >
              Continue
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuizInfo;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
const StudentsTable = () => {
  const itemsPerPage = 15; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [TableData, setTableData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    const getTutors = async () => {
      try {
        const response = await axios.get("showallstudent");
        const studentdata = response.data.data.message;
        setTableData(studentdata);
        setloading(false);
      } catch (error) {
        toast.error(error);
        setloading(false);
      }
    };
    getTutors();
  }, []);
  const formatDateString = (dateString) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };
  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = TableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();
  const handleDetails = (id) => {
    navigate(`/adminstudent/details/${id}`);
  };
  return (
    <div className="container-fluid p-0">
      <div className="container-fluid">
        <h4 className="viga-24-400 text-blue">All Students</h4>
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  ID
                </th>
                <th scope="col" className="py-3">
                  Student Name
                </th>
                <th scope="col" className="py-3">
                  Department
                </th>
                <th scope="col" className="py-3">
                  Subcription Plan
                </th>
                <th scope="col" className="py-3">
                  School
                </th>
                <th scope="col" className="py-3">
                  Join Date
                </th>
              </tr>
            </thead>
            {loading ? (
              <div
                className="spinner-border spinner-border-lg text-primary"
                role="status"
              ></div>
            ) : (
              <tbody>
                {currentItems.map((item) => (
                  <tr
                    key={item.id}
                    className="table-row"
                    onClick={() => handleDetails(item.id)}
                  >
                    <td>{item.id}</td>
                    <td>{item.Fullname}</td>
                    <td>{item.Program}</td>
                    <td>{item.Subscription}</td>
                    <td>{item.School}</td>
                    <td>{formatDateString(item.Verification_at)}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(TableData.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                className={`btn pagination-button mx-1 ${
                  currentPage === index + 1
                    ? "btn-primary active"
                    : "btn-secondary"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentsTable;

import { useState } from "react";
import AllCourses from "./AllCourses";
import MyCourse from "./MyCourse";
import FreeCourse from "./FreeCourse";
const CourseContainer = () => {
  const [isAllCourseActive, setAllCourseActive] = useState(false);
  const [isMyCourseActive, setMyCourseActive] = useState(true);
  const [isFreeCourseActive, setFreeCourseActive] = useState(false);
  const [isProCourseActive, setProCourseActive] = useState(false);

  const handleMyCourse = () => {
    setAllCourseActive(false);
    setMyCourseActive(true);
    setFreeCourseActive(false);
    setProCourseActive(false);
    setcourseContent(<MyCourse redirect={handleAllCourse} />);
  };
  const handleAllCourse = () => {
    setAllCourseActive(true);
    setMyCourseActive(false);
    setFreeCourseActive(false);
    setProCourseActive(false);
    setcourseContent(<AllCourses />);
  };
  const handleFreeCourse = () => {
    setAllCourseActive(false);
    setMyCourseActive(false);
    setFreeCourseActive(true);
    setProCourseActive(false);
    setcourseContent(<FreeCourse />);
  };
  const handleProCourse = () => {
    setAllCourseActive(false);
    setMyCourseActive(false);
    setFreeCourseActive(false);
    setProCourseActive(true);
    setcourseContent("");
  };

  const [courseContent, setcourseContent] = useState(
    <MyCourse redirect={handleAllCourse} />
  );

  const allCourseBtnClass = isAllCourseActive
    ? "course-btn-active"
    : "course-btn-inactive";
  const myCourseBtnClass = isMyCourseActive
    ? "course-btn-active"
    : "course-btn-inactive";
  const freeCourseBtnClass = isFreeCourseActive
    ? "course-btn-active"
    : "course-btn-inactive";
  const proCourseBtnClass = isProCourseActive
    ? "course-btn-active"
    : "course-btn-inactive";

  // const courseContent = isAllCourseActive ? (
  //   <AllCourses />
  // ) : (
  //   <MyCourse redirect={handleAllCourse} />
  // );

  return (
    <div className="container-fluid course-table bg-white px-2">
      <div className="d-flex align-items-center resource-topnav">
        {/* My course toggle */}
        <button className={myCourseBtnClass} onClick={handleMyCourse}>
          <h3 className="font-24-700 px-2">My Course</h3>
        </button>
        {/* All course toggle */}
        <button className={allCourseBtnClass} onClick={handleAllCourse}>
          <h3 className="font-24-700 px-2">All Courses</h3>
        </button>
        {/* Professional courses toggle */}
        <button className={freeCourseBtnClass} onClick={handleFreeCourse}>
          <h3 className="font-24-700 px-2">Free Courses</h3>
        </button>
        <button className={proCourseBtnClass} onClick={handleProCourse}>
          <h3 className="font-24-700 px-2">Professional Courses</h3>
        </button>
      </div>
      {/* Course Categories */}
      {courseContent}
    </div>
  );
};

export default CourseContainer;

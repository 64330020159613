import { Link } from "react-router-dom";
const ProfileModal = ({ username, userPic }) => {

  return (
    <div className="container">
      <div className="dropdown profile-dropdown d-none d-md-none d-lg-flex">
        <img
          src={userPic}
          alt="Profile"
          className="profile-pic-sm"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />

        {/* Profile Page */}
        <div className="dropdown-menu profile-menu p-4">
          <h2 className="viga-24-400">My Profile</h2>
          <div className="profile-img text-center mb-3">
            {/* <i className="bi bi-person-circle display-1 text-blue" alt="" /> */}
            <img src={userPic} className="profile-pic-sm" alt="Profile" />
          </div>
          <div className="profile-info">
            {/* full name */}
            <div className="d-flex mb-2">
              <i className="bi bi-person-circle profile-grey me-1"></i>
              <div className="d-block ">
                <p className="profile-grey m-0">Full Name</p>
                <span className="profile-name lato-20-600">{username}</span>
              </div>
            </div>
            <div className="d-flex">
              <Link to="/profile">
                <button
                  className="blue-filled-btn ms-auto"
                  // onClick={handleOpenModal}
                >
                  View Profile
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;

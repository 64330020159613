import React from "react";
import { Link } from "react-router-dom";
import testimonial3 from "../assets/testimonial3.png";

const MobileProfile = () => {
  return (
    <div
      className="modal fade"
      id="profileModal"
      aria-labelledby="profileModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-">
        <div className="modal-content">
          <div className="modal-body">
            <div className="container p-3">
              <h2 className="lato-36-600">My Profile</h2>
              <div className="profile-img text-center mb-3">
                <img src={testimonial3} className="w-100" alt="" />
                <span className="userID text-center lato-20-600">
                  User ID: EV 20013
                </span>
              </div>
              <div className="profile-info">
                {/* full name */}
                <div className="d-flex mb-2">
                  <i className="bi bi-person-circle profile-grey me-1"></i>
                  <div className="d-block ">
                    <p className="profile-grey">Full Name</p>
                    <span className="profile-name lato-20-600">
                      Abdulkareem Temitope Shakirah
                    </span>
                  </div>
                </div>
                {/* email */}
                <div className="d-flex mb-2">
                  <i className="bi bi-envelope profile-grey me-1"></i>
                  <div className="d-block">
                    <p className="profile-grey">Email Address</p>
                    <span className="profile-name lato-20-600">
                      Shakirah@etcvibes.com
                    </span>
                  </div>
                </div>
                {/* Phone number */}
                <div className="d-flex mb-2">
                  <i className="bi bi-telephone-fill profile-grey me-1"></i>
                  <div className="d-block">
                    <p className="profile-grey">Phone Number</p>
                    <span className="profile-name lato-20-600">
                      08136144208
                    </span>
                  </div>
                </div>
                {/* Nick name */}
                <div className="d-flex mb-3">
                  <i className="bi bi-person-circle profile-grey me-1"></i>
                  <div className="d-block">
                    <p className="profile-grey">Nick Name</p>
                    <span className="profile-name lato-20-600">Qeerah</span>
                  </div>
                </div>
                <div className="d-flex">
                  <Link className="blue-filled-btn ms-auto">Edit Profile</Link>
                </div>
              </div>
            </div>
            <div className="modal-buttons">
              <div className="d-flex justify-content-center">
                <button className="mx-3 readmore-pay">Continue</button>
                <button
                  className=" mx-3 readmore-cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileProfile;

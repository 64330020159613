import { ToastContainer, Zoom } from "react-toastify";
import Logo from "../../../components/Logo";
import AdminLoginForm from "./AdminLoginForm";
import { Link } from "react-router-dom";

const AdminLogin = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 signup-left">
          <Logo />
          <div className="px-4 mt-auto d-flex justify-content-center">
            <div className="d-block">
              <h2 className="text-white logo-h3">E.T.C Study Vibes</h2>
              <p className="text-white w-75">
                providing you with everything required to make your striaght As!
                on a single platform..
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-white p-3 d-flex">
          <div className="container form-container px-3 m-auto">
            <div className="d-flex justify-content-center d-flex d-md-none">
              <Logo />
            </div>
            <h3 className="my-3 viga-24-400 text-blue login-header">
              Admin Login
            </h3>
            {/* <p className="span-grey">
              Login to Your Account and Keep the Momentum Going
            </p> */}
            <AdminLoginForm />
            <span>
              No account?
              <Link to="/adminsignup" className="blue-span ps-1">
                Signup
              </Link>
            </span>
          </div>
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default AdminLogin;

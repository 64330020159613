import { useEffect } from "react";
import { useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const AdminUniTable = () => {
  const itemsPerPage = 15; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [isloading, setisLoading] = useState(false);
  const [dloading, setdLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setisLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get("University");
        const resp = response.data.data;
        setTableData(resp.message);
        setisLoading(false);
      } catch (error) {
        toast.error(error);
        setisLoading(false);
      }
    };
    fetchData();
  }, []);
  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = tableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const deleteUni = async (uni) => {
    setdLoading(true);
    try {
      const response = await axios.get(`deleteUniversity/${uni}`);
      const resp = response.data.data.message;
      toast(resp);
      setdLoading(false);
    } catch (error) {
      toast.error(error);
      setdLoading(false);
    }
  };

  return (
    <div className="table-responsive p-2">
      <table className="table table-hover">
        <thead>
          <tr className="resource-head">
            <th scope="col" className="py-3">
              S/N
            </th>
            <th scope="col" className="py-3">
              University
            </th>
            <th scope="col" className="py-3"></th>
          </tr>
        </thead>
        {isloading ? (
          <span className="spinner-border spinner-border-lg text-blue"></span>
        ) : (
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id} className="table-row">
                <td>{index + 1}</td>
                <td>{item.sc_name}</td>
                <td>
                  {dloading ? (
                    <span className="spinner-border text-danger lato-14-400"></span>
                  ) : (
                    <i
                      className="bi bi-trash3-fill text-danger"
                      onClick={() => deleteUni(item.id)}
                    ></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {/* Pagination */}
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(tableData.length / itemsPerPage) },
          (_, index) => (
            <button
              key={index}
              className={`btn pagination-button mx-1 ${
                currentPage === index + 1
                  ? "btn-primary active"
                  : "btn-secondary"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default AdminUniTable;

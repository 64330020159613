import { Modal } from "react-bootstrap";
import PlanPayment from "./PlanPayment";
import { Link } from "react-router-dom";

const InsufficientWallet = ({ price, planName, show, handleClose }) => {
  const payerEmail = sessionStorage.getItem("userEmail");

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="p-3">
          <div className="d-block">
            <h3 className="viga-24-400 text-center my-2 text-danger">
              Insufficient Funds!
            </h3>
            <p>
              To continue your learning journey, please ensure you have
              sufficient funds in your wallet or choose to pay from your bank
              account.
            </p>
          </div>
          <div className="d-flex">
            <button className="blue-filled-btn m-auto">
              <Link
                to="/userwallet"
                className="text-decoration-none text-white viga-14-400"
              >
                Add funds to Wallet
              </Link>
            </button>
            <PlanPayment
              amount={price * 100}
              payerEmail={payerEmail}
              planName={planName}
              handleClose={handleClose}
            />
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
      <button onClick={handleFundWalletModalClose}>Close</button>
    </Modal.Footer> */}
    </Modal>
  );
};

export default InsufficientWallet;

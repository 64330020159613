import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const SubscriptionModal = ({ show, handleClose, openFundWalletModal }) => {
  const userId = sessionStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);

  const subCourse = (selectedPrice, selectedPlanName) => {
    setIsLoading(true);
    const obj = {
      plan: selectedPlanName,
      userid: userId,
      amount: `${selectedPrice}`,
    };
    axios
      .post("SubcribeFromWallet", obj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp);
          toast.success(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          handleClose();
        } else {
          toast.warning(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          openFundWalletModal(selectedPrice, selectedPlanName);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          theme: "colored",
          autoClose: 3000,
        });
      });
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-1">
        <div className="subscription-modal">
          <div className="container">
            <p className="lato-24-400 text-center">
              Subscribe to a plan to access all our Study materials
            </p>
            <div className="mb-4">
              <div className="d-flex scrolling-wrapper justify-content-center">
                <div className="basic-card-modal me-4 me-md-2 p-0">
                  <div className="sub-card-header p-3 p-md-5">
                    <h5 className="blue-32-600 text-center">Basic</h5>
                  </div>
                  <div className="card-body text-center p-3 p-md-5">
                    <h2 className="text-white poppins sub-price">₦1200</h2>
                    <p className="text-white">/month</p>
                    <ul className="plan-card-list">
                      <li>1 User Per Account</li>
                      <li>Access to 5 course study materials</li>
                      <li>Access to ETC AI Vibes</li>
                    </ul>

                    <div
                      className="blue-filled-btn mt-1 mt-md-5"
                      role="button"
                      onClick={() => {
                        subCourse(1200, "Basic");
                      }}
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></span>
                      ) : (
                        <>Subscribe</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="silver-card-modal me-4 me-md-2 p-0">
                  <div className="sub-card-header p-3 p-md-5">
                    <h5 className="blue-32-600 text-center">Silver</h5>
                  </div>
                  <div className="card-body text-center p-3 p-md-5">
                    <h2 className="text-white sub-price">₦2500</h2>
                    <p className="text-white">/month</p>
                    <ul className="plan-card-list">
                      <li>1 User Per Account</li>
                      <li>Access to 10 course study materials</li>
                      <li>Access to ETC AI Vibes</li>
                    
                    </ul>
                    <div
                      className="blue-filled-btn mt-1 mt-md-5"
                      role="button"
                      onClick={() => {
                        subCourse(2500, "Silver");
                      }}
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></span>
                      ) : (
                        <>Subscribe</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <Link to="/userWallet/walletsub" className="ms-auto me-3">
            <span data-bs-dismiss="modal"> See more plans</span>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;

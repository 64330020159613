import microsoft from "../../assets/microsoft.png";
import redeemer from "../../assets/redeemers-uni-ezgif 1.png";

const Partners = () => {
  return (
    <div className="partners">
      <h1>Our Partners</h1>
      <div className="partpic">
        <img src={redeemer} alt="" />
        <img src={microsoft} alt="" />
        {/* <img src={redeemer} alt="" />
        <img src={mic} alt="" />
        <img src={redeemer} alt="" />
        <img src={mic} alt="" /> */}
      </div>
    </div>
  );
};

export default Partners;

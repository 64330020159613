import { useEffect } from "react";
import blueLogo from "../../assets/img/blueLogo.svg";
import AdminMobileSidebar from "./AdminMobileSidebar";
import { useNavigate } from "react-router-dom";

const getCurrentDate = () => {
  const date = new Date();
  const options = { month: "long", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
const AdminHeader = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const authId = sessionStorage.getItem("adminID");
    if (authId === "" || authId === null) {
      navigate("/adminlogin");
    }
  }, [navigate]);
  const currentDate = getCurrentDate();
  return (
    <section className="container-fluid bg-white border-0 px-3 p-md-3 admin-header sticky-top mb-0 mb-md-4">
      <div className="d-flex align-items-center">
        <div className="logo d-block d-md-none pe-2">
          <img src={blueLogo} alt="Logo" />
        </div>
        <div className="d-block">
          <h3 className="viga-24-400 m-0 text-blue">Admin Platform</h3>
        </div>
        {/* <div className="form ms-auto ms-2">
          <input
            type="text"
            className="form-control search-bar-grey "
            placeholder="Search"
          />
        </div> */}
        <div className="d-block ps-2 ms-auto">
          <span className="date ms-auto text-grey">{currentDate}</span>
        </div>
        <AdminMobileSidebar />
      </div>
    </section>
  );
};

export default AdminHeader;

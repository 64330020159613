import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import GoogleLoginDiv from "./GoogleLoginDiv";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
const LoginStudent = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      const tag = "student";
      const logobj = { email, password, tag };

      axios
        .post("LoginTutorAndUsers", logobj, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          let resp = response.data.data;
          if (resp.StudentResponse.status === "success") {
            console.log("Login successful!");
            console.log(resp);
            navigate("/userdashboard");
            sessionStorage.setItem("userID", resp.StudentResponse.UserId);
            sessionStorage.setItem(
              "userSubDays",
              resp.StudentResponse.Subcription_days
            );
            dispatch(
              login({
                userId: resp.StudentResponse.UserId,
                loggedIn: true,
              })
            );
          } else if (
            resp.StudentResponse.message ===
            "Your Account has not been verified"
          ) {
            navigate("/userverify");
            sessionStorage.setItem("userEmail", email);
          } else {
            toast.error("Invalid Username or Password", {
              theme: "colored",
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Login failed due to:" + err.message, {
            theme: "colored",
            autoClose: 3000,
          });
        });
    }
  };
  const validate = () => {
    let result = true;
    if (email === "" || email === null) {
      result = false;
      toast.warning("Please Enter Username", {
        theme: "colored",
        autoClose: 3000,
      });
    }
    if (password === "" || password === null) {
      result = false;
      toast.warning("Please Enter Password", {
        theme: "colored",
        autoClose: 3000,
      });
    }
    return result;
  };
  return (
    <form className="login-form text-center my-5" onSubmit={handleLogin}>
      <div className="mb-4 d-flex">
        <label htmlFor="email" className="login-label">
          Email Address
        </label>
        <input
          type="email"
          placeholder="name@email.com"
          className="login-input"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex">
        <label htmlFor="password" className="login-label">
          Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="*******"
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPassword}
        ></i>
      </div>
      <div className="mb-3 d-flex justify-content-center login-additions">
        <input type="checkbox" name="" id="" />
        <span htmlFor="" className="ps-1 login-reminder">
          Remember me
        </span>
        <span className="ms-auto">
          <Link to="/resetPassword">Forget Password?</Link>
        </span>
      </div>
      <div className="mt-4">
        <button type="submit" className="submit-btn">
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span>Log In</span>
          )}
        </button>
      </div>

      <div className="my-3">
        <GoogleLoginDiv />
      </div>
      <span className="font-12">
        Need help? Contact support at{" "}
        <a href="mailto:contact@etcvibes.com">contact@etcvibes.com</a>
      </span>
    </form>
  );
};

export default LoginStudent;

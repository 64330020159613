import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const UserAddResource = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState("");
  const [courseId, setcourseId] = useState("");
  const [courses, setCourses] = useState([]);
  const [images, setimages] = useState("");
  const [type, setType] = useState("");
  const [Name, setName] = useState("");
  const [year, setYear] = useState("");
  const userId = sessionStorage.getItem("userID");
  const [isChecked, setIschecked] = useState(false);

  const handlecheckbox = (e) => {
    setIschecked(!isChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const courseData = await axios.get("ListCourses");
        const deptData = await axios.get("listDepartment");
        const cou = courseData.data.data.message;
        setCourses(cou);
        const dept = deptData.data.data.message;
        setDepartment(dept);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const uploadResource = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("department_id", selectedDepartment);
    formData.append("course_id", courseId);
    formData.append("name", Name);
    formData.append("year", year);
    formData.append("resource_type", type);
    formData.append("images", images);
    console.log(formData);
    try {
      await axios.post(`UserPost/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleClose();
      toast.success("Resource added successfully", {
        theme: "colored",
        autoClose: "2000",
      });
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message || error.message, {
        theme: "colored",
        autoClose: "2000",
      });
      setLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <form className="container" onSubmit={uploadResource}>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Department
            </label>
            <select
              className="resource-input"
              onChange={(e) => setselectedDepartment(e.target.value)}
              value={selectedDepartment}
            >
              <option value="">Select Department</option>
              {department.map((dep, index) => (
                <option key={index} value={dep.id}>
                  {dep.dept_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course
            </label>
            <select
              className="resource-input"
              onChange={(e) => setcourseId(e.target.value)}
              value={courseId}
            >
              <option value="">Select Course</option>
              {courses.map((fac, index) => (
                <option key={index} value={fac.CourseID}>
                  {fac.CourseTitle}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Name
            </label>
            <input
              type="text"
              value={Name}
              placeholder="Resource Title"
              onChange={(e) => setName(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Year
            </label>
            <input
              type="text"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="resource-input"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Resource Type
            </label>
            <select
              className="resource-input"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value=""></option>
              <option value="note">Notes</option>
              <option value="pastquestion">Past Question</option>
              <option value="solution">Solution</option>
              <option value="textbook">Textbook</option>
              <option value="video">Video</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              File
            </label>
            <input
              type="file"
              onChange={(e) => setimages(e.target.files[0])}
              className="resource-input"
            />
          </div>
          <div className="my-4">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handlecheckbox}
            />
            <span>
              {" "}
              I confirm that the material I am submitting is original and not
              plagiarized. I have the necessary copyright permissions, and I am
              sharing it for use by the community on ETC Vibes.
            </span>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className={`${
                isChecked ? "blue-light-btn" : "grayed-btn"
              } text-center`}
              type="submit"
              disabled={!isChecked}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span> Upload</span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserAddResource;

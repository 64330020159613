import React from "react";
import { Modal } from "react-bootstrap";

const CashConversionAlert = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <p className="fw-bold d-flex align-items-center lato-16-600">
          {" "}
          <i className="bi bi-exclamation-circle text-red"></i> Transaction
          Pending Confirmation Attention
        </p>
        <p>
          <b>Attention</b>: Admin Confirmation Required, please note that this
          transaction requires administrator approval. Please be patient for
          confirmation from the administrator. Thank you for your cooperation.
        </p>
        <div className="d-flex justify-content-center">
          <button className="blue-border-btn">Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default CashConversionAlert;

import { Modal } from "react-bootstrap";

const ZoomNotify = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="p-3">
          <div className="d-block">
            <h3 className="viga-24-400 text-center my-2 text-blue">
              Zoom Onboarding Process
            </h3>
            <p>
              You've received an email for the Zoom onboarding process. Please
              check and complete the necessary steps to unlock tutorial creation
              privileges on ETC Vibes.
              <br />
              If you need assistance, feel free to reach out to our support
              team.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="blue-filled-btn">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ZoomNotify;

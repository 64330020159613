import ResourceTable from "./ResourceTable";
import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";
import LogoutModal from "../../../components/LogoutModal";
import AdminResourceCards from "./AdminResourceCards";

const AdminResource = () => {
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <AdminResourceCards />
        <ResourceTable />
      </div>
    </section>
  );
};  

export default AdminResource;

import { NavLink } from "react-router-dom";

const MobileTutorNav = () => {
  return (
    <nav className="navbar fixed-bottom d-flex d-md-flex d-lg-none p-0 w-100">
      <div className="container-fluid mobile-nav">
        <div className="row row-cols-4 w-100 mx-0">
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              to="/tutor"
              // className={({ isActive }) =>
              //   isActive ? "nav-link mobile-active" : "nav-link mobile-inactive"
              // }
              className="nav-link"
            >
              <div className="nav-item text-center">
                <i className="bi bi-house-door fs-5 "></i>
                <p className="m-0 lato-14-400">Home</p>
              </div>
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink className="nav-link" to="/tutorprofile">
              <div className="nav-item text-center">
                <i className="bi bi-person fs-5"></i>
                <p className="m-0 lato-14-400">Profile</p>
              </div>
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink to="/tutorhelp" className="nav-link">
              <div className="nav-item text-center">
                <i className="bi bi-question-circle fs-5"></i>
                <p className="m-0 lato-14-400">Help</p>
              </div>
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink
              className="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <div className="nav-item text-center">
                <i className="bi bi-box-arrow-right fs-5"></i>
                <p className="m-0 lato-14-400">Logout</p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileTutorNav;

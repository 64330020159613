import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
const TutorialTable = () => {
  const [TableData, setTableData] = useState([]);
  const itemsPerPage = 15; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    const getTutorial = async () => {
      try {
        const response = await axios.get("tutorialRecord");
        const tutordata = response.data.data.message;
        setTableData(tutordata);
        setloading(false);
      } catch (error) {
        toast.error(error);
        setloading(false);
      }
    };
    getTutorial();
  }, []);
  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = TableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();
  const handleDetails = () => {
    navigate("/admintutorial/details");
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };
  return (
    <div className="container-fluid p-0">
      <div className="container-fluid">
        <h4 className="viga-24-400 text-blue">All Tutorials</h4>
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  Type of Tutorial
                </th>
                <th scope="col" className="py-3">
                  Tutorial
                </th>
                <th scope="col" className="py-3">
                  Amount
                </th>
                <th scope="col" className="py-3">
                  Date & Time
                </th>
                <th scope="col" className="py-3">
                  Tutorial Status
                </th>
              </tr>
            </thead>
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-lg text-primary"
                  role="status"
                ></span>
              </>
            ) : (
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index} className="table-row" onClick={handleDetails}>
                    <td>{item.tt_type}</td>
                    <td>{item.tt_title}</td>
                    <td>₦ {item.amount}</td>
                    <td>{formatDateTime(item.tt_time)}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(TableData.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                className={`btn pagination-button mx-1 ${
                  currentPage === index + 1
                    ? "btn-primary active"
                    : "btn-secondary"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TutorialTable;

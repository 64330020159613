
const PayoutsCard = () => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">All Tutors</h3>
          <p className="lato-24-400 text-blue">300</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Total Payouts</h3>
          <p className="lato-24-400 text-blue">₦90000000</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Ave. payout/T.Rate</h3>
          <p className="lato-24-400 text-blue">1500/1hr</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Total Tutorials </h3>
          <p className="lato-24-400 text-blue">250</p>
        </div>
      </div>
    </div>
  );
};

export default PayoutsCard;

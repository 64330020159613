import React, { useEffect, useState } from "react";
import AdminSidebar from "../../AdminSidebar";
import LogoutModal from "../../../../components/LogoutModal";
import AdminHeader from "../../AdminHeader";
import { Link, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const StudentUploadDetails = () => {
  let { type, id } = useParams();
  const [resource, setResource] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`AdminViewResource/${type}/${id}`);
        const details = response.data.resource;
        setResource(details);
        setLoading(false);
      } catch (error) {
        toast.error("Error fetching student details:", error);
        setLoading(false);
      }
    };

    fetchDetails();
  }, [type, id]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };

  const approveResource = async (user) => {
    const obj = {
      resource_type: type,
      resource_id: id,
      user_id: user,
    };
    try {
      const response = await axios.post("Approve", obj);
      const resp = response.data;
      console.log(resp);
      toast.success(resp.message);
    } catch (error) {
      console.error("Error approving resource:", error);
      toast.error("Error approving resource:", error.message);
    }
  };

  const denyResource = async () => {
    const obj = {
      resource_type: type,
      resource_id: id,
      reason: "The pdf is not opening, contact contact@etcvibes.com or +2347067093323",
    };
    try {
      await axios.post("Deny", obj);
      toast.success("Resource rejected");
    } catch (error) {
      console.error("Error denying resource:", error);
      toast.error("Error denying resource:", error.message);
    }
  };

  const handleResourceClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid page-details">
          <div className="d-flex align-detailss-center">
            <Link to="/adminresource">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
            <span className="viga-24-400">Resource Details</span>
          </div>

          <div className="container ">
            {loading ? (
              <span
                className="spinner-border spinner-border-lg text-primary"
                role="status"
              ></span>
            ) : (
              <div className="container">
                <div className="d-block">
                  <div className="d-flex mb-3">
                    <button
                      className="grey-btn px-4 ms-auto"
                      onClick={denyResource}
                      disabled={resource.status === 1 || resource.status === 2}
                    >
                      Reject
                    </button>
                    <button
                      className="blue-filled-btn ms-5"
                      onClick={() => approveResource(resource.user_id)}
                      disabled={resource.status === 1 || resource.status === 2}
                    >
                      Approve
                    </button>
                  </div>

                  <Link
                    to={`${encodeURIComponent(resource.url)}`}
                    className="resource-blue p-3 mb-3 w-50"
                    // onClick={() => handleResourceClick(resource.url)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-file-earmark-text text-white fs-3 pe-3"></i>
                    <span className="lato-20-400">
                      {resource.resource_type === "note" ? (
                        <div>{resource.nt_name}</div>
                      ) : resource.resource_type === "video" ? (
                        <div>{resource.v_name}</div>
                      ) : resource.resource_type === "pastquestion" ? (
                        <div>{resource.pq_name}</div>
                      ) : resource.resource_type === "textbook" ? (
                        <div>{resource.tb_name}</div>
                      ) : resource.resource_type === "solution" ? (
                        <div>{resource.pq_name}</div>
                      ) : (
                        // Default content for other resource types
                        <div>{/* Default content */}</div>
                      )}
                    </span>
                    <i className="bi bi-check-lg ms-auto fs-3 text-white"></i>
                  </Link>
                  <div className="d-flex align-detailss-center mb-2">
                    <span className="lato-24-700 pe-2">Course Title:</span>
                    <span className="lato-24-400">{resource.course_name}</span>
                  </div>
                  <div className="d-flex align-detailss-center mb-2">
                    <span className="lato-24-700 pe-2">Resource Type:</span>
                    <span className="lato-24-400">
                      {resource.resource_type}
                    </span>
                  </div>
                  <div className="d-flex align-detailss-center mb-2">
                    <span className="lato-24-700 pe-2">Uploaded By:</span>
                    <span className="lato-24-400">
                      {" "}
                      {resource.resource_type === "note" ? (
                        <div>{resource.nt_tutor}</div>
                      ) : resource.resource_type === "video" ? (
                        <div>{resource.v_tutor}</div>
                      ) : resource.resource_type === "pastquestion" ? (
                        <div>{resource.pq_tutor}</div>
                      ) : resource.resource_type === "textbook" ? (
                        <div>{resource.tb_author}</div>
                      ) : resource.resource_type === "solution" ? (
                        <div>{resource.pq_tutor}</div>
                      ) : (
                        // Default content for other resource types
                        <div>{/* Default content */}</div>
                      )}
                    </span>
                  </div>
                  <div className="d-flex align-detailss-center mb-2">
                    <span className="lato-24-700 pe-2">Date Uploaded:</span>
                    <span className="lato-24-400">
                      {formatDateTime(resource.created_at)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudentUploadDetails;

import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";
import LogoutModal from "../../../components/LogoutModal";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const TutorDetails = () => {
  const { tutorId } = useParams(); // Get the tutor ID from the URL
  const [resource, setResource] = useState([]);
  const [account, setAccount] = useState([]);
  const [tutorial, setTutorial] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setloading(true);
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(`TutorFeatures/${tutorId}`);
        const tutorDetails = response.data.data.message.tutor_details;
        const accountDetails = response.data.data.message.Account_Details;
        const tutorialDetails = response.data.data.message.Tutorials;
        setResource(tutorDetails);
        setAccount(accountDetails);
        setTutorial(tutorialDetails);
        setloading(false);
      } catch (error) {
        toast.error("Error fetching student details:", error);
        setloading(false);
      }
    };

    // Call the fetchStudentDetails function
    fetchStudentDetails();
  }, [tutorId]);
  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid page-details">
          <div className="d-flex align-items-center">
            <Link to="/admintutor">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
            <span className="viga-24-400">Tutor Profile</span>
          </div>
          <div className="container">
            <div className="d-block d-md-flex mb-3 align-items-center">
              <div className="student-img">
                <i className="bi bi-person-circle text-blue"></i>
              </div>
              <div className="d-flex ms-auto">
                <button className="grey-btn me-2 ">Suspend Account</button>
                <button className="btn btn-danger mx-2">Delete Account</button>
              </div>
            </div>
          </div>
          {loading ? (
            <span
              className="spinner-border spinner-border-lg text-primary"
              role="status"
            ></span>
          ) : (
            <div className="container">
              {resource.map((details) => (
                <div className="d-block" key={details.id}>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Name: </span>
                    <span className="lato-24-400">{details.Fullname}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">University:</span>
                    <span className="lato-24-400">{details.University}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Email :</span>
                    <span className="lato-24-400">{details.Email}</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span className="lato-24-700 pe-2">Department:</span>
                    <span className="lato-24-400">{details.Department}</span>
                  </div>
                </div>
              ))}

              <div className="d-block">
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Account Name: </span>
                  <span className="lato-24-400">{account.AccountName}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Account Type:</span>
                  <span className="lato-24-400">{account.AccountType}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Account Number :</span>
                  <span className="lato-24-400">{account.AccountNumber}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="lato-24-700 pe-2">Bank:</span>
                  <span className="lato-24-400">{account.Bank}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TutorDetails;

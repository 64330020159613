import { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const CoinApprovalModal = ({ show, handleClose, item }) => {
  const [loading, setLoading] = useState(false);
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };

  const approveRequest = async (id) => {
    setLoading(true);
    try {
      const response = await axios.put(`ApproveCashPayment/${id}`);
      const resp = response.data;
      console.log(resp);
      toast.success("Request approved", {
        theme: "colored",
        autoClose: "2000",
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        theme: "colored",
        autoClose: "2000",
      });
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <ModalBody>
        <div className="container p-3">
          <div className="text-center">
            <h2 className="viga-24-400 text-blue">🔔 Coin Conversion 🔔</h2>
          </div>
          <div className="d-block">
            <p className="text-center">*Request for coin conversion to naira</p>

            <div className="row row-cols-1 row-cols-md-2 g-3">
              <div className="col">
                <span>
                  Name: <b>{item.account_name}</b>
                </span>
              </div>
              <div className="col">
                <span>
                  Date: <b>{formatDateTime(item.created_at)}</b>
                </span>
              </div>
              <div className="col">
                <span>
                  Type: <b>{item.type}</b>
                </span>
              </div>
              <div className="col">
                <span>
                  Request:{" "}
                  <b>
                    {item.amount_converted} coins to ₦ {item.local_currency}
                  </b>
                </span>
              </div>
              <div className="col">
                <span>
                  Account Number: <b>{item.account_number}</b>
                </span>
              </div>
              <div className="col">
                <span>
                  Bank Name: <b>{item.bank_name}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center my-3">
            {item.status === 1 || item.status === 2 ? (
              <button className="blue-filled-btn">Approved</button>
            ) : (
              <button
                className="blue-filled-btn"
                disabled={item.status === 1 || item.status === 2}
                onClick={() => approveRequest(item.id)}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <span>Approve</span>
                )}
              </button>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CoinApprovalModal;

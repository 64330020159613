import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import MobileProfile from "../../../components/MobileProfile";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import MainCalculator from "./MainCalculator";
import "./cgpa.css";

const CgpaCalc = () => {
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <MobileProfile />
      <div className="main-content p-1 p-md-3 cgpa-calc">
        <DashboardHeader />
        <div className="dahboard-title px-3">
          <h3 className="viga-24-400">CGPA Calculator</h3>
        </div>
        <div className="container-fluid p-4 justify-content-center d-flex">
          <MainCalculator />
        </div>
      </div>
      <MobileNav />
    </div>
  );
};

export default CgpaCalc;

import React from "react";

const FinanceHomeCards = ({ finData }) => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">All Tutors</h3>
          <p className="lato-24-400 text-blue">{finData.TotalTutors}</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Total Users</h3>
          <p className="lato-24-400 text-blue">{finData.TotalUsers}</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Tutorial Earnings</h3>
          <p className="lato-24-400 text-blue">{finData.TotalEarnings}</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Total Payouts</h3>
          <p className="lato-24-400 text-blue">{finData.Payout}</p>
        </div>
      </div>
    
    </div>
  );
};

export default FinanceHomeCards;

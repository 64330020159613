import React from "react";
import { useState } from "react";

const MainCalculator = () => {
  const [numCourses, setNumCourses] = useState(0);
  const [courses, setCourses] = useState([]);
  const [totalCreditUnits, setTotalCreditUnits] = useState(0);
  // const [totalGradePoints, setTotalGradePoints] = useState(0);
  const [cgpa, setCgpa] = useState(0);

  const handleNumCoursesChange = (e) => {
    const count = parseInt(e.target.value);
    setNumCourses(count);
    setCourses(
      Array.from({ length: count }, (_, index) => ({
        creditUnit: 0,
        grade: "",
      }))
    );
  };

  const handleCourseChange = (index, field, value) => {
    setCourses((prevCourses) =>
      prevCourses.map((course, i) => {
        if (i === index) {
          return {
            ...course,
            [field]: value,
          };
        }
        return course;
      })
    );
  };

  const deleteCourse = (index) => {
    setCourses((prevCourses) => prevCourses.filter((_, i) => i !== index));
  };

  const calculateCgpa = () => {
    let totalCredit = 0;
    let totalGradePoint = 0;

    courses.forEach((course) => {
      const { creditUnit, grade } = course;
      const credit = parseInt(creditUnit);
      let gradePoint;

      switch (grade) {
        case "A":
          gradePoint = 5;
          break;
        case "B":
          gradePoint = 4;
          break;
        case "C":
          gradePoint = 3;
          break;
        case "D":
          gradePoint = 2;
          break;
        case "E":
          gradePoint = 1;
          break;
        case "F":
          gradePoint = 0;
          break;
        default:
          gradePoint = NaN;
      }

      if (!isNaN(credit) && !isNaN(gradePoint)) {
        totalCredit += credit;
        totalGradePoint += credit * gradePoint;
      }
    });

    const calculatedCgpa = totalGradePoint / totalCredit;
    setCgpa(isNaN(calculatedCgpa) ? 0 : calculatedCgpa.toFixed(2));
    setTotalCreditUnits(totalCredit);
    // setTotalGradePoints(totalGradePoint);
  };

  return (
    <div className="p-4">
      <div>
        <div className="mb-3 calc-course d-flex">
          <label htmlFor="numCourses" className="poppins-20-400 pe-4">
            Enter Number of courses to be added
          </label>
          <input
            type="number"
            id="numCourses"
            min="0"
            className="form-control w-25"
            value={numCourses}
            onChange={handleNumCoursesChange}
          />
        </div>
      </div>
      {courses.map((course, index) => (
        <div key={index} className="d-flex">
          <h3 className="viga-20-400 pe-4 text-blue">Course {index + 1}</h3>
          <div className="">
            <label htmlFor={`creditUnit-${index}`} className="viga-20-400">
              Credit Unit:
            </label>
            <input
              type="number"
              id={`creditUnit-${index}`}
              min="0"
              className="form-control w-50"
              value={course.creditUnit}
              onChange={(e) =>
                handleCourseChange(index, "creditUnit", e.target.value)
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor={`grade-${index}`} className="viga-20-400">
              Grade:
            </label>
            <select
              id={`grade-${index}`}
              value={course.grade}
              className="form-control"
              onChange={(e) =>
                handleCourseChange(index, "grade", e.target.value)
              }
            >
              <option value="">Select Grade</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="F">F</option>
            </select>
          </div>
          <button
            onClick={() => deleteCourse(index)}
            className="bi bi-trash-fill btn fs-3 text-danger ms-3"
          ></button>
        </div>
      ))}
      <button onClick={calculateCgpa} className="calc-btn text-center">
        Calculate CGPA
      </button>
      <div className="d-flex mt-3">
        <div className="border total-unit me-2">
          Total Credit Unit
          <p className="viga-40-400 text-center">{totalCreditUnits}</p>
        </div>
        <div className="border total-unit">
          CGPA
          <p className="viga-40-400 text-center">{cgpa}</p>
        </div>
        {/* <p>Total Grade Points: {totalGradePoints}</p> */}
      </div>
    </div>
  );
};

export default MainCalculator;

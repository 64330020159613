import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axios from "../../../../../api/axios";

const AddFlashCards = ({ show, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [courseId, setcourseId] = useState("");
  const [courses, setCourses] = useState([]);
  const [topic, setTopic] = useState("");
  const [questionCard, setQuestionCard] = useState([
    {
      id: 1,
      question: "",
      answer: "",
    },
  ]);
  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const flash_questions = questionCard.map(({ question, answer }) => ({
      question,
      answer,
    }));
    const obj = {
      flash_questions: [
        {
          course_id: courseId,
          topic,
          flash: flash_questions,
        },
      ],
    };
    try {
      const response = await axios.post("createFlashcardQuestions", obj);
      const resp = response.data;
      console.log(resp);
      toast.success("Flashcard added successfully", {
        theme: "colored",
        autoClose: 2000,
      });
      handleClose();
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const courseData = await axios.get("ListCourses");

        const cou = courseData.data.data.message;

        setCourses(cou);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const addQuestionCard = () => {
    setQuestionCard((prevCards) => [
      ...prevCards,
      {
        id: prevCards.length + 1,
        question: "",
        answer: "",
        option1: "",
        option2: "",
        option3: "",
      },
    ]);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestionCards = [...questionCard];
    updatedQuestionCards[index][field] = value;
    setQuestionCard(updatedQuestionCards);
  };

  const removeQuestionCard = (index) => {
    setQuestionCard((prevCards) => prevCards.filter((_, i) => i !== index));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        <h3 className="fw-bold text-blue">Add FlashCard</h3>
      </ModalHeader>
      <ModalBody>
        <form className="container" onSubmit={submitForm}>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Course
            </label>
            <select
              className="resource-input"
              onChange={(e) => setcourseId(e.target.value)}
              value={courseId}
            >
              <option value="">Select Course</option>
              {courses.map(({ id, CourseID, CourseTitle }) => (
                <option key={id} value={CourseID}>
                  {CourseTitle}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Topic
            </label>
            <input
              type="text"
              onChange={(e) => setTopic(e.target.value)}
              className="resource-input"
              value={topic}
            />
          </div>

          <div className="quiz-fixed-column">
            {questionCard.map((question, index) => (
              <div className="card rounded p-3 mb-3" key={question.id}>
                <div className="d-flex align-items-center">
                  <div className="me-auto">
                    <h6>
                      No. of Questions:{" "}
                      <span className="text-blue">{question.id}</span>
                    </h6>
                  </div>
                  <i
                    className="bi bi-trash text-danger fs-5"
                    onClick={() => removeQuestionCard(index)}
                  ></i>
                </div>
                <hr />
                <div className="admin-quiz-questions">
                  <div className="mb-3">
                    <textarea
                      className="quiz-textbox"
                      cols="30"
                      rows="3"
                      placeholder="Enter Question"
                      value={question.question}
                      onChange={(e) =>
                        handleQuestionChange(index, "question", e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div className="admin-quiz-answers">
                    {/* Option 1 */}
                    <div className="mb-3 quiz-option-container">
                      <input
                        type="text"
                        name=""
                        className="admin-quiz-option"
                        placeholder="Enter Option"
                        value={question.option1}
                        onChange={(e) =>
                          handleQuestionChange(index, "answer", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button
              type="button"
              className="blue-filled-btn text-center"
              onClick={addQuestionCard}
            >
              Add Question
            </button>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex">
          <button onClick={handleClose} className="blue-outline-btn">
            Close
          </button>
          <button
            className="blue-filled-btn text-center ms-4"
            type="submit"
            onClick={submitForm}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
              ></span>
            ) : (
              <span>Upload Quiz</span>
            )}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddFlashCards;

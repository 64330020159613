import React from "react";

const SubscriptionsCard = () => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">All Users</h3>
          <p className="lato-24-400 text-blue">3000</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Total Subscriptions</h3>
          <p className="lato-24-400 text-blue">1000</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Average Sub. price</h3>
          <p className="lato-24-400 text-blue">₦1500</p>
        </div>
      </div>
      <div className="col">
        <div className="finance-card">
          <h3 className="viga-24-400">Total Revenue</h3>
          <p className="lato-24-400 text-blue">₦250000000</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsCard;

import React from "react";
import { useState } from "react";

const EarningsTable = () => {
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number

  const tableData = [
    {
      id: 1,
      ID: "001",
      name: "Basic Plan Subscribers",
      earning: "₦500000",
      rate: "20",
      amount: "₦2000/Hr",
    },
    {
      id: 2,
      ID: "002",
      name: "Silver Plan Subscribers",
      earning: "₦500000",
      rate: "20",
      amount: "₦2000/Hr",
    },
    {
      id: 3,
      ID: "003",
      name: "Private Tutorial",
      earning: "₦500000",
      rate: "20",
      amount: "₦2000/Hr",
    },
    {
      id: 4,
      ID: "004",
      name: "Extra Tutorial",
      earning: "₦500000",
      rate: "21",
      amount: "₦2000/Hr",
    },
  ];

  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = tableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="container-fluid shadow p-3 p-md-3 rounded   ">
      <h4 className="inter-24-400">Tutor's Earnings</h4>
      <div className="table-responsive p-2">
        <table className="table table-hover ">
          <thead>
            <tr className="resource-head">
              <th scope="col" className="py-3">
                S/N
              </th>
              <th scope="col" className="py-3">
                Tutor's Name
              </th>
              <th scope="col" className="py-3">
                Total Earnings
              </th>
              <th scope="col" className="py-3">
                Number of Sessions
              </th>
              <th scope="col" className="py-3">
                Hourly rate
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index} className="table-row">
                <th>{item.ID}</th>
                <td>{item.name}</td>
                <td>{item.earning}</td>
                <td>{item.rate}</td>
                <td>{item.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(tableData.length / itemsPerPage) },
          (_, index) => (
            <button
              key={index}
              className={`btn pagination-button mx-1 ${
                currentPage === index + 1
                  ? "btn-primary active"
                  : "btn-secondary"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default EarningsTable;

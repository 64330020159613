import React, { useState } from "react";
import AdminResourceCardTwo from "./AdminResourceCardTwo";
import AdminUploadTable from "./studentUpload/AdminUploadTable";

const ResourceTable = () => {
  const [activeTab, setActiveTab] = useState("allResources");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex mb-3">
        <button
          className={` ${
            activeTab === "allResources"
              ? "course-btn-active"
              : "course-btn-inactive"
          }`}
          onClick={() => handleTabChange("allResources")}
        >
          All Resources
        </button>
        <button
          className={`ps-5  ${
            activeTab === "studentUploads"
              ? "course-btn-active"
              : "course-btn-inactive"
          }`}
          onClick={() => handleTabChange("studentUploads")}
        >
          Student Uploads
        </button>
      </div>
      {activeTab === "allResources" && <AdminResourceCardTwo />}
      {activeTab === "studentUploads" && <AdminUploadTable />}
    </div>
  );
};

export default ResourceTable;
